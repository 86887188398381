// Modules
import React from "react";
import Icon from "../Icon";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { AnimatePresence, motion } from "framer-motion";
import { useCreatePortalInBody } from "../../hooks/useCreatePortalInBody";
import Fade from "../Animations/Fade";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface ModalProps {
  children: any;
  activeCond: boolean;
  onClose: any;
  Header?: JSX.Element | string;
  Footer?: JSX.Element | string;
  center?: boolean;
  maxWidth?: string;
  zIndex?: string;
}

const enterVarient = {
  opacity: 1,
  transform: "scale(1) translateZ(0px)",
  transition: { duration: 0.2, ease: [0, 0, 0.2, 1] },
};
const exitVarient = {
  opacity: 0,
  transform: "scale(0) translateZ(-100%)",
  transition: { duration: 0.1, ease: [0.4, 0, 1, 1] },
};

type TExtendedProps = ModalProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const dbg = false;

const Modal: React.FC<TExtendedProps> = ({
  children,
  center,
  Header,
  Footer,
  onClose,
  activeCond,
  maxWidth = "28rem",
  zIndex = "1400",
  ...props
}) => {
  const createBodyPortal = useCreatePortalInBody();

  const [removeModalFromVDOM, setRemoveFromVDOM] = React.useState(!activeCond);
  const [closeIsTriggered, setCloseIsTriggered] = React.useState("0");

  const modal = React.useRef(null);

  // console.log({ removeModalFromVDOM, closeIsTriggered, activeCond });

  React.useEffect(() => {
    if (removeModalFromVDOM && closeIsTriggered === "0" && activeCond) {
      disableBodyScroll(document.body);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [removeModalFromVDOM, closeIsTriggered, activeCond]);

  // Not good for overflow

  React.useEffect(() => {
    if (closeIsTriggered === "clicked-to-close") {
      dbg && console.log("closeIsTriggered", closeIsTriggered);
      setTimeout(() => {
        dbg && console.log("close modal");
        onClose();
        setTimeout(() => {
          dbg && console.log("remove modal");
          setRemoveFromVDOM(false);
          setTimeout(() => {
            dbg && console.log("cleanup");
            setCloseIsTriggered("0");
            setRemoveFromVDOM(true);
          }, 100);
        }, 200);
      }, 100);
    } else {
    }
  }, [closeIsTriggered, onClose]);

  return createBodyPortal(
    removeModalFromVDOM ? (
      <>
        <React.Suspense fallback={null}>
          <Styling />
        </React.Suspense>
        <AnimatePresence>
          {activeCond && (
            <div
              ref={modal}
              className={`modal ${props.className ? props.className : ""}`}
              style={{ alignItems: center ? "center" : "normal" }}
            >
              <>
                <motion.div
                  className="modal-content"
                  style={{ maxWidth, zIndex }}
                  initial={exitVarient}
                  animate={enterVarient}
                  exit={exitVarient}
                >
                  <button className="modal-close-btn" onClick={() => setCloseIsTriggered("clicked-to-close")}>
                    <Icon name="close" />
                  </button>
                  {Header && <header className="modal-header">{Header}</header>}
                  <article className="modal-body">{children}</article>
                  {Footer && <footer className="modal-footer">{Footer}</footer>}
                </motion.div>

                <Fade activeCond={closeIsTriggered === "0"}>
                  <div
                    onClick={() => {
                      setCloseIsTriggered("clicked-to-close");
                    }}
                    className="modal-overlay"
                  />
                </Fade>
              </>
            </div>
          )}
        </AnimatePresence>
      </>
    ) : null
  );
};

export default Modal;
