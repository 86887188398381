// Types
import { RouteType, RouteTypeWithElement } from "../../types/RouteTypes";

// Pages
import BackupDataPage from "../../../../pages/Preferences/BackupData/BackupDataPage";
import ExportDataPage from "../../../../pages/Preferences/BackupData/SubPages/ExportDataPage";
import ImportDataPage from "../../../../pages/Preferences/BackupData/SubPages/ImportDataPage";
import TruncateDataPage from "../../../../pages/Preferences/BackupData/SubPages/TruncateDataPage";

export const backupDataPageRoutesMetadataWithElement: RouteTypeWithElement[] = [
  {
    title: "Backup Data Page",
    name: "backup-data-page",
    absolutePath: "/preferences/backup-data",
    path: "backup-data",
    Element: BackupDataPage,
    children: [
      {
        title: "Export Data Page",
        name: "export-data-page",
        absolutePath: "/preferences/backup-data/export-data-page",
        path: "export-data-page",
        Element: ExportDataPage,
      },
      {
        title: "Import Data Page",
        name: "import-data-page",
        absolutePath: "/preferences/backup-data/import-data-page",
        path: "import-data-page",
        Element: ImportDataPage,
      },
      {
        title: "Truncate Data Page",
        name: "truncate-data-page",
        absolutePath: "/preferences/backup-data/truncate-data-page",
        path: "truncate-data-page",
        Element: TruncateDataPage,
      },
    ],
  },
];

export const backupDataPageRoutesMetadata: RouteType[] = backupDataPageRoutesMetadataWithElement.map(
  ({ Element, ...rest }) => rest
);
