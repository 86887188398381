import React from "react";
import BasePage from "../Base/BasePage";
import { getCRLUD } from "../Base/operation/get/crlud";
import { knowledgeDataType } from "./knowledgesDataType";

const crlud = getCRLUD("knowledge", "knowledges");

const KnowledgesPage: React.FC = () => {
  return <BasePage title="Knowledges" crlud={crlud} entityDataType={knowledgeDataType} />;
};

export default KnowledgesPage;
