import Swal from "sweetalert2";
import { successToast } from "../../../constant/customToast";
import { decryptObject, encryptObject } from "../../../lib/crypt";
import { EntityDataType } from "../types/Entity";

interface onSaveEntityHandlerProps {
  entityDataTypeRequiredOnCreateOrUpdate: EntityDataType;
  mode: string;
  operationName: string;
  entityName: string;
  loading: boolean;
  createOrUpdateEntity: any;
  entity: any;
  onClose: any;
  onClearForm: any;
  onRedirectToList: any;
}

export const onSaveEntityHandler = async ({
  entityDataTypeRequiredOnCreateOrUpdate,
  mode,
  operationName,
  entityName,
  loading,
  createOrUpdateEntity,
  entity,
  onClose,
  onClearForm,
  onRedirectToList,
}: onSaveEntityHandlerProps) => {
  if (!loading && createOrUpdateEntity !== null) {
    if (!entity) return;
    const locationError = `Error on ${mode} the ${entityName}:`;

    const _entity = { ...entity };

    if (entityDataTypeRequiredOnCreateOrUpdate.filter((el) => el.key === "category").length > 0) {
      delete _entity.category;

      if (!_entity.domain || !_entity.specification || !_entity.topic) {
        Swal.fire({
          icon: "error",
          title: locationError,
          html: `Category fields are required`,
        });
      }

      _entity.domain = _entity.domain.id;
      _entity.specification = _entity.specification.id;
      _entity.topic = _entity.topic.id;
    }

    let warnings: string[] = [];

    entityDataTypeRequiredOnCreateOrUpdate.forEach((columnRequired) => {
      if (columnRequired.key === "category") {
        if (
          (_entity["domain"] && _entity["domain"].length === 0) ||
          (_entity["specification"] && _entity["specification"].length === 0) ||
          (_entity["topic"] && _entity["topic"].length === 0)
        ) {
          warnings.push("category");
        }
      } else {
        const valueColumn = _entity[columnRequired.key] || "";
        if (valueColumn.length === 0) {
          warnings.push(columnRequired.key);
        }
      }
    });

    if (warnings.length > 0) {
      Swal.fire({
        icon: "warning",
        title: locationError,
        html: `These fields are required, you have to fill them: \n
        <ol style="text-align: left; padding-left:48px;">
          ${warnings
            .map(
              (warning) =>
                `<li>- ${warning} <b>(${
                  entityDataTypeRequiredOnCreateOrUpdate.filter((el) => el.key === warning)[0].renderAs
                })</b></li>`
            )
            .toString()
            .replaceAll(",", "")}
        </ol>`,
      });
      return;
    }

    // console.log("_entity =>", _entity);
    

    const { data } = await createOrUpdateEntity({
      variables: {
        challenge: encryptObject({ ..._entity }),
      },
    });

    if (!data) {
      Swal.fire({
        icon: "error",
        title: locationError,
        html: `Something went wrong on mutate data not exist`,
      });
    }

    const { msg } = decryptObject(data[`${operationName}`]);

    if (!msg) {
      Swal.fire({
        icon: "error",
        title: locationError,
        html: `Something went wrong on mutate message from server not exist`,
      });
    }

    if (msg === `${mode}d`) {
      successToast(`Weve ${mode}d the ${entityName} for you.`);
      mode === "update" && onClose();
    } else {
      Swal.fire({
        icon: "error",
        title: locationError,
        html: `${msg.toString()}`,
      });
    }

    if (mode === "create" && warnings.length === 0 && warnings.length === 0) {
      onClearForm();
    }

    if (warnings.length === 0) {
      onRedirectToList();
    }
  }
};
