// Functions
import { getAttrOfItemBykey, updateObject } from "../../../../../lib/object";

// Types
import { FilterType } from "../../../../../pages/Base/Pagination/Filter/FilterType";

export const updateFilterSearchType = (
  value: any,
  selected_filter_key: string,
  local_filters: FilterType[] | null,
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>
) => {
  if (value) {
    const newUpdate: FilterType = {
      key: selected_filter_key,
      searchType: value,
      name: getAttrOfItemBykey(selected_filter_key, local_filters, "name"),
      value: getAttrOfItemBykey(selected_filter_key, local_filters, "value"),
      type: getAttrOfItemBykey(selected_filter_key, local_filters, "type"),
    };

    const newUpdatedObj = updateObject(newUpdate, local_filters);

    set_local_filters([...newUpdatedObj]);
  }
};

export const updateFilterValue = (
  value: any,
  selected_filter_key: string,
  local_filters: FilterType[] | null,
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>
) => {
  if (value !== null) {
    const newUpdate: FilterType = {
      key: selected_filter_key,
      name: getAttrOfItemBykey(selected_filter_key, local_filters, "name"),
      searchType: getAttrOfItemBykey(selected_filter_key, local_filters, "searchType"),
      value: value,
      type: getAttrOfItemBykey(selected_filter_key, local_filters, "type"),
    };

    const newUpdatedObj = updateObject(newUpdate, local_filters);

    set_local_filters([...newUpdatedObj]);
  }
};
