export const scrollIntoView = (searchParams: any) => {
  const get_elem = searchParams.get("scroll_into_view");
  // console.log("get_elem => ", get_elem);

  if (get_elem) {
    const elem = window.document.getElementById(get_elem);
    // console.log("elem => ", elem);

    if (elem) {
      elem.scrollIntoView();
      return true;
    }
  }
  return;
};
