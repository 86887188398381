import React from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { config } from "../../../config";
import Image from "../../../Components/Image";

interface MotionImageProps {
  width: number;
  srcVarient: "only" | "text";
}

const startVarient = { x: 8, opacity: 0 };
const endVarient = { x: 0, opacity: 1 };

const MotionImage: React.FC<MotionImageProps> = ({ width, srcVarient }) => {
  const controls = useAnimation();

  React.useEffect(() => {
    controls.start(endVarient);
  });

  return (
    <AnimatePresence>
      <motion.div
        initial={startVarient}
        animate={controls}
        exit={startVarient}
        transition={{
          delay: 0.25,
          x: { type: "spring", stiffness: 100 },
          default: { duration: 0.25 },
        }}
      >
        {/* when state {image} value change, this component will re-render with new src */}
        <Image
          style={{ width: `${width}px` }}
          src={`/images/logo/logo_${srcVarient}_dark.png`}
          srcDark={`/images/logo/logo_${srcVarient}_light.png`}
          alt={`${config.APP_NAME}`}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default MotionImage;
