import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { config } from "../config";

const link = createHttpLink({
  uri: config.EP,
  credentials: "include",
});

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default apolloClient;
