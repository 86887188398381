// Modules
import React from "react";

// Components
/// Children
import UserMenuComponent from "./User/UserMenuComponent";

interface HeaderComponentProps {
  onOpen: () => void;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ onOpen }) => {
  return (
    <header className={`header`}>
      <button className="burger" onClick={() => onOpen()}></button>
      <div className="control">
        <UserMenuComponent />
      </div>
    </header>
  );
};

export default HeaderComponent;
