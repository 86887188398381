// Modules
import React from "react";
import moment from "moment";

// Loader
import LoadingComponent from "../Loader/LoadingComponent";

// Components
import DisplayError from "../../../Components/Error/ErrorComponent";

// Hooks
import { useScript } from "../../../hooks/useScript";

// Configs
const duration_in_minutes = 15; // minutes

interface AppControllerShieldProps {
  isDenied: string;
}

const AppControllerShield: React.FC<AppControllerShieldProps> = ({ isDenied }) => {
  // Max try script
  const status = useScript("/25ed1bcb423b0b7200f485fc5ff71c8e.js");

  if (status === "ready") {
    const sec = isDenied.substring(3, isDenied.length - 3);
    const pure = window.atob(sec);

    var present = moment();
    var last_try = moment(new Date(pure));

    const diff = present.diff(last_try, "minutes");

    if (diff >= duration_in_minutes) {
      localStorage.removeItem("ga__54684565");
      localStorage.removeItem("ga_465489");
      window.location.reload();
      return null;
    } else {
      return (
        <DisplayError layout="modal" errorUniqueReference="853a0afb-1a6e-48ea-96e3-895ef1a1a80d">
          You've reached the maximum logon attempts please retry after{" "}
          <b id="down_counter_login">{duration_in_minutes - +diff}</b> minutes.
        </DisplayError>
      );
    }
  } else {
    return <LoadingComponent loadingUniqueReference="41568478-6e9f-4f63-ba43-d17c7b272eeb" message="max try script" />;
  }
};

export default AppControllerShield;
