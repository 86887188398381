import React from "react";
import Drawer from "../../../Components/Drawer/Drawer";
import DateSettings from "../MainPanel/DateSettings/DateSettings";
import HorizontalNavigator from "../MainPanel/HorizontalNavigator";
import SliderMarkZoomTime from "../MainPanel/SliderMarkZoomTime";

interface TaskManagerSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

const TaskManagerSettings: React.FC<TaskManagerSettingsProps> = ({ isOpen, onClose }) => {
  return (
    <Drawer activeCond={isOpen} Header={<h4>Task Manager Settings</h4>} onClose={onClose}>
      <DateSettings />
      <HorizontalNavigator />
      <SliderMarkZoomTime />
    </Drawer>
  );
};

export default TaskManagerSettings;
