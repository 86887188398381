// Modules
import React from "react";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";

// Functions
import { getLayoutStatesObj, layout_setting_key } from "../../../App/Layout/Context/LayoutStates";
import DisplayError from "../../../Components/Error/ErrorComponent";
import { useMutationSetting } from "../../../Components/Form/Input/SettingInput/hooks/useMutationSetting";
import { useQuerySetting } from "../../../Components/Form/Input/SettingInput/hooks/useQuerySetting";

// Components
import Select from "../../../Components/Select/Select";

const layoutStatesObj = getLayoutStatesObj();
const AppearancesPage: React.FC = () => {
  const { data, error, loading } = useQuerySetting(layout_setting_key);

  const mutate = useMutationSetting();

  const mutateSetting = (settingValue: string) => {
    mutate(layout_setting_key, settingValue);
  };

  if (loading) {
    return (
      <LoadingComponent
        loadingUniqueReference="4ec4333d-f334-4f21-bed5-9d20163c786f"
        message={`setting app_application_layout`}
      />
    );
  }

  if (error || !data) {
    return <DisplayError errorUniqueReference="e45392dc-8fd4-48a6-a19c-3656c09f0e9c" error={error} />;
  }

  return (
    <div>
      <h2 style={{ paddingBottom: "8px" }}> Application layout</h2>
      <div>
        <Select style={{ maxWidth: "512px" }} defaultValue={data.value} onChange={(e) => mutateSetting(e.target.value)}>
          {layoutStatesObj.map((el:any) => (
            <option key={el.key} value={el.key}>
              {el.value.toLowerCase().replaceAll("_", " ")}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default AppearancesPage;
