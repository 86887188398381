// Modules
import React from "react";

// Components
import FilterWrapper from "./Wrapper";
import SearchItem from "./Search/Item";
import FilterDropdownMenu from "./DropdownMenu/DropdownMenu";
import FilterModalInput from "./ModalInput";
import Icon from "../Icon";
import Input from "../Form/Input";

// Types
import { FilterType } from "../../pages/Base/Pagination/Filter/FilterType";

// Functions
import { getAttrOfItemBykey } from "../../lib/object";
import useDarkMode from "use-dark-mode";

interface FilterComponentProps {
  rowHeight: number;
  filters: FilterType[] | null;
  onFilter: (localFilters: any, clear?: boolean) => void;
  defaultSearch: any;
  dropdownTop: string;
  filterModalInputTop: string;
  filterModalInputLeft: string;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  rowHeight,
  filters,
  defaultSearch,
  onFilter,
  dropdownTop,
  filterModalInputTop,
  filterModalInputLeft,
}) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === true;

  const [isFilterMenuClosed, setIsFilterMenuClosed] = React.useState(true);

  const [isFilterMenuInputClosed, setIsFilterMenuInputClosed] = React.useState(true);

  const [selectedFilterKey, setSelectedFilterKey] = React.useState<string | null>(null);

  const [localFilters, setLocalFilters] = React.useState<FilterType[] | null>(defaultSearch);

  React.useEffect(() => {
    return () => {
      setSelectedFilterKey(null);
      setLocalFilters([...defaultSearch]);
    };
  }, [defaultSearch]);

  return (
    <>
      <FilterWrapper rowHeight={rowHeight}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            minHeight: `${rowHeight}px`,
            height: "100%",
            padding: "8px",
            margin: "0px 8px 0px 16px",
          }}
          htmlFor="filter-input"
        >
          <Icon
            name="list"
            style={{
              fill: isDarkMode ? "var(--wa-900)" : "var(--ba-900)",
            }}
          />
        </label>
        <div
          style={{
            position: "absolute",
            display: "flex",
            width: "100%",
            minHeight: `${rowHeight}px`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flex: "1",
              minHeight: `${rowHeight}px`,
              alignItems: "center",
              paddingLeft: "50px",
            }}
          >
            {filters
              ?.filter((el) => el.value !== null)
              ?.map((single_filter) => (
                <SearchItem
                  key={single_filter.key}
                  filter={single_filter}
                  setIsFilterMenuInputClosed={setIsFilterMenuInputClosed}
                  setSelectedFilterKey={setSelectedFilterKey}
                  filters={filters}
                  onFilter={onFilter}
                />
              ))}

            <div
              style={{
                display: "flex",
                flex: "1",
              }}
            >
              <Input
                readOnly
                style={{
                  position: "relative",
                  flex: "1",
                  paddingLeft: "16px",
                  minHeight: `${rowHeight}px`,
                  outline: "0",
                  background: "transparent",
                }}
                id="filter-input"
                autoComplete="off"
                onClick={() => {
                  setIsFilterMenuClosed(false);
                }}
                type="text"
                name="filter"
              />

              {!isFilterMenuClosed && (
                <FilterDropdownMenu
                  dropdownTop={dropdownTop}
                  filters={filters}
                  setIsFilterMenuClosed={setIsFilterMenuClosed}
                  setIsFilterMenuInputClosed={setIsFilterMenuInputClosed}
                  setSelectedFilterKey={setSelectedFilterKey}
                />
              )}

              {selectedFilterKey && !isFilterMenuInputClosed && (
                <FilterModalInput
                  filterModalInputTop={filterModalInputTop}
                  filterModalInputLeft={filterModalInputLeft}
                  selectedFilterName={getAttrOfItemBykey(selectedFilterKey, localFilters, "name")}
                  selectedFilterKey={selectedFilterKey}
                  setLocalFilters={setLocalFilters}
                  setIsFilterMenuInputClosed={setIsFilterMenuInputClosed}
                  filters={filters}
                  localFilters={localFilters}
                  onFilter={onFilter}
                />
              )}
            </div>
          </div>
        </div>
      </FilterWrapper>
    </>
  );
};

export default FilterComponent;
