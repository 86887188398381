import React from "react";
import Switch from "rc-switch";

import axios, { AxiosError } from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { HostType } from "../../types/host";
import { decryptObject, encryptObject } from "../../../../lib/crypt";
import { config } from "../../../../config";
import { useMutation } from "@apollo/client";
import { DELETE_SOURCE, UPDATE_SOURCE } from "../../../../apollo/graphql/source";
import { successToast } from "../../../../constant/customToast";
import DisplayError from "../../../Error/ErrorComponent";
import Icon from "../../../Icon";
import Button from "../../../Button/Button";
import Select from "../../../Select/Select";
import TextInput from "../../../Form/Input/TextInput/TextInput";
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import { MediaInputlayoutType } from "../../MainComponent";

export type Source = {
  id?: string;
  isActive: boolean;
  src: string;
  hostId: string;
  mediaId?: string;
  host?: HostType[];
  createdAt?: string;
  updatedAt?: string;
};

export const getAttrByID = (key: any, arrayOfObject: any, targetId: any) => {
  // console.log(key, arrayOfObject, targetId);

  if (arrayOfObject && arrayOfObject.length > 0 && key && targetId) {
    return arrayOfObject.filter((h: any) => h.id === targetId)[0][key];
  }
};

const upload_source = async (
  file: File,
  api: any,
  updateSourceHandler: any,
  setUploadLoader: any,
  set_file: any,
  parentInfo: any,
  mediaType: any,
  thumbnailFile: any
) => {
  if (file) {
    try {
      let body = new FormData();
      body.append("buffer_file", file);
      if (mediaType.toUpperCase() === "VIDEO") {
        body.append("thumbnail", thumbnailFile);
      }
      body.append("parentInfo", encryptObject(parentInfo));

      // ${api}
      const response_api: any = await axios({
        method: "post",
        url: `${config.PEP}/api/${mediaType}/${api}/upload`,
        data: body,
      });
      // console.log(response_api);

      updateSourceHandler({
        src: response_api.data.link,
      });
      setUploadLoader(false);
      set_file(undefined);
    } catch (error) {
      setUploadLoader(false);
      set_file(undefined);

      // console.log(error);

      const err = error as AxiosError;
      if (err.response) {
        // console.log(err.response.status);
        // console.log(err.response.data);

        Swal.fire({
          icon: "error",
          title: `Error with http status code: ${err.response.status}`,
          html: `${err.response.data}`,
        });
      }
    }
  }
};
interface SourceComponentProps {
  layout: MediaInputlayoutType;
  source: Source;
  index: number;
  isMediasMin: boolean;
  hosts: any;
  folder: string;
}
const SourceComponent: React.FC<SourceComponentProps> = ({ source, index, isMediasMin, hosts, folder, layout }) => {
  const [updateSourceMutation, { loading, error }] = useMutation(UPDATE_SOURCE, {
    refetchQueries: ["SOURCES_IDS", "SOURCE"],
    fetchPolicy: "no-cache",
  });

  const [deleteSourceMutation, { loading: deleteSourceLoading, error: deleteSourceError }] = useMutation(
    DELETE_SOURCE,
    {
      refetchQueries: ["SOURCES_IDS"],
      fetchPolicy: "no-cache",
    }
  );

  const [isMinimized, setIsMinimized] = React.useState(true);
  const [file, set_file] = React.useState<File>();
  const [thumbnailFile] = React.useState<File>();
  const [uploadLoader, setUploadLoader] = React.useState(false);

  const apiName = getAttrByID("name", hosts, source.hostId);

  const mediaType = hosts[0].type.toUpperCase();

  const [isActive, setIsActive] = React.useState(source.isActive ? "ON" : "OFF");

  const [domain, setDomain] = React.useState(source.hostId);

  const [src, setSrc] = React.useState<string>(source.src);
  const [isImageRemoved, setIsImageRemoved] = React.useState(true);

  const updateSourceHandler = async (newUpdates: any) => {
    if (!loading && updateSourceMutation !== null) {
      let requiredData: any = {};

      if (!newUpdates.hostId) {
        requiredData = { hostId: source.hostId };
      }

      if (apiName) {
        if (!newUpdates.apiName) {
          requiredData = { apiName };
        }
      } else {
        throw new Error("no api name detected");
      }

      let finalData = {
        id: source.id,
        mediaId: source.mediaId,
      };

      // await console.log("<< finalData => ", finalData);
      // await console.log("newUpdates => ", newUpdates);
      // await console.log("requiredData => ", requiredData);

      finalData = {
        ...newUpdates,
        ...requiredData,
        ...finalData,
      };

      // await console.log("finalData >> => ", finalData);

      if (!finalData.mediaId) {
        throw new Error("no mediaId");
      }

      const { data } = await updateSourceMutation({
        variables: {
          challenge: encryptObject(finalData),
        },
      });
      if (!data) {
        // console.log("something went wrong 01");
      }
      // console.log(data);

      const { msg } = decryptObject(data["updateSource"]);
      if (!msg) {
        // console.log("something went wrong 02");
      }

      if (msg === "updated") {
        // if (clearForm) {
        //   setStatus(StatusTypes.DRAFT);
        //   setUrl("");
        //   setTitle("");
        //   setSubtitle("");
        //   set_sources(defaultSource);
        //   setDescription("");
        // }

        successToast("weve updated your source for you");

        // if (redirectToList) {
        //   // navigateTo(`/sources`);
        //   // not working : refetchQueries not working when using string array after mutation #5419
        //   // https://github.com/apollographql/apollo-client/issues/5419
        //   window.location.href = `/sources`;
        // }
      }
    }
  };

  React.useEffect(() => {
    setSrc(source.src);
  }, [source.src]);

  React.useEffect(() => {
    const runMe = async () => {
      if (source.src !== undefined && source.src !== "") {
        // console.log("runned");

        const respImage = await fetch(source.src, { method: "HEAD" });
        // console.log(respImage);

        // console.log(respImage.url.includes("removed.png"));

        if (respImage.url.includes("removed.png")) {
          setIsImageRemoved(false);
          // console.log("set to empty");
        }
      }
    };

    runMe();
  }, [source.src]);

  if (loading) {
    return <LoadingComponent loadingUniqueReference="af223b24-20ae-4548-a3e0-60ec61914fc5" message="Media" />;
  }

  if (deleteSourceLoading) {
    return <LoadingComponent loadingUniqueReference="af223b24-20ae-4548-a3e0-60ec61914fc5" message="Delete Source" />;
  }

  const possibleError = deleteSourceError || error;
  if (possibleError) {
    return <DisplayError errorUniqueReference="1f8b2ab9-fbb9-4a15-bbe1-6527ece3d4e5" error={possibleError} />;
  }

  return (
    <div
      className="source-container"
      style={{
        borderColor: source.id || src === "" ? "rgba(0,0,0,.6)" : "#c88b8b",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: isMinimized ? "center" : "normal",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: isMediasMin ? "column-reverse" : "row",
          }}
        >
          {isMinimized && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {mediaType === "IMAGE" && (
                <div>
                  {isImageRemoved && src && (
                    <img
                      style={{
                        maxHeight: "150px",
                      }}
                      alt={`source N° ${index + 1}`}
                      src={src}
                    />
                  )}
                  {(!isImageRemoved || !src) && (
                    <div className="image-container">
                      <Icon size="256px" name="image" />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="flex-center">
            {!isMediasMin && (
              <h2>
                Source N° {index + 1} from {"  "}
                {getAttrByID("name", hosts, source.hostId)} API
              </h2>
            )}

            {isMediasMin && (
              <h3>
                N° {index + 1} &nbsp;{getAttrByID("name", hosts, source.hostId)}
              </h3>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Button onClick={() => setIsMinimized(!isMinimized)}>
            <Icon name={!isMinimized ? "collapse" : "expand"} />
          </Button>

          { layout === "edit" && (
            <Button
              style={{
                background: "var(--p3)",
              }}
              onClick={async () => {
                const result: any = await Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this! " + source.id,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                });

                if (result.isConfirmed) {
                  const { data } = await deleteSourceMutation({
                    variables: {
                      challenge: encryptObject({ id: source.id, folder }),
                    },
                  });

                  if (!data) {
                    // console.log("something went wrong 01");
                  }
                  // console.log(data);

                  const { msg } = decryptObject(data["deleteSource"]);
                  if (!msg) {
                    // console.log("something went wrong 02");
                  }

                  if (msg === "deleted") {
                    successToast("weve deleted your source for you");
                  }
                }
              }}
            >
              <Icon name="trash" />
            </Button>
          )}
        </div>
      </div>
      {!isMinimized && layout === "edit" && (
        <div
          style={{
            display: "flex",
            marginTop: "16px",
            flexDirection: "column",
          }}
        >
          <div className="info-block">
            <div>Created At :</div>
            <div>
              <Button>{moment(source.createdAt).format("dddd DD MMM YYYY - HH:mm")}</Button>
            </div>
          </div>

          <div className="info-block">
            <div>Updated At :</div>
            <div>
              <Button>
                {source.updatedAt !== source.createdAt ? (
                  moment(source.updatedAt).format("dddd DD MMM YYYY - HH:mm")
                ) : (
                  <Icon color="gray" name="close" />
                )}
              </Button>
            </div>
          </div>

          <div className="info-block">
            <div>isActive :</div>
            <div>
              <Switch
                checked={isActive === "ON"}
                onChange={(_checked) => {
                  setIsActive(_checked ? "ON" : "OFF");
                }}
                onBlur={() => {
                  let cmp: string = source.isActive ? "ON" : "OFF";
                  if (isActive !== cmp) {
                    updateSourceHandler({
                      isActive: isActive === "ON" ? true : false,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="info-block">
            <div>Domain ( hostId ) :</div>
            <div>
              {hosts && (
                <Select
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                  onBlur={() => {
                    // console.log("domain => ", domain);
                    // console.log("source.hostId => ", source.hostId);

                    if (domain !== source.hostId) {
                      updateSourceHandler({
                        hostId: domain,
                        host_changed: true,
                      });
                    }
                  }}
                >
                  {hosts &&
                    hosts.map((host: any) => (
                      <option key={host.id} value={host.id}>
                        {host.domain}
                      </option>
                    ))}
                </Select>
              )}
            </div>
          </div>
          <div className="info-block">
            <div>Upload :</div>
            <div>
              {mediaType === "IMAGE" && (
                <>
                  <input
                    accept="image/*"
                    onChange={(e) => {
                      const form_file = e.target.files && e.target.files[0];
                      if (form_file) {
                        // console.log(form_file);
                        set_file(form_file);
                      }
                    }}
                    type="file"
                  />
                </>
              )}

              <Button
                disabled={file && !uploadLoader ? false : true}
                onClick={() => {
                  setUploadLoader(true);
                  file &&
                    upload_source(
                      file,
                      apiName,
                      updateSourceHandler,
                      setUploadLoader,
                      set_file,
                      { id: source.id, src: source.src, folder },
                      mediaType.toLowerCase(),
                      thumbnailFile
                    );
                }}
              >
                <Icon name={uploadLoader ? "upload" : "plus"} />
                {uploadLoader ? "uploading..." : "upload"}
              </Button>
            </div>
          </div>
          {mediaType === "IMAGE" && file && (
            <>
              <div className="info-block">
                <div>Image preview :</div>
                <div></div>
              </div>
              <div className="info-block">
                <img alt={`source file N° ${index + 1}`} src={URL.createObjectURL(file)} />
              </div>
            </>
          )}

          {mediaType === "VIDEO" && thumbnailFile && (
            <>
              <div className="info-block">
                <div>Image preview :</div>
                <div></div>
              </div>
              <div className="info-block">
                <img alt={`source thumbnail file N° ${index + 1}`} src={URL.createObjectURL(thumbnailFile)} />
              </div>
            </>
          )}

          <div className="info-block">
            <div>Src :</div>
            <div>
              <TextInput
                value={src}
                onChange={(e) => {
                  setSrc(e.target.value);
                }}
                onBlur={() => {
                  if (src !== source.src) {
                    updateSourceHandler({
                      src,
                    });
                  }
                }}
              />
            </div>
          </div>

          {mediaType === "IMAGE" && (
            <div className="flex-column">
              <div className="flex-center pb16 info-block">
                <div>Preview :</div>
                <div></div>
              </div>
              <div className="flex-center pb16">
                {isImageRemoved && source.src !== "" && source.src && (
                  <img alt={`source N° ${index + 1}`} src={source.src} />
                )}
                {(!source.src || !isImageRemoved) && <DummyImage />}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SourceComponent;

const DummyImage = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "500px",
      background: "rgba(0,0,0,0.7)",
    }}
  >
    <Icon size="256px" name="image" />
  </div>
);
