// Types
import { RouteType, RouteTypeWithElement } from "../../types/RouteTypes";

// Pages
import PaletteEntypoint from "../../../../pages/Apps/Palettes";

export const palettesPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Palettes Page",
    name: "Palettes-page",
    table: "palette",
    absolutePath: "/apps/palettes",
    path: "",
    Element: PaletteEntypoint,
  },
];

export const palettesPageRoutesMetadata: RouteType[] = palettesPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
