// Modules
import React from "react";
import Button from "../../../../Components/Button/Button";
import Icon from "../../../../Components/Icon";

// Hooks
import { useOnScreen } from "../../../../hooks/useOnScreen";
import { desecureText } from "../../../../lib/desecureText";

// Types
import { EntityColumnType, EntityDataType } from "../../types/Entity";

// Components
// Children
import RenderElement from "./RenderElement/RenderElement";

interface EntityProps {
  index: any;
  entity: any;
  onUpdateEntity: () => void;
  onDeleteEntity: () => void;
  entityDataType: EntityDataType;
  listEntitiesLowerCaseName: string;
}

const Entity: React.FC<EntityProps> = ({
  index,
  onUpdateEntity,
  onDeleteEntity,
  entity,
  entityDataType,
  listEntitiesLowerCaseName,
}) => {
  // Ref for the element that we want to detect whether on screen
  const ref = React.useRef<any>();
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.
  const onScreen = useOnScreen(ref, "-300px");

  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (onScreen && !visible) {
      setVisible(true);
    }
  }, [onScreen, visible]);

  // Every entity have 'createdAt' & updatedAt
  // will change updatedAt to empty string if it's equal to createdAt
  const checkIfCreatedAndUpdatedAtExist = entityDataType.map((el) => el.key === "createdAt" || el.key === "updatedAt");
  const countTrueValue = checkIfCreatedAndUpdatedAtExist.filter(Boolean).length;

  let _entity: any = { ...entity };
  if (countTrueValue === 2) {
    const { createdAt, updatedAt } = entity;
    if (createdAt === updatedAt) {
      _entity.updatedAt = "";
    }
  }

  const leftSideElements: EntityDataType = entityDataType.filter((el) => el.side === "left");
  const rightSideElements: EntityDataType = entityDataType.filter((el) => el.side === "right");

  return (
    <div className="entity" ref={ref}>
      <h2 className="number">{index + 1}</h2>
      <div className="left-side">
        {leftSideElements.map((entityDataColumn: EntityColumnType) => (
          <div key={entityDataColumn.key}>
            <RenderElement
              listEntitiesLowerCaseName={listEntitiesLowerCaseName}
              columnDataType={entityDataColumn}
              columnDataValue={desecureText(_entity[entityDataColumn.key])}
              entity={_entity}
            />
          </div>
        ))}
      </div>
      <div className="right-side">
        <div className="flex-block action-block">
          <Button onClick={() => onUpdateEntity()}>
            <Icon name="edit" />
          </Button>

          <Button colorScheme="#de5246" onClick={() => onDeleteEntity()}>
            <Icon name="trash" />
          </Button>
        </div>
        {rightSideElements.map((entityDataColumn: EntityColumnType) => (
          <div key={entityDataColumn.key}>
            <RenderElement
              listEntitiesLowerCaseName={listEntitiesLowerCaseName}
              columnDataType={entityDataColumn}
              columnDataValue={desecureText(_entity[entityDataColumn.key])}
              entity={_entity}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Entity;
