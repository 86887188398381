// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import QuoteEntypoint from "../../../pages/Quotes";

export const quotesPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Quotes Page",
    name: "quotes-page",
    table: "quote",
    absolutePath: "/quotes",
    path: "",
    Element: QuoteEntypoint,
  },
];

export const quotesPageRoutesMetadata: RouteType[] = quotesPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
