import { useQuery } from "@apollo/client";
import React from "react";
import { QUERY_TASK_MANAGER_SETTINGS } from "../../../apollo/graphql/task_manager";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import DisplayError from "../../../Components/Error/ErrorComponent";
import { decryptObject, encryptObject } from "../../../lib/crypt";
import TaskManagerProvider from "../context/provider";

const QueryTaskManagerSettings: React.FC = () => {
  const [_challenge] = React.useState(encryptObject({ bypass: "true" }));
  const { data, error, loading } = useQuery(QUERY_TASK_MANAGER_SETTINGS, {
    variables: { challenge: _challenge },
  });

  if (loading) {
    <LoadingComponent
      loadingUniqueReference="2863e00c-95ce-4e03-845e-0d9f2a021bfc"
      message={`task manager settings`}
    />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="5301aa84-aeaf-479c-9149-4af184a3fa1c" error={error} />;
  }

  if (data && data.queryTaskManagerSettings) {
    const encryptedData = data && data.queryTaskManagerSettings;
    const { settings } = decryptObject(encryptedData);

    if (settings && settings.length > 0) {
      //console.log("settings=>", settings);
      return <TaskManagerProvider settings={JSON.parse(settings)} />;
    } else {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Settings table is empty, please fill up with data.
        </div>
      );
    }
  } else {
    return null;
  }
};

export default QueryTaskManagerSettings;
