// Modules
import React from "react";

// Hooks
import { useCreatePortalInBody } from "../../../hooks/useCreatePortalInBody";

interface ErrorModalProps {
  children: any;
  errorUniqueReference?: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ children, errorUniqueReference }) => {
  const createBodyPortal = useCreatePortalInBody(errorUniqueReference);

  return createBodyPortal(
    <div className="error-page">
      <div className="error-wrapper">
        <div className="error-message">{children}</div>
      </div>
    </div>
  );
};

export default ErrorModal;
