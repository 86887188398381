// Modules
import React from "react";
import useDarkMode from "use-dark-mode";

interface DropdownMenuItemProps {
  children: any;
}

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({ children }, props) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === false ? "b" : "w";
  const [hover, setHover] = React.useState(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: "flex",
        flex: "1",
        cursor: "pointer",
        minHeight: "32px",
        paddingBlock: "8px",
        paddingInline: "12px",
        background: hover ? `var(--${isDarkMode}a-200)` : `var(--${isDarkMode}a-50)`,
      }}
      {...props}
    >
      {children}
      <div></div>
    </div>
  );
};

export default DropdownMenuItem;
