import { gql } from "@apollo/client";

export const SOURCES_IDS = gql`
  query SOURCES_IDS($challenge: String = "") {
    sourcesIds(challenge: $challenge)
  }
`;

export const SOURCE = gql`
  query SOURCE($challenge: String = "") {
    source(challenge: $challenge)
  }
`;

export const CREATE_SOURCE = gql`
  mutation CREATE_SOURCE($challenge: String!) {
    createSource(challenge: $challenge)
  }
`;

export const BATCH_UPLOAD_SOURCE = gql`
  mutation BATCH_UPLOAD_SOURCE($challenge: String!) {
    batchUploadSource(challenge: $challenge)
  }
`;
export const UPDATE_SOURCE = gql`
  mutation UPDATE_SOURCE($challenge: String!) {
    updateSource(challenge: $challenge)
  }
`;

export const DELETE_SOURCE = gql`
  mutation DELETE_SOURCE($challenge: String!) {
    deleteSource(challenge: $challenge)
  }
`;
