// Modules
import React from "react";
import { Link, NavLink } from "react-router-dom";

// Components
/// Global
import DarkModeToggle from "../../../Components/DarkModeToggle/DarkModeToggle";
import Icon from "../../../Components/Icon";
/// Children
import DropdownMenuComponent from "./DropdownMenu/DropdownMenuComponent";

// Constants
import { navigation } from "./navigation";
import { LayoutStates } from "../Context/LayoutStates";
import MotionImage from "./MotionImage";
import { useLayout } from "../Context/useLayout";
import useMediaQuery from "../../../hooks/useMediaQuery";

// Functions

const getLogoType = (layout: LayoutStates, active: boolean, largeLogo: boolean) => {
  switch (+layout) {
    case LayoutStates.LARGE_SIDEBAR:
    case LayoutStates.MEDIUM_SIDEBAR:
      return "text";

    case LayoutStates.AUTO:
      if (largeLogo) {
        return "text";
      } else {
        if (active) return "text";

        return "only";
      }

    case LayoutStates.SMALL_SIDEBAR:
    case LayoutStates.MOBILE_SIDEBAR:
      if (active) return "text";
      return "only";

    default:
      return "only";
  }
};

interface SidebarComponentProps {
  isVisible: boolean;
  onClose: () => void;
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({ isVisible, onClose }) => {
  const largeLogo = useMediaQuery("(min-width: 1259px)");

  const { layout } = useLayout();
  // Hooks
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    return () => {
      setActive(false);
    };
  }, []);

  return (
    <>
      <aside className={`sidebar${isVisible ? " visible" : ""}${active ? " active" : ""}`}>
        <button className="close" onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <div className="logo-container">
          <Link className="logo" to="/" onClick={onClose}>
            <MotionImage
              key={`${getLogoType(layout, active, largeLogo) === "text"}-${getLogoType(layout, active, largeLogo)}`}
              width={getLogoType(layout, active, largeLogo) === "text" ? 214 : 48}
              srcVarient={getLogoType(layout, active, largeLogo)}
            />
          </Link>
        </div>
        <div className="menu">
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={({ isActive }) => (isActive ? "item active" : "item")}
                to={x.url}
                key={index}
                onClick={onClose}
              >
                <Icon name={x.icon} size="24" />
                {x.title}
              </NavLink>
            ) : (
              <DropdownMenuComponent
                visibleSidebar={active}
                setValue={setActive}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className="toggle" onClick={() => setActive(!active)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className="foot">
          <Link to="/settings" className="link">
            <Icon name="setting" size="24" />
            Settings
          </Link>
          <DarkModeToggle visibleSidebar={active} />
        </div>
      </aside>
      <div className={`overlay${active ? " active" : ""}`} onClick={() => setActive(false)}></div>
    </>
  );
};

export default SidebarComponent;
