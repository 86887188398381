import React from "react";
import { LoadingContext } from "./LoadingContext";

export function useLoading() {
  const context = React.useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
