import React from "react";

interface FieldProps {
  children: any;
}

type TExtendedProps = FieldProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Field: React.FC<TExtendedProps> = ({ children, ...props }) => {
  return (
    <div className="field" {...props}>
      {children}
    </div>
  );
};

export default Field;
