// Modules
import React from "react";
import { Outlet } from "react-router-dom";

// Components
import TabComponent from "../../../Components/Tab/Tab";

// Metadata
import { backupDataPageRoutesMetadata } from "../../../App/Routes/Preferences/BackupData/BackupDataMetadata";

const BackupDataPage: React.FC = () => {
  const { children, absolutePath } = backupDataPageRoutesMetadata[0];

  return (
    <div>
      <div className="h3 title">Backup Data page</div>
      {children && <TabComponent absolutePath={absolutePath} chlids={children} />}
      <Outlet />
    </div>
  );
};

export default BackupDataPage;
