// Modules
import React, { useId } from "react";
import colorConvert from "color-convert";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

// Constant
const style = getComputedStyle(document.body);

const getRGB = (colorScheme: any) => {
  // console.log("colorScheme =>", colorScheme);

  if (!colorScheme) return "255 255 255";

  let rgbValue;

  if (colorScheme.includes("$")) {
    rgbValue = colorConvert.hex.rgb(style.getPropertyValue(`--${colorScheme.replace("$", "")}`));

    // console.log(rgbValue);
  } else if (colorScheme.includes("#")) {
    rgbValue = colorConvert.hex.rgb(colorScheme);
  } else {
    rgbValue = colorConvert.keyword.rgb(colorScheme);
  }

  if (rgbValue) {
    // console.log(rgbValue);

    return rgbValue.join(" ");
  } else {
    return "0 0 0";
  }
};

interface ButtonProps {
  size?: "sm" | "md" | "md1";
  full?: boolean;
  children: any;
  colorScheme?: string;
}

type TExtendedProps = ButtonProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button: React.FC<TExtendedProps> = ({ size = "md", full, children, className, key, colorScheme, ...props }) => {
  const _id = useId();

  const id = _id.replaceAll(":", "");

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      {colorScheme && (
        <style lang="scss">
          {`
        .${id} {
          background: rgba(${getRGB(colorScheme)} / 1) !important;
        }
        .${id}:hover, .${id}.hover {
          background: rgba(${getRGB(colorScheme)} / .8) !important;
        }
        .${id}:active, .${id}.active {
          background: rgba(${getRGB(colorScheme)} / .5) !important;
        }
        .${id}:disabled, .${id}.disabled {
          background: rgba(${getRGB(colorScheme)} / .3) !important;
        }
        `}
        </style>
      )}

      <button
        className={`${id} button${full ? " full" : ""} ${size ? size : ""} ${className ? className : ""}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
