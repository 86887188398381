import React from "react";
import Swal from "sweetalert2";

import Button from "../Button/Button";
import Icon from "../Icon";
import SearchBox from "../SearchBox/SearchBox";

import UnlinkChildren from "./UnlinkChildren";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface MulipleSelectChildrenProps {
  childrensIds: string;
  childrensIdsReadonly: string;
  setChildrensIds: any;
  parentId: string | null;
  disabled?: boolean;
}

const sta = (arr: string) => arr.split(",");
const ats = (str: string[]) => str.join(",");

const MulipleSelectChildren: React.FC<MulipleSelectChildrenProps> = ({
  parentId,
  childrensIds,
  childrensIdsReadonly,
  setChildrensIds,
  disabled,
}) => {
  const ids = sta(childrensIds);

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div className={`muliple-select-children${disabled ? " disabled" : ""}`}>
        {disabled && <div className="shield" />}

        <div className="flex-center">
          <Button
            onClick={() => {
              let idsClone = [...ids];
              idsClone[ids.length] = "";
              setChildrensIds(ats(idsClone));
            }}
          >
            <Icon name="add" />&nbsp; LINK CHILDREN
          </Button>
        </div>

        <div className="flex-column">
          <h3>Linked children ID list:</h3>

          {ids &&
            ids.length > 0 &&
            ids.map((oneId, index) => (
              <div
                className="element-idcn"
                style={{
                  borderColor: childrensIdsReadonly.includes(oneId) ? "" : "yellow",
                }}
                key={index + "index__SearchBox"}
              >
                <SearchBox
                  inputBorderRadius="0.375rem"
                  id={oneId}
                  name={`N°${index + 1} ${!childrensIdsReadonly.includes(oneId) ? " (Unsaved on the server)" : ""}`}
                  setID={(newIdArg: string) => {
                    // check if item is already exist
                    if (ids.includes(newIdArg)) {
                      // show alert message
                      Swal.fire({
                        icon: "warning",
                        title: `The item is already exist`,
                        html: `'${newIdArg}' is already exist`,
                      });
                      // remove the elemebnt from list
                      let idsClone = [...ids];
                      idsClone.pop();
                      setChildrensIds(ats(idsClone));
                    } else {
                      // upadte new array
                      let idsClone = [...ids];
                      idsClone[index] = newIdArg;
                      setChildrensIds(ats(idsClone));
                    }
                  }}
                />

                {parentId && childrensIdsReadonly.includes(oneId) && (
                  <UnlinkChildren idToUnlink={oneId} parentId={parentId} />
                )}

                {(!parentId || !childrensIdsReadonly.includes(oneId)) && (
                  // Create mode
                  <Button
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      height: "40px"
                    }}
                    disabled={ats(ids).length <= 0}
                    title={ats(ids).length <= 0 ? "Add item first" : "Click to delete the item"}
                    onClick={() => {
                      let newArray = ids.filter((el) => el !== oneId);
                      setChildrensIds(ats(newArray));
                    }}
                  >
                    <Icon name="close" />
                  </Button>
                )}
              </div>
            ))}
        </div>

        <div className="flex-column-wrap">
          <hr />
          RAW: <br />
          <div>{childrensIds}</div>
        </div>
      </div>
    </>
  );
};

export default MulipleSelectChildren;
