import { EntityDataType } from "../Base/types/Entity";

export const projectDataType: EntityDataType = [
  {
    key: "id",
    name: "ID",
    unique: true,
    canEdit: false,
    side: "right",
    renderAs: "copy-id-box",
    searchType: "equal",
    type: "string",
    required: false,
  },
  {
    key: "title",
    name: "Title",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "title-name-tags-group",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "medias",
    name: "Images",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "images-upload",
    searchType: "contains",
    type: "string",
    required: false,
  },
  {
    key: "name",
    name: "Name",
    unique: true,
    canEdit: true,
    side: "left",
    renderAs: "title-name-tags-group",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "tags",
    name: "Tags",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "title-name-tags-group",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "subtitle",
    name: "Subtitle",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "text",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "status",
    name: "Status",
    unique: false,
    canEdit: true,
    side: "right",
    renderAs: "status-select",
    searchType: "equal",
    type: "string",
    required: true,
  },
  {
    key: "category",
    name: "Category",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "categories-select",
    searchType: "equal",
    type: "string",
    required: true,
  },
  {
    key: "publishDate",
    name: "Publish Date",
    unique: false,
    canEdit: true,
    side: "right",
    renderAs: "date",
    searchType: "equal",
    type: "date",
    required: true,
  },
  {
    key: "views",
    name: "Views",
    unique: false,
    canEdit: false,
    side: "right",
    renderAs: "text",
    searchType: "equal",
    type: "string",
    required: false,
  },
  {
    key: "body",
    name: "Body",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "md-editor",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "createdAt",
    name: "Created At",
    unique: false,
    canEdit: false,
    required: false,
    side: "right",
    renderAs: "datetime",
    searchType: "equal",
    type: "date",
  },
  {
    key: "updatedAt",
    name: "Updated At",
    unique: false,
    canEdit: false,
    required: false,
    side: "right",
    renderAs: "datetime",
    searchType: "equal",
    type: "date",
  },
];
