// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { palettesPageRoutesWithElementMetadata } from "./palettesMetaData";

const PalettesPageRoutes: React.FC = () => {
  return (
    <Routes>
      {palettesPageRoutesWithElementMetadata.map(({ path, Element }, palette) => (
        <Route key={palette} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default PalettesPageRoutes;
