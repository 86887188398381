// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { taskManagerPageRoutesWithElementMetadata } from "./taskManagerMetaData";

const TaskManagerPageRoutes: React.FC = () => {
  return (
    <Routes>
      {taskManagerPageRoutesWithElementMetadata.map(({ path, Element }, taskManager) => (
        <Route key={taskManager} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default TaskManagerPageRoutes;
