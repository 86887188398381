// Modules
import React from "react";
import { useQuery } from "@apollo/client";

// Queries
import { DOMAINS } from "../../../apollo/graphql/knowledge";

// Functions
import { decryptObject, encryptObject } from "../../../lib/crypt";

export const useQueryDomains = () => {
  const [challenge] = React.useState(encryptObject({ bypass: true }));

  const { data, error, loading } = useQuery(DOMAINS, {
    variables: { challenge },
  });

  if (loading) return { error: null, loading, data: null };

  if (error) return { error: error.message, loading: false, data: null };

  if (data && data.domains) {
    const domains = decryptObject(data.domains).domains;

    if (decryptObject(data.domains).domains) {
      return { error: null, loading: false, data: [...domains] };
    } else {
      return { error: "error 41534486", loading: false, data: null };
    }
  } else {
    return { error: "error 5646743", loading: false, data: null };
  }
};
