/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptObject, encryptObject } from "../../lib/crypt";
import Button from "../Button/Button";
import Icon from "../Icon";
import Select from "../Select/Select";

const listRowsPerPage = [10, 30, 50, 100, 1000];

interface PaginationProps {
  pages: number;
  entity: string;
}

const Pagination: React.FC<PaginationProps> = ({ pages, entity }) => {
  const navigateTo = useNavigate();

  let challenge: any;
  const location = useLocation();
  if (location.search) {
    const params = new URLSearchParams(location.search);
    if (params.has("q")) {
      challenge = decryptObject(params.get("q"));
    }
  }

  if (!challenge) {
    challenge = {
      page: 1,
      limit: 10,
    };
  }

  const { page, limit } = challenge;

  const [_limit, setLimit] = React.useState(limit);

  React.useEffect(() => {
    if (_limit !== limit) {
      navigateTo(
        `/${entity}?q=${encryptObject({
          page: 1,
          limit: _limit,
        })}`
      );
    }
  }, [_limit]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            transform: "rotate(180deg)",
            height: "40px",
            borderRadius: "0 12px 12px 0",
          }}
          disabled={page === 1}
          onClick={() =>
            navigateTo(
              `/${entity}?q=${encryptObject({
                page: 1,
                limit,
              })}`
            )
          }
        >
          <Icon name="arrow-next" />
          <Icon name="arrow-next" />
        </Button>
        <Button
          style={{
            height: "40px",
            borderRadius: "0",
          }}
          disabled={page === 1}
          onClick={() =>
            navigateTo(
              `/${entity}?q=${encryptObject({
                page: page - 1,
                limit,
              })}`
            )
          }
        >
          <Icon name="arrow-left" />
        </Button>
        <span
          style={{
            display: "inline-flex",
            paddingInline: "16px",
            alignItems: "center",
            background: "var(--p1)",
            height: "40px",
          }}
        >
          {page} / {pages}
        </span>
        <span>
          <Select
            style={{
              background: "var(--p1)",
              borderRadius: 0,
            }}
            value={limit}
            onChange={(e) => setLimit(+e.target.value)}
            name="limit"
            id="limit"
          >
            {listRowsPerPage.map((element) => (
              <option value={element} key={element}>
                {element}
              </option>
            ))}
          </Select>
        </span>
        <Button
          style={{
            height: "40px",
            borderRadius: "0",
          }}
          onClick={() =>
            navigateTo(
              `/${entity}?q=${encryptObject({
                page: page + 1,
                limit,
              })}`
            )
          }
          disabled={page === pages}
        >
          <Icon name="arrow-right" />
        </Button>
        <Button
          style={{
            height: "40px",
            borderRadius: "0 12px 12px 0",
          }}
          disabled={page === pages}
          onClick={() =>
            navigateTo(
              `/${entity}?q=${encryptObject({
                page: pages,
                limit,
              })}`
            )
          }
        >
          <Icon name="arrow-next" />
          <Icon name="arrow-next" />
        </Button>
      </span>
    </div>
  );
};

export default Pagination;
