// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import KnowledgeEntypoint from "../../../pages/Knowledges";
import KnowledgesMap from "../../../Components/KnowledgesMap/KnowledgesMap";

export const knowledgePageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Knowledges Page",
    name: "knowledges-page",
    table: "knowledge",
    absolutePath: "/knowledges",
    path: "",
    Element: KnowledgeEntypoint,
  },
  {
    title: "Knowledges Map Page",
    name: "knowledges-map-page",
    absolutePath: "/knowledges/map",
    path: "map",
    Element: KnowledgesMap,
  },
];

export const knowledgePageRoutesMetadata: RouteType[] = knowledgePageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
