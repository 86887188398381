import { toast, ToastOptions } from "react-toastify";
import { config } from "../config";

export const servicesEndpoint = `${config.EP.replace("/graphql", "")}/xxappxx`;

export const tables = ["knowledge", "setting", "task"];

const options: ToastOptions<{}> | undefined = {
  position: "top-right",
  autoClose: 500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const successToast = (message: string) => toast.success(message, options);
export const errorToast = (message: string) => toast.error(message, { autoClose: 5000, ...options });
export const warningToast = (message: string) => toast.warning(message, { autoClose: 5000, ...options });

export const onError = (message: any) => {
  errorToast(message);
  console.error(message);
};
