import React from "react";
import PersistHosts from "./containers/PersistHosts";
import { MediaInputlayoutType } from "./MainComponent";

interface Props {
  layout: MediaInputlayoutType;
  relatedTableId: string;
  folder: string;
}

const MediasInput: React.FC<Props> = ({ relatedTableId, folder, layout }) => {
  return <PersistHosts relatedTableId={relatedTableId} folder={folder} layout={layout} />;
};

export default MediasInput;
