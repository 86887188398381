import React from "react";
import { LayoutContext } from "./LayoutContext";

export const LayoutProvider = ({ children, defaultLayoutValue }: any) => {
  const [layout, setLayout] = React.useState(defaultLayoutValue);

  const contextValue = React.useMemo(
    () => ({
      layout,
      setLayout,
    }),
    [layout, setLayout]
  );

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};
