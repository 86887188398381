// Modules
import React from "react";

// Functions
import { importData } from "../../../../maintenance/importData";

// Components
import Button from "../../../../Components/Button/Button";
import { getTables } from "../getTables";

const ImportDataPage: React.FC = () => {
  const [detectTableName, setResultOfDetectTableName] = React.useState("");

  const tables = getTables();
  return (
    <div>
      ImportDataPage
      <br />
      <form id="importDataForm" encType="multipart/form-data">
        <input
          onChange={(e) => {
            const valFilename = e.target.value;
            if (valFilename) {
              console.log(valFilename);
              
              const pureFilenameWithExtention = valFilename.split(/[\\/]/).pop();

              if (pureFilenameWithExtention?.includes(".json")) {
                const getTableName = pureFilenameWithExtention.split("_")[1].slice(0, -1);
                setResultOfDetectTableName(getTableName);
              }
            }
          }}
          id="importDataInputFile"
          type="file"
          name="fileUpload"
        />
      </form>
      <br />
      <div>
        {tables.length > 0 &&
          tables.map((table: any, index: any) => (
            <span key={`import-${index}`}>
              {detectTableName !== "" &&
                (detectTableName.includes(table) || detectTableName.slice(0, -2).includes(table)) && (
                  <Button style={{ margin: "8px" }} onClick={() => importData(table)}>
                    Import {table}s
                  </Button>
                )}
            </span>
          ))}
      </div>
    </div>
  );
};

export default ImportDataPage;
