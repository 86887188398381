// Modules
import React from "react";

// Components
import Select from "../../../Select/Select";

interface FakeSpecificationsProps {}

const FakeSpecifications: React.FC<FakeSpecificationsProps> = () => {
  return (
    <div className="form-group" style={{ marginBottom: "4px", marginRight: "16px", width: `${100 / 3}%` }}>
      <label style={{ marginBottom: "4px" }} htmlFor="specification">
        Specification:
      </label>
      <Select disabled={true} id="specification" value="">
        <option value="">Select a domain first</option>
      </Select>
    </div>
  );
};

export default FakeSpecifications;
