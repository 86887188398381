import React, { useMemo, useState } from "react";
import { TaskManagerContext } from ".";
import TaskManagerPage from "../pages";
import { useTime } from "../hooks/useTime";
import { defaultValueTimeZoom } from "../MainPanel/SliderMarkZoomTime/constants";
import { getTheNearElement } from "../utils";
import moment from "moment";
import { APP_DATE_FORMAT } from "../../../lib/date";

interface TaskManagerProviderProps {
  settings: any;
}

const TaskManagerProvider: React.FC<TaskManagerProviderProps> = ({ settings }) => {
  const { timeZoom, selected } = settings;
  let currentTimelineDate = settings.currentTimelineDate || moment().format(APP_DATE_FORMAT);

  const dbg = false;

  const timeNow = useTime(1000);
  
  const nearest = getTheNearElement(timeNow, defaultValueTimeZoom);

  const defaultStore = useMemo(
    () => ({ server: { timeZoom, selected, currentTimelineDate }, client: { timeNow, nearest } }),
    [timeZoom, timeNow, nearest, selected, currentTimelineDate]
  );

  dbg && console.log("TaskManagerProvider >  defaultStore => ", defaultStore);

  const [taskManagerStore, setTaskManagerStore] = useState(defaultStore);

  dbg && console.log("TaskManagerProvider >  taskManagerStore => ", taskManagerStore);

  const taskManagerStoreValue = { taskManagerStore, setTaskManagerStore };

  dbg && console.log("TaskManagerProvider >  taskManagerStoreValue => ", taskManagerStoreValue);

  // update the hole store when the time change

  React.useEffect(() => {
    setTaskManagerStore(defaultStore);

    // return () => {
    //   second
    // }
  }, [defaultStore]);

  return (
    <TaskManagerContext.Provider value={taskManagerStoreValue}>
      <TaskManagerPage />
    </TaskManagerContext.Provider>
  );
};

export default TaskManagerProvider;
