import React from "react";
import TextInput from "../../../../../../Components/Form/Input/TextInput/TextInput";

interface DateInputEditorProps {
  xKey: string;
  name: string;
  get: string;
  set: any;
  required: boolean;
}

const DateInputEditor: React.FC<DateInputEditorProps> = ({ xKey, name, get, set, required }) => {
  return (
    <div className="form-group panel"
    style={{
      width: "240px"
    }}
    >
      <label htmlFor={`${xKey.toString()}`}>{`${name.toString()}`}:</label>
      <div className="flex-block">
        <TextInput
          id={`${xKey.toString()}`}
          name={`${name.toString()}`}
          value={get}
          onChange={(e) => set(`${e.target.value.toString()}`)}
          type="date"
          autoComplete="off"
          placeholder={`Enter the ${name.toString()}`}
          required={required}
          icon="clock"
        />
      </div>
    </div>
  );
};

export default DateInputEditor;
