import React from "react";
import useDarkMode from "use-dark-mode";
import Icon from "../../../../../Components/Icon";
import { getBorder, getColor } from "../../../utils";
import { TIMELINE__HEIGHT, TIMELINE__TIME_HEIGHT } from "../timelineConstants";

interface SingleTimelineProps {
  selected: string;
  time: string;
  nearest: string;
  loading: boolean;
  timeZoom: number;
  setCurrentContextMenuItem: any;
}

const SingleTimeline: React.FC<SingleTimelineProps> = ({
  selected,
  time,
  nearest,
  loading,
  timeZoom,
  setCurrentContextMenuItem,
}) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value;

  return (
    <div
      style={{
        height: `${TIMELINE__HEIGHT}px`,
        color: selected === time ? "green" : time === nearest ? getColor("nearest") : isDarkMode ? "#BBB" : "#1a1d1f",
        fontWeight: time.split(":")[1] === "00" ? "bold" : "normal",
      }}
      className={`timeline-item ${loading ? "loading" : ""}`}
    >
      <div className="icon-wrapper">{selected === time && <Icon name="arrow-right" className="icon-timeline" />}</div>

      <span
        className={`time-holder ${loading ? "loading" : ""}`}
        id={time}
        style={{
          color: selected === time ? "green" : time === nearest ? getColor("nearest") : isDarkMode ? "#BBB" : "#1a1d1f",
          fontWeight: time.split(":")[1] === "00" ? "bold" : "normal",
        }}
        onClick={({ clientX, clientY }) => setCurrentContextMenuItem({ time, position: { x: clientX, y: clientY } })}
      >
        <div className="time-wrapper">
          <div
            className="time"
            style={{
              height: `${TIMELINE__TIME_HEIGHT}px`,
            }}
          >
            {time}
            <span
              className="time-border"
              style={{
                border:
                  selected === time && nearest === time
                    ? `1px dashed ${getColor("nearest")}`
                    : getBorder(time, nearest, timeZoom, selected),
              }}
            ></span>
          </div>
        </div>
        {/* <div
          className="timeline-item-border"
          style={{
            border: getBorder(time, nearest, timeZoom, selected),
          }}
        /> */}
      </span>
    </div>
  );
};

export default SingleTimeline;
