// Modules
import React from "react";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface CardProps {
  title?: string;
  head?: string;
  children: any;
}

type TExtendedProps = CardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Card: React.FC<TExtendedProps> = ({ title, head, children, ...props }) => {
  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <div className="card" {...props}>
        {title && (
          <div className="head">
            <div className="title">{title}</div>
            {head && head}
          </div>
        )}
        {children}
      </div>
    </>
  );
};

export default Card;
