import React from "react";
import BasePage from "../Base/BasePage";
import { getCRLUD } from "../Base/operation/get/crlud";
import { projectDataType } from "./projectsDataType";

const crlud = getCRLUD("project", "projects");

const ProjectsPage: React.FC = () => {
  return <BasePage title="Projects" crlud={crlud} entityDataType={projectDataType} />;
};

export default ProjectsPage;
