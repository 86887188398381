// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import QuestionEntypoint from "../../../pages/Questions";

export const questionsPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Questions Page",
    name: "questions-page",
    table: "question",
    absolutePath: "/questions",
    path: "",
    Element: QuestionEntypoint,
  },
];

export const questionsPageRoutesMetadata: RouteType[] = questionsPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
