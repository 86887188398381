import React from "react";
import Select from "../../../../../../Components/Select/Select";
import { statusSelectDataTypeslist } from "./statusData";

interface StatusSelectEditorProps {
  xKey: string;
  name: string;
  get: string;
  set: any;
  required: boolean;
}

const StatusSelectEditor: React.FC<StatusSelectEditorProps> = ({ xKey, name, get, set, required }) => {
  return (
    <div style={{
      width: "240px"
    }} className="form-group panel">
      <label htmlFor={`${xKey.toString()}`}>{`${name.toString()}`}:</label>
      <div className="flex-block">
        <Select
          id={`${xKey.toString()}`}
          value={get}
          onChange={(e) => set(`${e.target.value.toString()}`)}
          required={required}
        >
          <option value="">Select a {`${name.toString()}`}</option>
          {statusSelectDataTypeslist.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default StatusSelectEditor;
