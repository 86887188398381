export const convertTimeToNumber = (time: any) => {
  const hours = Number(time.split(":")[0]);
  const minutes = Number(time.split(":")[1]) / 60;
  return hours + minutes;
};

// assuming current intervals do not overlap
export const sortIntervals = (intervals: any) => {
  return intervals.sort((intA: any, intB: any) => {
    const startA = convertTimeToNumber(intA[0]);
    const endA = convertTimeToNumber(intA[1]);

    const startB = convertTimeToNumber(intB[0]);
    const endB = convertTimeToNumber(intB[1]);

    if (startA > endB) {
      return 1;
    }

    if (startB > endA) {
      return -1;
    }

    return 0;
  });
};

export const isOverlapping = (intervals: any, newInterval: any) => {
  const idIndex = 2;
  const a = convertTimeToNumber(newInterval[0]);
  const b = convertTimeToNumber(newInterval[1]);

  for (const interval of intervals) {
    const c = convertTimeToNumber(interval[0]);
    const d = convertTimeToNumber(interval[1]);

    if (a < d && b > c) {
      return {
        uuid: newInterval[idIndex] + "-" + interval[idIndex],
        this_one_overlap: newInterval,
        with_interval: interval,
      };
      //   console.log("This one overlap: ", newInterval);
      //   console.log("with interval: ", interval);
      //   console.log("----");
    }
  }

  return null;
};

export const isGoodInterval = (interval: any) => {
  let good = false;

  if (interval.length === 2) {
    // If you want you can also do extra check if this is the same day
    const start = convertTimeToNumber(interval[0]);
    const end = convertTimeToNumber(interval[1]);

    if (start < end) {
      good = true;
    }
  }

  return good;
};

export const addInterval = (INTERVALS: any, interval: any) => {
  if (!isGoodInterval(interval)) {
    console.log("This is not an interval");
    return;
  }

  if (!isOverlapping(INTERVALS, interval)) {
    INTERVALS.push(interval);

    // you may also want to keep those intervals sorted
    const sortedIntervals = sortIntervals(INTERVALS);
    console.log("Sorted intervals", sortedIntervals);
  }
};

//              test

// const INTERVALS = [
//     ["14:00", "15:00"],
//     ["08:00", "12:30"],
//     ["12:35", "12:36"],
//     ["13:35", "13:50"],
//   ];

//   // --------------------------------------
//   const goodIntervals = [
//     ["05:31", "06:32"],
//     ["16:00", "17:00"],
//     ["12:31", "12:34"],
//   ];

//   let goodCount = 0;
//   for (const goodInterval of goodIntervals) {
//     if (!isOverlapping(INTERVALS, goodInterval)) {
//       goodCount += 1;
//     }
//   }

//   console.log("Check good intervals: ", goodCount === goodIntervals.length);

//   // --------------------------------------
//   const ovelappingIntervals = [
//     ["09:30", "12:40"],
//     ["05:36", "08:50"],
//     ["13:36", "13:37"],
//     ["06:00", "20:00"],
//     ["14:00", "15:00"],
//   ];

//   let badCount = 0;
//   for (const badInterval of ovelappingIntervals) {
//     if (isOverlapping(INTERVALS, badInterval)) {
//       badCount += 1;
//     }
//   }

//   console.log("Check bad intervals: ", badCount === ovelappingIntervals.length);

//   // --------------------------------------
//   addInterval(INTERVALS, goodIntervals[0]);
//   console.log("INTERVALS: ", INTERVALS);
