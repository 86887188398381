import { gql } from "@apollo/client";

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    me
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($challenge: String!) {
    signin(challenge: $challenge)
  }
`;

export const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    logout
  }
`;
