import { EntityDataType } from "../../../types/Entity";
import { ModalModeType } from "../types";

interface GetEntityStateProps {
  mode: ModalModeType;
  dataTypeEditable: EntityDataType;
  entityData: any;
}

export const getEntityState = ({ mode, dataTypeEditable, entityData }: GetEntityStateProps) => {
  let state: any = {};

  dataTypeEditable.forEach((entityColumn) => {
    if (entityColumn.renderAs === "categories-select") {
      state["domain"] = mode === "create" ? "" : { id: entityData["domain"], name: "" };
      state["specification"] = mode === "create" ? "" : { id: entityData["specification"], name: "" };
      state["topic"] = mode === "create" ? "" : { id: entityData["topic"], name: "" };
    } else if (entityColumn.type === "string" || entityColumn.type === "date") {
      state[entityColumn.key] = mode === "create" ? "" : entityData[entityColumn.key];
    }
  });

  if (mode === "update") {
    state["id"] = entityData.id;
  }

  return state;
};
