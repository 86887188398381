// Modules
import React from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { AnimatePresence, motion } from "framer-motion";

// Components
import Icon from "../Icon";
import Fade from "../Animations/Fade";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

// Constants
const debug = false;


interface DrawerProps {
  children: any;
  activeCond: boolean;
  onClose: any;
  Header?: JSX.Element | string;
  Footer?: JSX.Element | string;
}

const enterVarient = {
  transform: "translateX(0%) translateY(0px) translateZ(0px)",
  transition: { duration: 0.2, ease: [0, 0, 0.2, 1] },
};
const exitVarient = {
  transform: "translateX(100%) translateY(0px) translateZ(0px)",
  transition: { duration: 0.1, ease: [0.4, 0, 1, 1] },
};

type TExtendedProps = DrawerProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;


const Drawer: React.FC<TExtendedProps> = ({ children, Header, Footer, onClose, activeCond, ...props }) => {
  const [removeDrawerFromVDOM, setRemoveFromVDOM] = React.useState(!activeCond);
  const [closeIsTriggered, setCloseIsTriggered] = React.useState("0");

  const drawer = React.useRef(null);

  React.useEffect(() => {
    if (removeDrawerFromVDOM && closeIsTriggered === "0" && activeCond) {
      disableBodyScroll(document.body);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [removeDrawerFromVDOM, closeIsTriggered, activeCond]);

  React.useEffect(() => {
    if (closeIsTriggered === "clicked-to-close") {
      debug && console.log("closeIsTriggered", closeIsTriggered);
      setTimeout(() => {
        debug && console.log("close drawer");
        onClose();
        setTimeout(() => {
          debug && console.log("remove drawer");
          setRemoveFromVDOM(false);
          setTimeout(() => {
            debug && console.log("cleanup");
            setCloseIsTriggered("0");
            setRemoveFromVDOM(true);
          }, 100);
        }, 200);
      }, 100);
    } else {
    }
  }, [closeIsTriggered, onClose]);

  return removeDrawerFromVDOM ? (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <AnimatePresence>
        {activeCond && (
          <div ref={drawer} className={`drawer ${props.className ? props.className : ""}`}>
            <>
              <motion.div className="drawer-content" initial={exitVarient} animate={enterVarient} exit={exitVarient}>
                <button className="drawer-close-btn" onClick={() => setCloseIsTriggered("clicked-to-close")}>
                  <Icon name="close" />
                </button>
                {Header && <header className="drawer-header">{Header}</header>}
                <article className="drawer-body">{children}</article>
                {Footer && <footer className="drawer-footer">{Footer}</footer>}
              </motion.div>
              <Fade activeCond={closeIsTriggered === "0"}>
                <div onClick={() => setCloseIsTriggered("clicked-to-close")} className="drawer-overlay" />
              </Fade>
            </>
          </div>
        )}
      </AnimatePresence>
    </>
  ) : null;
};

export default Drawer;
