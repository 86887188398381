import React from "react";

import { useMutation, useQuery } from "@apollo/client";
import { decryptObject, encryptObject } from "../../../../lib/crypt";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterType } from "../../../Base/Pagination/Filter/FilterType";
import DisplayError from "../../../../Components/Error/ErrorComponent";
import Button from "../../../../Components/Button/Button";
import NoContentIllustration from "../../../../Components/NoContentIllustration";
import Media from "./Media/Media";
import Icon from "../../../../Components/Icon";
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import FilterComponent from "../../../../Components/Filter";
import { media_filters } from "./filters";
import { onFilter } from "./functions/onFilter";
import { useMediaQueryMetadata } from "./hooks/useMediaQueryMetadata";
import MediaModal from "../MediaModal/MediaModal";
import { DELETE_MEDIA, MEDIAS } from "../../../../apollo/graphql/media";
import { onDeleteMedia } from "../MediaModal/functions/onDeleteMedia";
import Pagination from "../../../../Components/Pagination/Pagination";

let defaultChallenge = encryptObject({
  page: 1,
  limit: 10,
  full: true,
});

const MediasList: React.FC = () => {
  const navigateTo = useNavigate();
  const location = useLocation();

  const { challenge, defaultSearch, cpyDefaultSearch } = useMediaQueryMetadata();

  const [filters, setFilter] = React.useState<FilterType[] | null>(defaultSearch);

  const [currentToDelete, setCurrentMediaToDelete] = React.useState<{
    id: string;
    title: string;
  } | null>(null);

  const [openAddNewModal, setOpenAddNewModal] = React.useState("");
  const [mediaToEdit, setMediaToEdit] = React.useState(null);
  const mode = mediaToEdit !== null && mediaToEdit !== undefined ? "update" : "create";

  // mutations / queries >>------------------------------------------------------------------

  const {
    data,
    error: errorList,
    loading: loadingList,
  } = useQuery(MEDIAS, {
    variables: { challenge: challenge ? challenge : defaultChallenge },
    fetchPolicy: "no-cache",
  });

  const [deleteMedia, { loading: loadingDelete, error: errorDelete }] = useMutation(DELETE_MEDIA, {
    refetchQueries: ["MEDIAS"],
  });

  const loading = loadingList || loadingDelete;
  const error = errorList || errorDelete;

  // mutations / queries >>------------------------------------------------------------------

  React.useEffect(() => {
    return () => {
      media_filters.forEach((filter) => {
        const _single_filter: FilterType = {
          key: filter.key,
          name: filter.name ? filter.name : filter.key,
          value: null,
          searchType: filter.searchType,
          type: filter.type,
        };
        defaultSearch.push(_single_filter);
      });
      setFilter([...cpyDefaultSearch]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (data && data.medias && decryptObject(data.medias).filter) {
      setFilter(decryptObject(data.medias).filter);
    }
  }, [data]);

  React.useEffect(() => {
    // onDeleteMedia(loading, deleteMedia, media.id, onClose, mode)
    if (currentToDelete) {
      onDeleteMedia(loading, deleteMedia, currentToDelete.id, () => setCurrentMediaToDelete(null), "delete");
    }

    // return () => {
    //   second
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToDelete]);

  React.useEffect(() => {
    // Detect wether or not create on params
    if (location.search) {
      const params = new URLSearchParams(location.search);

      if (params.has("action")) {
        const __action = params.get("action");

        if (__action === "create" && params.has("parentId")) {
          const __parentId = params.get("parentId");
          console.log(__action, __parentId);

          setTimeout(() => {
            setOpenAddNewModal(`${__parentId}`);
          }, 200);
        }
      }
    }
  }, [location.search]);

  // useEffects <<------------------------------------------------------------------

  if (loading) {
    return <LoadingComponent loadingUniqueReference="206318eb-2db1-4cea-8216-c7a8f8fa48b5" message="medias list" />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="7b027f52-d008-48cc-ba05-372312ffe578" error={error} />;
  }

  if (data && data.medias) {
    const medias = decryptObject(data.medias).medias;
    const pages = decryptObject(data.medias).pages;
    // console.log("data=>", decryptObject(data.medias));

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => setOpenAddNewModal("btn-trigger-create")}
            style={{
              paddingRight: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "16px",
              }}
            >
              <Icon name="add" />{" "}
              <span
                style={{
                  paddingLeft: "8px",
                }}
              >
                CREATE
              </span>
            </div>
          </Button>
        </div>
        <FilterComponent
          filters={filters}
          onFilter={(localFilters: any, clear?: boolean) =>
            onFilter(localFilters, clear, challenge, defaultChallenge, navigateTo)
          }
          defaultSearch={defaultSearch}
          rowHeight={40}
          dropdownTop="292px"
          filterModalInputTop="15vh"
          filterModalInputLeft="15vw"
        />
        {(!medias || !pages) && <NoContentIllustration hint="Try to clear filter" />}
        {medias && pages > 0 && (
          <>
            {medias.map((media: any, i: any) => (
              <Media
                key={i}
                index={i}
                onUpdateMedia={() => setMediaToEdit(media)}
                media={media}
                onDeleteMedia={() => setCurrentMediaToDelete(media)}
              />
            ))}
          </>
        )}
        {pages > 0 && <Pagination entity="medias" pages={pages} />}
        <MediaModal
          mode={mode}
          mediaToEdit={mediaToEdit}
          setMediaToEdit={setMediaToEdit}
          openAddNewModal={openAddNewModal}
          isOpen={mode === "update" || openAddNewModal !== "" ? true : false}
          onClose={() => {
            setOpenAddNewModal("");
            navigateTo("/medias");
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default MediasList;
