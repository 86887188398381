// Modules
import React from "react";

// Functions
import { decryptObject } from "../../lib/crypt";
import { isUUID } from "../../lib/isUUID";
import { errors } from "./errors";

// Components
import ErrorMessage from "./Message";
import ErrorModal from "./Modal";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

// Constants
const activateErrorsReferences = process.env.REACT_APP_ACTIVATE_ERRORS_REFERENCES === "true";

export interface DisplayErrorProps {
  errorUniqueReference: string;
  error?: any;
  layout?: "message" | "modal";
  type?: string;
  children?: any;
  buttonText?: string;
  onClick?: () => any;
}

const DisplayError: React.FC<DisplayErrorProps> = ({
  errorUniqueReference,
  error,
  type = "default",
  layout = "message",
  children,
  buttonText,
  onClick,
}) => {
  if (!children && (!error || !error.message)) return null;

  const Error = layout === "message" ? ErrorMessage : ErrorModal;

  let renderedComponent: JSX.Element = <></>;

  let renderButton = buttonText && onClick ? <button onClick={() => onClick()}>{buttonText}</button> : null;

  if (error && error.networkError && error.networkError.result && error.networkError.result.errors.length) {
    renderedComponent = (
      <>
        {error.networkError.result.errors.map((error: any, i: number) => (
          <Error errorUniqueReference={errorUniqueReference} key={i}>
            {activateErrorsReferences && <h3>#{errorUniqueReference}</h3>}
            <strong>*ERC-0*</strong>: <span>no internet connection</span>
            {error.message.replace("GraphQL error: ", "")}
            {renderButton}
          </Error>
        ))}
      </>
    );
  }

  if (error && error.message.includes("Network error: Failed to fetch")) {
    renderedComponent = <div>{errors["68cde10d-7957-4477-b0b1-2ca747bc2426"]}</div>;
  } else if (type === "login-errors") {
    const jsonObjError = JSON.parse(decryptObject(error.message));
    const { x_rate_limit_limit, x_rate_limit_remaining } = jsonObjError;
    renderedComponent = (
      <Error errorUniqueReference={errorUniqueReference}>
        <div>
          <b>{activateErrorsReferences && <h3>#{errorUniqueReference}</h3>}</b> wrong email or password the rate limit
          is&nbsp;
          <b>{x_rate_limit_limit}</b>, your rate limit remaining is&nbsp;
          <b>{x_rate_limit_remaining}</b>
        </div>
        {renderButton}
      </Error>
    );
  } else if (error && error.message && isUUID(error.message)) {
    const uuid = error.message;
    renderedComponent = (
      <Error errorUniqueReference={errorUniqueReference}>
        {activateErrorsReferences && <h3>#{errorUniqueReference}</h3>}
        {`${errors[uuid]}`}
        {renderButton}
      </Error>
    );
  } else if (error && error.message) {
    renderedComponent = (
      <Error errorUniqueReference={errorUniqueReference}>
        {activateErrorsReferences && <h3>#{errorUniqueReference}</h3>}
        {error.message.includes("Failed to fetch")
          ? errors["68cde10d-7957-4477-b0b1-2ca747bc2426"]
          : error.message}{" "}
        {renderButton}
      </Error>
    );
  } else {
    renderedComponent = (
      <>
        {children ? (
          <Error errorUniqueReference={errorUniqueReference}>
            {activateErrorsReferences && <h3>#{errorUniqueReference}</h3>}
            {children}
            {renderButton}
          </Error>
        ) : (
          <Error errorUniqueReference={errorUniqueReference}>
            {activateErrorsReferences && <h3>#{errorUniqueReference}</h3>} server currently under maintenance please try
            again later {renderButton}
          </Error>
        )}
      </>
    );
  }

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      {renderedComponent}
    </>
  );
};

export default DisplayError;
