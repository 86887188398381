import React from "react";
import { Outlet } from "react-router-dom";
import { settingsPageRoutesMetadata } from "../../App/Routes/Settings/settingsMetadata";
import TabComponent from "../../Components/Tab/Tab";

const SettingsPage: React.FC = () => {

  const { children, absolutePath } = settingsPageRoutesMetadata[0];

  return (
    <div>
      <div className="h3 title">Settings</div>
      {children && <TabComponent absolutePath={absolutePath} chlids={children} />}
      <Outlet />
    </div>
  );
};

export default SettingsPage;
