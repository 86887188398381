import { successToast } from "../constant/customToast";

type UseCustomToastOptions = { copy: string };

const useCustomToast = () => {
  const toastImplementation = async ({ copy }: UseCustomToastOptions) => {
    await navigator.clipboard.writeText(copy!);

    return successToast(`This text was copied: ${copy} `);
  };

  return toastImplementation;
};

export default useCustomToast;
