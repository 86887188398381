// Modules
import ReactDOM from "react-dom/client";

// Components
import AppController from "./App/Controller/AppController";
import { LoadingProvider } from "./App/Controller/Loader/LoadingProvider";

// Functions
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <LoadingProvider>
    <AppController />
  </LoadingProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
