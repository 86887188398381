import { useLocation } from "react-router-dom";
import { decryptObject } from "../../../../lib/crypt";
import { FilterType } from "../Filter/FilterType";

export const usePaginationMetadata = (entityFilters: any) => {
  const location = useLocation();

  let defaultSearch: any = [];
  let cpyDefaultSearch: any = [];

  entityFilters.forEach((filter: any) => {
    const _single_filter: FilterType = {
      key: filter.key,
      name: filter.name ? filter.name : filter.key,
      value: null,
      searchType: filter.searchType,
      type: filter.type,
    };
    defaultSearch.push(_single_filter);
    cpyDefaultSearch.push(_single_filter);
  });

  let challenge: string | null = null;

  if (location.search) {
    const params = new URLSearchParams(location.search);
    if (params.has("q")) {
      // challenge = decryptObject(params.get("q"));
      challenge = params.get("q");
      const raw_challenge = decryptObject(params.get("q"));
      // console.log("raw_challenge=>", raw_challenge);

      if (raw_challenge && raw_challenge.filter) {
        // console.log("raw_challenge.filter=>", raw_challenge.filter);

        defaultSearch = [...raw_challenge.filter];
      }
    }
  }

  return { challenge, defaultSearch, cpyDefaultSearch };
};
