import { gql } from "@apollo/client";

export const QUERY_TASK_MANAGER_SETTINGS = gql`
  query QUERY_TASK_MANAGER_SETTINGS($challenge: String = "") {
    queryTaskManagerSettings(challenge: $challenge)
  }
`;

export const UPDATE_TASK_MANAGER_SETTINGS = gql`
  mutation UPDATE_TASK_MANAGER_SETTINGS($challenge: String!) {
    updateTaskManagerSettings(challenge: $challenge)
  }
`;
