import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useContext } from "react";
import { UPDATE_TASK_MANAGER_SETTINGS } from "../../../../apollo/graphql/task_manager";
import Button from "../../../../Components/Button/Button";
import DisplayError from "../../../../Components/Error/ErrorComponent";
import Icon from "../../../../Components/Icon";
import { encryptObject } from "../../../../lib/crypt";
import { ITaskManagerContext, TaskManagerContext } from "../../context";
import { scrollIntoNearest } from "../../utils";

export const onChangeSelected = (
  action: any,
  selected: any,
  timeZoom: any,
  nearest: any,
  updateTaskManagerSettingsMutation: any,
  server: any
) => {
  let newSelected = "";

  switch (action) {
    case "up":
      newSelected = moment(selected, "HH:mm").add(timeZoom, "minutes").format("HH:mm");
      break;

    case "down":
      newSelected = moment(selected, "HH:mm").subtract(timeZoom, "minutes").format("HH:mm");
      break;

    default:
      newSelected = nearest;
      break;
  }

  updateTaskManagerSettingsMutation({
    variables: {
      challenge: encryptObject({
        settings: {
          ...server,
          selected: newSelected,
        },
      }),
    },
  });
};

const HorizontalNavigator: React.FC = () => {
  const [updateTaskManagerSettingsMutation, { loading, error }] = useMutation(UPDATE_TASK_MANAGER_SETTINGS, {
    refetchQueries: ["QUERY_TASK_MANAGER_SETTINGS"],
  });

  const { taskManagerStore } = useContext<ITaskManagerContext>(TaskManagerContext);

  const {
    client: { nearest, timeNow },
    server,
  } = taskManagerStore;

  const { timeZoom, selected } = server;

  if (error) {
    return <DisplayError layout="modal" errorUniqueReference="bd0ec05e-b512-4b23-b830-c84232874847" error={error} />;
  }

  return (
    <div className="horizontal-navigator">
      <h3>Navigation shortcuts</h3>
      <div className="group-control">
        <p>
          Time now: <b className="time-now">{timeNow}</b>
        </p>
        <hr />
        <p>
          <Button size="sm" onClick={() => scrollIntoNearest(nearest)}>
            ALT + 0
          </Button>
          &nbsp; scroll to current time
        </p>
      </div>

      <div className="group-control">
        <p>
          Selected time: <b className="time-now">{selected}</b>
        </p>
        <hr />
        <p>
          <Button
            size="sm"
            onClick={() =>
              !loading && onChangeSelected("up", selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server)
            }
          >
            ALT +&nbsp; <Icon name="arrow-top" />
          </Button>
          &nbsp; Select up
        </p>
        <p>
          <Button
            size="sm"
            onClick={() => {
              if (!loading) {
                onChangeSelected("", selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server);
              }
            }}
          >
            ALT + *
          </Button>
          &nbsp; select nearest
        </p>
        <p>
          <Button size="sm" onClick={() => scrollIntoNearest(selected)}>
            ALT + .
          </Button>
          &nbsp; scroll to selected
        </p>
        <p>
          <Button
            size="sm"
            onClick={() =>
              !loading &&
              onChangeSelected("down", selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server)
            }
          >
            ALT +&nbsp; <Icon name="arrow-bottom" />
          </Button>
          &nbsp; Select down
        </p>
      </div>
    </div>
  );
};

export default HorizontalNavigator;
