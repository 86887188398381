import React from "react";
import Select from "../../../../../../Components/Select/Select";
import { getTables } from "../../../../../Preferences/BackupData/getTables";

interface EntitySelectEditorProps {
  xKey: string;
  name: string;
  get: string;
  set: any;
  required: boolean;
}

const EntitySelectEditor: React.FC<EntitySelectEditorProps> = ({ xKey, name, get, set, required }) => {
  const tables = getTables();

  return (
    <div
      style={{
        width: "240px",
      }}
      className="form-group panel"
    >
      <label htmlFor={`${xKey.toString()}`}>{`${name.toString()}`}:</label>
      <div className="flex-block">
        <Select
          id={`${xKey.toString()}`}
          value={get}
          onChange={(e) => set(`${e.target.value.toString()}`)}
          required={required}
        >
          <option value="">Select a {`${name.toString()}`}</option>
          {tables.map((table: string) => (
            <option key={table} value={table}>
              {table}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default EntitySelectEditor;
