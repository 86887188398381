// Modules
import React from "react";
import useDarkMode from "use-dark-mode";

// Functions
import { capitalizeFirstLetter } from "../../../lib/string";

//Components
import DropdownMenuItem from "./Item/Item";

// Types
import { FilterType } from "../../../pages/Base/Pagination/Filter/FilterType";

interface FilterDropdownMenuProps {
  dropdownTop: string;
  filters: FilterType[] | null;
  setIsFilterMenuClosed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFilterMenuInputClosed: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFilterKey: React.Dispatch<React.SetStateAction<string | null>>;
}

const FilterDropdownMenu: React.FC<FilterDropdownMenuProps> = ({
  dropdownTop,
  filters,
  setIsFilterMenuClosed,
  setIsFilterMenuInputClosed,
  setSelectedFilterKey,
}) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === true;

  return (
    <div
      style={{
        display: "flex",
        outline: "none",
        minWidth: "128px",
        borderRadius: "4px",
        boxShadow: "0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%)",
        maxHeight: "240px",
        top: dropdownTop,
        position: "fixed",
        background: `${isDarkMode ? "#222" : "white"}`,
        zIndex: "200",
      }}
    >
      {filters && (
        <div
          style={{
            display: "flex",
            overflowY: "scroll",
            width: "100%",
            zIndex: "201",
            flexDirection: "column",
          }}
        >
          {filters
            .filter((el) => el.value === null)
            .map((el, index) => (
              <div
                style={{
                  display: "flex",
                  flex: "1",
                }}
                key={index}
                onClick={() => {
                  setIsFilterMenuClosed(true);
                  setIsFilterMenuInputClosed(false);
                  setSelectedFilterKey(el.key + "");
                }}
              >
                <DropdownMenuItem>{capitalizeFirstLetter(el.name)}</DropdownMenuItem>
              </div>
            ))}
        </div>
      )}
      <div
        style={{
          zIndex: "200",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
        onClick={() => setIsFilterMenuClosed(true)}
        onWheel={() => setIsFilterMenuClosed(true)}
      ></div>
    </div>
  );
};

export default FilterDropdownMenu;
