import { EntityDataType } from "../Base/types/Entity";

export const knowledgeDataType: EntityDataType = [
  {
    key: "id",
    name: "ID",
    unique: true,
    canEdit: false,
    side: "right",
    renderAs: "copy-id-box",
    searchType: "equal",
    type: "string",
    required: false,
  },
  {
    key: "title",
    name: "Title",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "title-name-tags-group",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "name",
    name: "Name",
    unique: true,
    canEdit: true,
    side: "left",
    renderAs: "title-name-tags-group",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "tags",
    name: "Tags",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "title-name-tags-group",
    searchType: "contains",
    type: "string",
    required: true,
  },
  {
    key: "parentId",
    name: "Parent ID",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "knowledge-parent-id-search-box",
    searchType: "equal",
    type: "string",
    required: false,
  },
  {
    key: "type",
    name: "Type Knowledge",
    unique: false,
    canEdit: true,
    side: "right",
    renderAs: "type-knowledge-select",
    searchType: "equal",
    type: "string",
    required: true,
  },
  {
    key: "description",
    name: "Description",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "md-editor",
    searchType: "contains",
    type: "string",
    required: true,
  },

  {
    key: "childrensIds",
    name: "Childrens IDs",
    unique: false,
    canEdit: true,
    side: "left",
    renderAs: "knowledge-childrens-ids-multi-search-box",
    searchType: "equal",
    type: "string",
    required: false,
  },
  {
    key: "createdAt",
    name: "Created At",
    unique: false,
    canEdit: false,
    required: false,
    side: "right",
    renderAs: "datetime",
    searchType: "equal",
    type: "date",
  },
  {
    key: "updatedAt",
    name: "Updated At",
    unique: false,
    canEdit: false,
    required: false,
    side: "right",
    renderAs: "datetime",
    searchType: "equal",
    type: "date",
  },
];
