import React from "react";
import Card from "../../../Components/Card/Card";
import SecretSettingInput from "../../../Components/Form/Input/SettingInput/Secret/SecretSettingInput";

const ImgBBSettingsPage: React.FC = () => {
  return (
    <Card title="ImgBB Settings">
      <h2 className="subtitle">ImgBB API</h2>

      <SecretSettingInput readSettingOnly={false} name="Access token" xKey="imgbb_api__access_token" />
    </Card>
  );
};

export default ImgBBSettingsPage;
