// Modules
import React from "react";
import useDarkMode from "use-dark-mode";

//Components
import Icon from "../../Icon";
import Button from "../../Button/Button";

// Types
import { FilterType } from "../../../pages/Base/Pagination/Filter/FilterType";
import FilterModalInputTypeString from "./Types/String";
import FilterModalInputTypeNumber from "./Types/Number";
import FilterModalInputTypeDate from "./Types/Date";
import FilterModalInputTypeSelect from "./Types/Select";

// Functions
import { getDisabledState } from "../utils/getDisabledState";
import { getAttrOfItemBykey } from "../../../lib/object";
import { capitalizeFirstLetter } from "../../../lib/string";

interface FilterModalInputProps {
  filterModalInputTop: string;
  filterModalInputLeft: string;
  selectedFilterKey: string;
  selectedFilterName: string;
  setLocalFilters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  setIsFilterMenuInputClosed: React.Dispatch<React.SetStateAction<boolean>>;
  filters: FilterType[] | null;
  localFilters: FilterType[] | null;
  onFilter: (localFilters: any, clear?: boolean | undefined) => void;
}

const FilterModalInput: React.FC<FilterModalInputProps> = ({
  filterModalInputTop,
  filterModalInputLeft,
  selectedFilterKey,
  selectedFilterName,
  setLocalFilters,
  setIsFilterMenuInputClosed,
  filters,
  localFilters,
  onFilter,
}) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value;

  const current_type = getAttrOfItemBykey(selectedFilterKey, localFilters, "type");

  return (
    <div
      style={{
        display: "flex",
        outline: "none",
        minWidth: "256px",
        borderRadius: "4px",
        boxShadow: "0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%)",
        top: filterModalInputTop,
        left: filterModalInputLeft,
        position: "fixed",
        background: `${isDarkMode ? "#222" : "#DDD"}`,
        zIndex: "202",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "8px",
          background: `${isDarkMode ? "var(--wa-200)" : "var(--wa-900)"}`,
          flex: "1",
          zIndex: "203",
          flexDirection: "column",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: "1",
            color: `${isDarkMode ? "white" : "black"}`,
          }}
        >
          <div style={{ display: "flex", flex: "1", padding: "11px 0 13px 16px" }}>
            {capitalizeFirstLetter(selectedFilterName)}
          </div>
          <span
            onClick={() => {
              setLocalFilters(filters);
              setIsFilterMenuInputClosed(true);
            }}
            title="Close"
          >
            <Icon
              style={{
                marginBlock: "16px",
                cursor: "pointer",
                color: `${isDarkMode ? "black" : "white"}`,
                // _hover: { color: isDarkMode ? "neutral.50" : "white" },
              }}
              name="close"
            />
          </span>
        </div>

        {["select"].includes(current_type) && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingTop: "14px",
                paddingBlock: "16px",
              }}
            >
              <FilterModalInputTypeSelect
                selectedFilterKey={selectedFilterKey}
                localFilters={localFilters}
                setLocalFilters={setLocalFilters}
                onFilter={onFilter}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: "8px" }}>
              <Button
                style={{
                  padding: "8px",
                }}
                disabled={getDisabledState(selectedFilterKey, localFilters, filters)}
                onClick={() => {
                  onFilter(localFilters);
                }}
              >
                APPLY
              </Button>
            </div>
          </>
        )}

        {["date"].includes(current_type) && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingTop: "14px",
                paddingBlock: "16px",
              }}
            >
              <FilterModalInputTypeDate
                selectedFilterKey={selectedFilterKey}
                localFilters={localFilters}
                setLocalFilters={setLocalFilters}
                onFilter={onFilter}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: "8px" }}>
              {getAttrOfItemBykey(selectedFilterKey, localFilters, "searchType") !== "default" && (
                <Button
                  style={{
                    padding: "8px",
                  }}
                  disabled={getDisabledState(selectedFilterKey, localFilters)}
                  onClick={() => {
                    onFilter(localFilters);
                  }}
                >
                  APPLY
                </Button>
              )}
            </div>
          </>
        )}

        {["string", "number"].includes(current_type) && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingTop: "14px",
                paddingBlock: "16px",
              }}
            >
              {current_type === "string" && (
                <FilterModalInputTypeString
                  selectedFilterKey={selectedFilterKey}
                  localFilters={localFilters}
                  setLocalFilters={setLocalFilters}
                  onFilter={onFilter}
                />
              )}
              {current_type === "number" && (
                <FilterModalInputTypeNumber
                  selectedFilterKey={selectedFilterKey}
                  localFilters={localFilters}
                  setLocalFilters={setLocalFilters}
                  onFilter={onFilter}
                />
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: "8px" }}>
              <Button
                disabled={getDisabledState(selectedFilterKey, localFilters)}
                onClick={() => {
                  onFilter(localFilters);
                }}
              >
                APPLY
              </Button>
            </div>
          </>
        )}
      </div>
      <div
        style={{
          background: "rgba(0, 0, 0, .85)",
          zIndex: "202",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
        onClick={() => {
          setLocalFilters(filters);
          setIsFilterMenuInputClosed(true);
        }}
        onWheel={() => {
          setLocalFilters(filters);
          setIsFilterMenuInputClosed(true);
        }}
      ></div>
    </div>
  );
};

export default FilterModalInput;
