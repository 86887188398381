import { decryptObject, encryptObject } from "../../../../../lib/crypt";
import { onError, successToast } from "../../../../../constant/customToast";

export const onDeleteMedia = async (loading: any, deleteMedia: any, id: any, onClose: any, mode: any) => {
  if (!loading && deleteMedia !== null) {
    const { data } = await deleteMedia({
      variables: {
        challenge: encryptObject({ id }),
      },
    });

    if (!data) {
      onError(`something went wrong on mutate !data`);
    }

    const { msg } = decryptObject(data[`${mode}Media`]);

    if (!msg) {
      onError(`something went wrong on mutate ${id}`);
    }

    if (msg === `${mode}d`) {
      successToast(`weve ${mode}d the media ${id} for you`);
      onClose();
    }
  }
};
