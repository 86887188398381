// Modules
import React from "react";

// Loader
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";

// Functions
import { decodeHTML } from "../../../lib/decodeHTML";

// Types

// Components
import DisplayError from "../../Error/ErrorComponent";
import Select from "../../Select/Select";
import { emptyCategoryContent, emptyCategoryContentType } from "../Categories";

// Hooks
import { useQueryDomains } from "./useQueryDomains";

interface DomainsProps {
  currentDomain: emptyCategoryContentType;
  setCurrentDomain: React.Dispatch<React.SetStateAction<any>>;
}

const Domains: React.FC<DomainsProps> = ({ currentDomain, setCurrentDomain }) => {
  const { error, loading, data } = useQueryDomains();

  React.useEffect(() => {
    return () => {
      setCurrentDomain(emptyCategoryContent);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    <LoadingComponent loadingUniqueReference="89681fd8-0ab5-4b34-a093-3a042e5ac29b" message={`domains from server`} />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="f1050454-ca1a-4972-a5b2-51e7f7ade6fc" error={error} />;
  }

  const isDomainsOkay = data && data.length > 0;

  return (
    <div
      style={{
        width: `${100 / 3}%`,
      }}
      className="form-group"
    >
      <label style={{ marginBottom: "4px" }} htmlFor="domain">
        Domain:
      </label>
      <Select
        id="domain"
        value={currentDomain && currentDomain.id ? currentDomain.id : ""}
        onChange={(e) => {
          const id = e.target.value;
          const name: any = document.querySelector(`#domain option[value = '${id}']`)?.getAttribute("data-name") || "";
          setCurrentDomain({
            id,
            name,
          });
        }}
      >
        <option value="">Select a domain</option>
        {isDomainsOkay ? (
          data.map((domain) => (
            <option key={domain.id} data-name={domain.name} value={domain.id}>
              {decodeHTML(domain.title)}
            </option>
          ))
        ) : (
          <option disabled={true} value="">
            No domain found
          </option>
        )}
      </Select>
    </div>
  );
};

export default Domains;
