// Modules
import moment from "moment";
import useDarkMode from "use-dark-mode";

// Functions
import { updateObject } from "../../../../lib/object";

// Types
import { FilterType } from "../../../../pages/Base/Pagination/Filter/FilterType";

// Components
import Button from "../../../Button/Button";
import Icon from "../../../Icon";

interface SearchItemProps {
  filter: any;
  setIsFilterMenuInputClosed: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFilterKey: React.Dispatch<React.SetStateAction<string | null>>;
  filters: FilterType[] | null;
  onFilter: (localFilters: any, clear?: boolean | undefined) => void;
}

const formatValue = (value: string, type: string, searchType: string, search_key: string) => {
  switch (type) {
    case "date":
      if (searchType === "default") {
        return value;
      }
      if (["equal", "not_equal", "gt", "gte", "lt", "lte"].includes(searchType)) {
        return moment(value, "YYYYMMDD").format("dddd DD MMM YYYY");
      }
      if (["in", "out"].includes(searchType)) {
        const __interval = JSON.parse(value);
        return `[ ${moment(__interval[0], "YYYYMMDD").format("dddd DD MMM YYYY")} , ${moment(
          __interval[1],
          "YYYYMMDD"
        ).format("dddd DD MMM YYYY")} ]`;
      }
      break;
    case "select":
      switch (search_key) {
        case "status":
          return value;
      }
  }
  return value;
};

const SearchItem: React.FC<SearchItemProps> = ({
  filter,
  setIsFilterMenuInputClosed,
  setSelectedFilterKey,
  filters,
  onFilter,
}) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === true;

  return (
    <div
      style={{
        display: "flex",
        cursor: "pointer",
        borderRadius: "12px",
        background: "var(--wa-100)",
        alignItems: "center",
        paddingLeft: "4px",
        marginBlock: "0px",
      }}
      onClick={() => {
        setIsFilterMenuInputClosed(false);
        setSelectedFilterKey(filter.key + "");
      }}
      // _hover={{ bg: "whiteAlpha.300" }}
    >
      <p
        style={{
          paddingLeft: "6px",
          paddingRight: "16px",
        }}
      >
        {filter.name} {filter.searchType} '{formatValue(filter.value, filter.type, filter.searchType, filter.key)}'
      </p>
      <Button
        className="button-icon"
        colorScheme="$p3"
        style={{
          marginRight: "0",
          width: "24px",
          height: "24px",
        }}
      >
        <Icon
          style={{
            fill: `${isDarkMode ? "var(--n3)" : "var(--n6)"}`,
          }}
          onClick={() => {
            const newUpdate: FilterType = {
              key: filter.key,
              name: filter.name,
              searchType: filter.searchType,
              type: filter.type,
              value: null,
            };

            // console.log("vvv newUpdate", newUpdate);

            const newUpdatedObj = updateObject(newUpdate, filters);

            // console.log("vvv newUpdatedObj", newUpdatedObj);

            onFilter([...newUpdatedObj], true);
          }}
          name="close"
        />
      </Button>
    </div>
  );
};

export default SearchItem;
