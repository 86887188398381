import React from "react";
import BasePage from "../Base/BasePage";
import { getCRLUD } from "../Base/operation/get/crlud";
import { quoteDataType } from "./quotesDataType";

const crlud = getCRLUD("quote", "quotes");

const QuotesPage: React.FC = () => {
  return <BasePage title="Quotes" crlud={crlud} entityDataType={quoteDataType} />;
};

export default QuotesPage;
