import { useQuery } from "@apollo/client";
import React from "react";
import { SETTING } from "../../../../../apollo/graphql/setting";
import { decryptObject, decryptText, encryptObject } from "../../../../../lib/crypt";

export const useQuerySetting = (xKey: string) => {
  const [challenge] = React.useState(encryptObject({ key: xKey }));
  const { data, error, loading } = useQuery(SETTING, {
    variables: { challenge },
  });

  if (loading) return { error: null, loading, data: null };

  if (error) return { error: error.message, loading: false, data: null };

  if (data && data.setting) {
    const value = decryptText(decryptObject(data.setting).setting.value);

    if (decryptObject(data.setting).setting) {
      return { error: null, loading: false, data: { value, xKey } };
    } else {
      return { error: "error 891615", loading: false, data: null };
    }
  } else {
    return { error: "error 7157851", loading: false, data: null };
  }
};
