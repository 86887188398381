// Modules
import React from "react";

// hooks

// Functions
/// Global - utilities
import { getSidebarForceClassName } from "./Context/LayoutUtils";

/// Loader
import LoadingComponent from "../Controller/Loader/LoadingComponent";

// Components
/// Children
import SidebarComponent from "./Sidebar/SidebarComponent";
import HeaderComponent from "./Header/HeaderComponent";
import { useLayout } from "./Context/useLayout";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface LayoutComponentProps {
  children?: any;
  wide?: boolean;
}

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children, wide }) => {
  const { layout } = useLayout();

  const layoutSidebarClassName = getSidebarForceClassName(layout);

  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <>
      <React.Suspense
        fallback={
          <LoadingComponent
            loadingUniqueReference="e019f622-355f-4d99-a49b-182024e81452"
            message="Layout Component styles"
            transparency={1}
          />
        }
      >
        <Styling />
      </React.Suspense>

      <main className={`page-layout ${layoutSidebarClassName}`}>
        <SidebarComponent isVisible={visible} onClose={() => setVisible(false)} />
        <HeaderComponent onOpen={() => setVisible(true)} />
        <div className="inner">
          <div className={`container${wide ? " wide" : ""}`}>{children}</div>
        </div>
      </main>
    </>
  );
};

export default LayoutComponent;
