import { gql } from "@apollo/client";

export const KNOWLEDGES = gql`
  query KNOWLEDGES($challenge: String!) {
    knowledges(challenge: $challenge)
  }
`;

export const SEARCH_KNOWLEDGES = gql`
  query SEARCH_KNOWLEDGES($challenge: String!) {
    searchKnowledges(challenge: $challenge)
  }
`;

export const CREATE_KNOWLEDGE = gql`
  mutation CREATE_KNOWLEDGE($challenge: String!) {
    createKnowledge(challenge: $challenge)
  }
`;

export const KNOWLEDGE = gql`
  query KNOWLEDGE($challenge: String!) {
    knowledge(challenge: $challenge)
  }
`;

export const UPDATE_KNOWLEDGE = gql`
  mutation UPDATE_KNOWLEDGE($challenge: String!) {
    updateKnowledge(challenge: $challenge)
  }
`;

export const DELETE_KNOWLEDGE = gql`
  mutation DELETE_KNOWLEDGE($challenge: String!) {
    deleteKnowledge(challenge: $challenge)
  }
`;

export const UNLINK_CHILDREN = gql`
  mutation UNLINK_CHILDREN($challenge: String!) {
    unlinkChildren(challenge: $challenge)
  }
`;

export const MAP_KNOWLEDGES = gql`
  query MAP_KNOWLEDGES($challenge: String!) {
    mapKnowledges(challenge: $challenge)
  }
`;

export const DOMAINS = gql`
  query DOMAINS($challenge: String!) {
    domains(challenge: $challenge)
  }
`;

export const SPECIFICATIONS = gql`
  query SPECIFICATIONS($challenge: String!) {
    specifications(challenge: $challenge)
  }
`;

export const TOPICS = gql`
  query TOPICS($challenge: String!) {
    topics(challenge: $challenge)
  }
`;
