// Modules
import React from "react";
import useDarkMode from "use-dark-mode";
import OutsideClickHandler from "react-outside-click-handler";

// Constants
import { useMutation } from "@apollo/client";

// Mutations
import { SIGN_OUT_MUTATION } from "../../../../apollo/graphql/user";

// Loader
import LoadingComponent from "../../../Controller/Loader/LoadingComponent";

// Components
import DisplayError from "../../../../Components/Error/ErrorComponent";

const UserMenuComponent: React.FC = () => {
  const [logoutUser, { loading, error }] = useMutation(SIGN_OUT_MUTATION, {
    refetchQueries: ["CURRENT_USER_QUERY"],
  });

  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === true ? "dark" : "light";

  const [visible, setVisible] = React.useState(false);

  if (loading)
    return <LoadingComponent loadingUniqueReference="cb0fdb2f-9b41-4292-84a9-2b3e57795e06" message="Logout" />;

  if (error)
    return <DisplayError errorUniqueReference="cc77ff03-e737-4a1e-bfe5-019e0654b086" error={error} layout="modal" />;

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={`user${visible ? " active" : ""}`}>
        <button className="head" onClick={() => setVisible(!visible)}>
          <img src={`/images/logo/logo_${isDarkMode}.png`} alt="Logo" />
        </button>
        <div className="body">
          <div className="menu">
            <button className="item" onClick={() => logoutUser()}>
              Log out
            </button>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default UserMenuComponent;
