// Modules
import React from "react";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface SpinnerProps {
  size?: string;
  color?: string;
}

type TExtendedProps = SpinnerProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Spinner: React.FC<TExtendedProps> = ({ size = "1.5rem", color = "white", ...props }) => {
  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div
        className="spinner"
        style={{
          color,
          width: size,
          height: size,
        }}
        {...props}
      />
    </>
  );
};

export default Spinner;
