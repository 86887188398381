// Modules
import React from "react";

// Functions
import { decodeHTML } from "../../../../lib/decodeHTML";

// Components
import Button from "../../../../Components/Button/Button";

interface RoundedButtonProps {
  children: JSX.Element | string;
  isSelected?: boolean;
  br?: string;
  description?: string;
  onClick?: any;
  disabled?: boolean;
  onDbClick?: any;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({
  children,
  isSelected = false,
  br,
  onClick,
  disabled,
  onDbClick,
}) => {
  return (
    <div className="button-group">
      <Button
        className={`rounded-button ${disabled ? "disabled rounded-button-disabled" : ""} ${
          isSelected ? "selected" : ""
        }`}
        style={{
          borderRadius: br,
          pointerEvents: "all",
        }}
        onClick={(event) => {
          if (!onClick) return;
          event.stopPropagation();
          // In that case, event.ctrlKey does the trick.
          onClick(event.ctrlKey);
        }}
        onDoubleClick={() => onDbClick && onDbClick()}
      >
        <span>{decodeHTML(children)}</span>
      </Button>
      {/* <Tooltip
        isDisabled={!description ? true : false}
        closeOnClick={false}
        bg="dark.800"
        color="white.300"
        placement="auto-start"
        label={description && <div dangerouslySetInnerHTML={{ __html: decodeHTML(description) }} />}
        hasArrow
        arrowSize={15}
      >
        <IconButton
          cursor="help"
          borderRightRadius={br}
          disabled={!description ? true : false}
          color="whiteAlpha.500"
          aria-label="Add to friends"
          icon={<InfoIcon />}
        />
      </Tooltip> */}
    </div>
  );
};

export default RoundedButton;

// `${
//   description && convertToPlain(description).replace(/<[^>]+>/g, "")
// }`
