import React from "react";
import Icon from "../Icon";

interface TooltipProps {
  className?: string;
  place: string;
  title: string;
  icon: string;
}

type TExtendedProps = TooltipProps &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Tooltip: React.FC<TExtendedProps> = ({
  className,
  place,
  title,
  icon,
  ...props
}) => {
  return (
    <div className={className ?? "tooltip"} {...props}>
      <span data-tip={title} data-place={place}>
        <Icon name={icon} />
      </span>
    </div>
  );
};

export default Tooltip;
