import { routes } from "../../../App/Routes/RoutesComponent";

export const getTables = () => {
  let tables: any = routes.filter((el) => el.table).map((el) => el.table);

  if (tables.filter((el: any) => el === "media")[0] === "media") {
    let newArrayTables = tables.filter((el: any) => el !== "media");
    newArrayTables = [...newArrayTables, "media", "source", "upload", "host"];
    tables = [...newArrayTables];
  }

  if (tables.filter((el: any) => el === "task")[0] === "task") {
    let newArrayTables = tables.filter((el: any) => el !== "task");
    newArrayTables = [...newArrayTables, "task", "task_manager", "user"];
    tables = [...newArrayTables];
  }

  return tables;
};
