// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { mediasPageRoutesWithElementMetadata } from "./mediasMetaData";

const MediasPageRoutes: React.FC = () => {
  return (
    <Routes>
      {mediasPageRoutesWithElementMetadata.map(({ path, Element }, media) => (
        <Route key={media} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default MediasPageRoutes;
