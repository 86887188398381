// Modules
import React from "react";

// Functions
import { getAttrOfItemBykey, getValueOfItemBykey } from "../../../../../lib/object";
import { getDisabledState } from "../../../utils/getDisabledState";
import { updateFilterSearchType, updateFilterValue } from "../utils";

// Types
import { FilterType } from "../../../../../pages/Base/Pagination/Filter/FilterType";

// Components
import Input from "../../../../Form/Input";
import Select from "../../../../Select/Select";

// Constants
import { NUMBER_FILTER_SEARCH_TYPES } from "../../../constants";

interface FilterModalInputTypeNumberProps {
  selectedFilterKey: string;
  localFilters: FilterType[] | null;
  setLocalFilters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  onFilter: (localFilters: any, clear?: boolean | undefined) => void;
}

const FilterModalInputTypeNumber: React.FC<FilterModalInputTypeNumberProps> = ({
  selectedFilterKey,
  localFilters,
  setLocalFilters,
  onFilter,
}) => {
  const currentSearchType = getAttrOfItemBykey(selectedFilterKey, localFilters, "searchType");

  const current_value = getValueOfItemBykey(selectedFilterKey, localFilters) || "";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "8px",
        }}
      >
        <Select
          defaultValue={currentSearchType}
          style={{
            width: "100%",
          }}
          onChange={(e) =>
            e.target.value && updateFilterSearchType(e.target.value, selectedFilterKey, localFilters, setLocalFilters)
          }
        >
          {NUMBER_FILTER_SEARCH_TYPES.map((el) => (
            <option key={`key-${el}`} value={`${el}`}>
              {el}
            </option>
          ))}
        </Select>
      </div>

      {currentSearchType !== "in" && currentSearchType !== "out" && (
        <div
          style={{
            display: "flex",
          }}
        >
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter" && !getDisabledState(selectedFilterKey, localFilters)) {
                onFilter(localFilters);
              }
            }}
            type="text"
            value={current_value}
            onChange={(e) => updateFilterValue(e.target.value, selectedFilterKey, localFilters, setLocalFilters)}
            placeholder={`${getAttrOfItemBykey(selectedFilterKey, localFilters, "name")}..`}
          />
        </div>
      )}

      {(currentSearchType === "in" || currentSearchType === "out") && (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          [
          <div
            style={{
              display: "flex",
              paddingBlock: "16px",
            }}
          >
            <Input
              style={{
                width: "75px",
              }}
              value={(current_value && JSON.parse(current_value))[0] || ""}
              onWheel={() => {
                (document.activeElement as HTMLElement).blur();
              }}
              min="0"
              type="number"
              onChange={(e) => {
                if (e.target.value !== null && +e.target.value >= 0) {
                  const value__ = JSON.stringify([+e.target.value, +JSON.parse(current_value)[1] || ""]);

                  updateFilterValue(value__, selectedFilterKey, localFilters, setLocalFilters);
                }
              }}
              placeholder={`${selectedFilterKey}..`}
            />
          </div>{" "}
          ,{" "}
          <div
            style={{
              display: "flex",
              paddingBlock: "16px",
            }}
          >
            <Input
              style={{
                width: "75px",
              }}
              value={(current_value && JSON.parse(current_value))[1] || ""}
              onWheel={() => {
                (document.activeElement as HTMLElement).blur();
              }}
              min="0"
              type="number"
              onChange={(e) => {
                if (e.target.value !== null && +e.target.value >= 0) {
                  const value__ = JSON.stringify([+JSON.parse(current_value)[0] || "", +e.target.value]);

                  updateFilterValue(value__, selectedFilterKey, localFilters, setLocalFilters);
                }
              }}
              placeholder={`${selectedFilterKey}..`}
            />
          </div>
          ]
        </div>
      )}
    </div>
  );
};

export default FilterModalInputTypeNumber;
