import Swal from "sweetalert2";
import { decryptObject, encryptObject } from "../../../lib/crypt";
import { onDeleteHandler } from "./onDeleteHandler";

export const onUnlinkHandler = async (
  unlinkChildren: any,
  idToUnlink: any,
  parentId: any,
  deleteChildren: any
) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, unlink it!",
  }).then(async (result0) => {
    if (result0.isConfirmed) {
      const { data: unlinkChildrenData } = await unlinkChildren({
        variables: {
          challenge: encryptObject({ childrenId: idToUnlink, parentId }),
        },
      });

      if (unlinkChildrenData && unlinkChildrenData.unlinkChildren) {
        // console.log(unlinkChildrenData.unlinkChildren);

        const respMsg = decryptObject(unlinkChildrenData.unlinkChildren);
        // console.log(respMsg);

        if (!respMsg.msg) {
          Swal.fire({
            icon: "error",
            title: `Something Wrong on unlink ${idToUnlink}`,
            html: `${respMsg.error}`,
          });
        } else if (respMsg.msg) {
          Swal.fire(
            "Unlinked!",
            `${idToUnlink} has been unlinked.`,
            "success"
          ).then(() => {
            Swal.fire({
              title: "Are you sure to delete the children ?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "Keep it orfelinat!",
            }).then((result1) => {
              if (result1.isConfirmed) {
                onDeleteHandler(deleteChildren, idToUnlink);
              } else {
                // console.log("reload");
                
                window.location.reload();
              }
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `Something Wrong on the server`,
            html: `Check the log for more details`,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `Something Wrong`,
          html: `On unlink ${idToUnlink}`,
        });
      }
    } 
  });
};
