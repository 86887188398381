// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { quotesPageRoutesWithElementMetadata } from "./quotesMetaData";

const QuotesPageRoutes: React.FC = () => {
  return (
    <Routes>
      {quotesPageRoutesWithElementMetadata.map(({ path, Element }, quote) => (
        <Route key={quote} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default QuotesPageRoutes;
