// Modules
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

// Components
/// Global
import Icon from "../../../../Components/Icon";

interface DropdownComponentProps {
  item: any;
  visibleSidebar: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({ item, visibleSidebar, setValue, onClose }) => {
  const [visible, setVisible] = React.useState(false);

  const { pathname } = useLocation();

  const handleClick = () => {
    setVisible(!visible);
    setValue(true);
  };

  const Head = () => {
    return (
      <button
        className={`head${pathname.includes(item.slug) ? " active" : ""}${visibleSidebar ? " wide" : ""}`}
        onClick={() => handleClick()}
      >
        <Icon name={item.icon} size="24" />
        {item.title}
        <Icon name="arrow-down" size="24" />
      </button>
    );
  };

  return (
    <div
      className={`dropdown${visible ? " active" : ""}${pathname.includes(item.slug) ? " active" : ""}${
        visibleSidebar ? " wide" : ""
      }`}
    >
      {item.add ? (
        <div className={`top${pathname.startsWith("/products/add") ? " active" : ""}`}>
          <Head />
          <Link
            className={`add${pathname.startsWith("/products/add") ? " active" : ""}`}
            to="/products/add"
            onClick={onClose}
          >
            <Icon name="plus" size="10" />
          </Link>
        </div>
      ) : (
        <Head />
      )}
      <div className="body">
        {item &&
          item.dropdown &&
          item.dropdown.length > 0 &&
          item.dropdown.map((dropdownItem: any, index: any) => (
            <NavLink
              className={({ isActive }) => (isActive ? "link active" : "link")}
              to={dropdownItem.url}
              key={index}
              onClick={onClose}
            >
              {dropdownItem.title}
              {dropdownItem.counter ? (
                <div className="counter" style={{ backgroundColor: dropdownItem.colorCounter }}>
                  {dropdownItem.counter}
                </div>
              ) : (
                <Icon name="arrow-next" size="24" />
              )}
            </NavLink>
          ))}
      </div>
    </div>
  );
};

export default DropdownComponent;
