import React from "react";

import MainPanel from "../MainPanel/MainPanel";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { useScrollYPosition } from "../../../hooks/useScrollPosition";
import ScrollTo from "../Components/ScrollTo";

const TaskManagerPage: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  const y = useScrollYPosition();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      {!isMobile && y >= 128 && (
        <>
          <ScrollTo direction="top" />
        </>
      )}

      <MainPanel />

      {!isMobile && window.document.body.getBoundingClientRect().bottom - window.innerHeight >= 64 && (
        <>
          <ScrollTo direction="bottom" /> <div id="bottom" style={{ height: 0 }} />
        </>
      )}
    </div>
  );
};

export default TaskManagerPage;
