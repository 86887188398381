import React from "react";
import useDarkMode from "use-dark-mode";

interface ImageProps {
  className?: string;
  src: string;
  srcDark: string;
  srcSet?: string;
  srcSetDark?: string;
  alt: string;
}

type TExtendedProps = ImageProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const Image: React.FC<TExtendedProps> = ({ className, src, srcDark, srcSet, srcSetDark, alt, ...props }) => {
  const darkMode = useDarkMode(false);

  return (
    <img
      className={className}
      srcSet={darkMode.value ? srcSetDark : srcSet}
      src={darkMode.value ? srcDark : src}
      alt={alt}
      {...props}
    />
  );
};

export default Image;
