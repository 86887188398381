// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { projectsPageRoutesWithElementMetadata } from "./projectsMetaData";

const ProjectsPageRoutes: React.FC = () => {
  return (
    <Routes>
      {projectsPageRoutesWithElementMetadata.map(({ path, Element }, project) => (
        <Route key={project} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default ProjectsPageRoutes;
