// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import IndexPage from "../../../pages";

export const indexPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Index Page",
    name: "index-page",
    absolutePath: "/",
    path: "",
    Element: IndexPage,
  },
];

export const indexPageRoutesMetadata: RouteType[] = indexPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
