// Modules
import React from "react";

// Component
import Select from "../../../../Select/Select";

const FakeTopics: React.FC = () => {
  return (
    <div className="form-group" style={{ marginBottom: "4px", width: `${100 / 3}%` }}>
      <label style={{ marginBottom: "4px" }} htmlFor="topic">
        Topic:
      </label>
      <Select disabled={true} id="topic" value="">
        <option value="">Select a specificationId first</option>
      </Select>
    </div>
  );
};

export default FakeTopics;
