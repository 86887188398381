// Modules
import React from "react";

// Functions
import { encryptObject } from "../../lib/crypt";

// Components
// Children
import Domains from "./Domains/Domains";
import FakeSpecifications from "./Domains/Specifications/FakeSpecifications";
import Specifications from "./Domains/Specifications/Specifications";
import FakeTopics from "./Domains/Specifications/Topics/FakeTopics";
import Topics from "./Domains/Specifications/Topics/Topics";

// Types
export type emptyCategoryContentType = {
  name: string;
  id: string;
};

export const emptyCategoryContent: emptyCategoryContentType = {
  name: "",
  id: "",
};

interface CategoriesProps {
  setCategory: any;

  domain: emptyCategoryContentType;
  setDomain: (domainArg: emptyCategoryContentType) => void;

  specification: emptyCategoryContentType;
  setSpecification: (specificationArg: emptyCategoryContentType) => void;

  topic: emptyCategoryContentType;
  setTopic: (topicArg: emptyCategoryContentType) => void;
}

const Categories: React.FC<CategoriesProps> = React.memo(
  ({ setCategory, domain, specification, topic, setDomain, setSpecification, setTopic }) => {
    React.useEffect(() => {
      if (domain && specification && topic) {
        if (domain.name === "" && specification.name === "" && topic.name === "") {
          setTimeout(() => {
            const domain_data_name: any = document
              .querySelector(`#domain option[value = '${domain.id}']`)
              ?.getAttribute("data-name");

            const specification_data_name: any = document
              .querySelector(`#specification option[value = '${specification.id}']`)
              ?.getAttribute("data-name");

            const topic_data_name: any = document
              .querySelector(`#topic option[value = '${topic.id}']`)
              ?.getAttribute("data-name");

            if (domain_data_name && specification_data_name && topic_data_name) {
              setCategory({
                domainArg: {
                  id: domain.id,
                  name: domain_data_name,
                },
                specificationArg: {
                  id: specification.id,
                  name: specification_data_name,
                },
                topicArg: {
                  id: topic.id,
                  name: topic_data_name,
                },
              });
            }
          }, 1000);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain, specification, topic]);

    const isDomainValid = domain && domain.id !== "";
    const isSpecificationValid = isDomainValid && specification && specification.id !== "";

    return (
      <div className="flex-block">
        <Domains currentDomain={domain} setCurrentDomain={setDomain} />

        {isDomainValid ? (
          <Specifications
            challenge={encryptObject({
              domainId: domain.id,
            })}
            currentSpecification={specification}
            setCurrentSpecification={setSpecification}
          />
        ) : (
          <FakeSpecifications />
        )}

        {isSpecificationValid ? (
          <Topics
            challenge={
              specification
                ? encryptObject({
                    specificationId: specification.id,
                  })
                : null
            }
            currentTopicId={topic}
            setCurrentTopicId={setTopic}
          />
        ) : (
          <FakeTopics />
        )}
      </div>
    );
  }
);

export default Categories;
