import { decryptObject, encryptObject } from "../../../../../lib/crypt";

export const onFilter = (
  localFilters: any,
  clear: boolean = false,
  challenge: any,
  defaultChallenge: any,
  navigateTo: any
) => {
  // console.log("is ? clear => ", clear);

  // console.log("localFilters=>", localFilters);

  let selected_challenge = challenge ? { ...decryptObject(challenge) } : { ...decryptObject(defaultChallenge) };
  // console.log("selected_challenge=>", selected_challenge);

  if (clear) {
    delete selected_challenge.filters;
  }

  let tmp = {
    ...selected_challenge,
  };

  tmp.filter = [...localFilters];

  // console.log("tmp=>", tmp);

  const final_challenge = {
    ...tmp,
    full: true,
  };

  // console.log("final_challenge=>", final_challenge);

  navigateTo(`/medias?q=${encryptObject({ ...final_challenge })}`);
};
