import React from "react";

interface ErrorMessageProps {
  children: any;
  errorUniqueReference?: string;
  // just to bypass pass ref to modal
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => <div className="error-message">{children}</div>;

export default ErrorMessage;
