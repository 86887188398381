// Functions
import { getAttrOfItemBykey, getValueOfItemBykey } from "../../../lib/object";

// Types
import { FilterType } from "../../../pages/Base/Pagination/Filter/FilterType";

export const getDisabledState = (
  selected_filter_key: string,
  local_filters: FilterType[] | null,
  filters: FilterType[] | null = null
) => {
  if (selected_filter_key) {
    const __checkType = getAttrOfItemBykey(selected_filter_key, local_filters, "type");

    const __checkValue = getAttrOfItemBykey(selected_filter_key, local_filters, "value");

    const __checkSearchType = getAttrOfItemBykey(selected_filter_key, local_filters, "searchType");

    switch (__checkType) {
      case "string":
      case "number":
      case "date":
        if (__checkValue !== null && (__checkSearchType === "in" || __checkSearchType === "out")) {
          if (__checkType === "date" && __checkValue.length <= 8) {
            return true;
          }

          if (
            JSON.parse(__checkValue)[0] !== 0 &&
            JSON.parse(__checkValue)[0] !== "" &&
            JSON.parse(__checkValue)[1] !== 0 &&
            JSON.parse(__checkValue)[1] !== ""
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (typeof __checkValue === "string") {
            if (__checkValue.length > 0) return false;
          } else {
            return true;
          }
        }

        break;

      case "select":
        const currentUrlValue = getValueOfItemBykey(selected_filter_key, filters);

        const currentUrlSearchType = getAttrOfItemBykey(selected_filter_key, filters, "searchType");

        if (currentUrlValue !== __checkValue || currentUrlSearchType !== __checkSearchType) {
          return false;
        } else {
          return true;
        }
    }
    return true;
  } else {
    return true;
  }
};
