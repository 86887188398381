import { createContext } from "react";

interface ITaskManagerClient {
  timeNow: string;
  nearest: string;
}

interface ITaskManagerServer {
  timeZoom: number;
  selected: string;
  currentTimelineDate: string;
}

export interface ITaskManager {
  client: ITaskManagerClient;
  server: ITaskManagerServer;
}

export interface ITaskManagerContext {
  taskManagerStore: ITaskManager;
  setTaskManagerStore: React.Dispatch<React.SetStateAction<ITaskManager>>;
}

export const TaskManagerContext = createContext<ITaskManagerContext>({} as ITaskManagerContext);
