// Modules
import { useQuery } from "@apollo/client";

// Functions
import { decryptObject } from "../../../../lib/crypt";

// Queries
import { SPECIFICATIONS } from "../../../../apollo/graphql/knowledge";

export const useQuerySpecifications = (challenge: any) => {
  const { data, error, loading } = useQuery(SPECIFICATIONS, {
    variables: { challenge },
  });

  if (loading) return { error: null, loading, data: null };

  if (error) return { error: error.message, loading: false, data: null };

  if (data && data.specifications) {
    const specifications = decryptObject(data.specifications).specifications;

    if (decryptObject(data.specifications).specifications) {
      return { error: null, loading: false, data: [...specifications] };
    } else {
      return { error: "error 41534486", loading: false, data: null };
    }
  } else {
    return { error: "error 5646743", loading: false, data: null };
  }
};
