import React from "react";
import MediaInputComponent, { MediaInputlayoutType } from "../MainComponent";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import { encryptObject } from "../../../lib/crypt";
import DisplayError from "../../Error/ErrorComponent";
import { HostContext } from "../context/HostContext";
import { useQueryHosts } from "./hooks/useQueryHosts";

interface PersistHostsProps {
  layout: MediaInputlayoutType;
  relatedTableId: string;
  folder: string;
}

const challenge = encryptObject({
  bpass: true,
});
const PersistHosts: React.FC<PersistHostsProps> = ({ relatedTableId, folder, layout }) => {
  const { error, loading, data } = useQueryHosts(challenge);

  if (loading) {
    <LoadingComponent loadingUniqueReference="2efbbec9-c867-452b-b936-c95dbe3c5f3b" message={`hosts from server`} />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="34d8b6d4-98de-445a-b637-e6bbed4030d2" error={error} />;
  }

  let ReturnJSX: any = null;

  if (data) {
    if (data && data.length > 0) {
      ReturnJSX = (
        <HostContext.Provider value={data}>
          <MediaInputComponent relatedTableId={relatedTableId} folder={folder} layout={layout} />
        </HostContext.Provider>
      );
    } else {
      ReturnJSX = (
        <option disabled={true} value="">
          No hosts found
        </option>
      );
    }
  } else {
    ReturnJSX = null;
  }

  return ReturnJSX;
};

export default React.memo(PersistHosts);
