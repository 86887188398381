// Modules
import { useMutation } from "@apollo/client";

// Mutations
import { UPDATE_SETTING } from "../../../../../apollo/graphql/setting";

// Functions
import { decryptObject, encryptObject } from "../../../../../lib/crypt";


import { onError, successToast } from "../../../../../constant/customToast";

export const useMutationSetting = () => {
  const [updateSettingMutation, { loading, error }] = useMutation(UPDATE_SETTING, {
    refetchQueries: ["SETTING"],
  });

  if (!loading && error) {
    onError(`something went wrong on mutate ${error.message}`);
  }

  const updateSettingHandler = async (key: string, value: string) => {
    if (!loading && !error && updateSettingMutation !== null) {
      const { data } = await updateSettingMutation({
        variables: {
          challenge: encryptObject({ value, key }),
        },
      });
      if (!data) {
        onError(`something went wrong on mutate !data`);
        // console.log("something went wrong 01");
      }
      // console.log(data);

      const { msg } = decryptObject(data["updateSetting"]);
      if (!msg) {
        // console.log("something went wrong 02");
        onError(`something went wrong on mutate ${key} => ${value}`);
      }

      if (msg === "updated") {
        successToast(`weve updated the setting ${key} for you`);
        await setTimeout(() => {
          window.location.href = `/settings`;
        }, 500);
      }
    }
  };

  return updateSettingHandler;
};
