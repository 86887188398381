// Modules
import React from "react";

// Routes
import { renderRoutes } from "../utils/renderRoutes";

// Metadata
import { settingsPageRoutesWithElementMetadata } from "./settingsMetadata";

const SettingsPageRoutes: React.FC = () => {
  return renderRoutes(settingsPageRoutesWithElementMetadata);
};

export default SettingsPageRoutes;
