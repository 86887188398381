/**
 * asyncForEach.
 * @param {array} array Original array.
 * @param {function} callback Async array.
 */
const asyncForEach = async (array: any, callback: any) => {
  try {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  } catch (error) {}
};

export default asyncForEach;
