import React from "react";
import Button from "../../../../../Components/Button/Button";
import Icon from "../../../../../Components/Icon";
import MulipleSelectChildren from "../../../../../Components/MulipleSelectChildren";
import SearchBox from "../../../../../Components/SearchBox/SearchBox";
import { desecureText } from "../../../../../lib/desecureText";

// Types
import { EntityColumnType } from "../../../types/Entity";
import DateInputEditor from "../Components/DateInputEditor";
import EntitySelectEditor from "../Components/EntitySelectEditor";
import MarkdownInputEditor from "../Components/MarkdownInputEditor";
import MediaInputEditor from "../Components/MediaInputEditor";
import StatusSelectEditor from "../Components/StatusSelectEditor";
import TextInputEditor from "../Components/TextInputEditor";
import TypeKnowledgeSelectEditor from "../Components/TypeKnowledgeSelectEditor";
import { ModalModeType } from "../types";

// Components

interface RenderElementEditorProps {
  id: string | undefined;
  mode: ModalModeType;
  getFromGlobalState: any;
  get: any;
  set: any;
  readEntityLowerCaseName: string;
  listEntitiesLowerCaseName: string;
  columnDataType: EntityColumnType;
  columnDataValue: string | undefined;
}

const RenderElementEditor: React.FC<RenderElementEditorProps> = ({
  id,
  mode,
  getFromGlobalState,
  get,
  set,
  readEntityLowerCaseName,
  listEntitiesLowerCaseName,
  columnDataType,
  columnDataValue,
}) => {
  if (columnDataType.renderAs === "null") {
    return null;
  }

  const getValue = get ? `${desecureText(get.toString())}` : "";

  if (columnDataType.renderAs === "knowledge-parent-id-search-box") {
    return (
      <div className="form-group" style={{
        paddingInline: "8px"
      }}>
        <div className="search-input">
          <SearchBox id={getValue} name="parent" setID={set} />
          <Button
            style={{
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              height: "40px",
            }}
            disabled={getValue.length <= 0}
            title={getValue.length <= 0 ? "Add item first" : "Click to delete the item"}
            onClick={() => {
              set("");
            }}
          >
            <Icon name="close" />
          </Button>
        </div>
      </div>
    );
  }

  if (columnDataType.renderAs === "copy-id-box") {
    if (mode === "update") {
      return (
        <span title="ID">
          You are editing the {readEntityLowerCaseName} identified by {columnDataValue}
        </span>
      );
    }
  }

  if (columnDataType.renderAs === "text") {
    return (
      <TextInputEditor
        required={columnDataType.required}
        xKey={columnDataType.key}
        name={columnDataType.name}
        get={getValue}
        set={set}
      />
    );
  }

  if (columnDataType.renderAs === "status-select") {
    return (
      <StatusSelectEditor
        required={columnDataType.required}
        xKey={columnDataType.key}
        name={columnDataType.name}
        get={getValue}
        set={set}
      />
    );
  }

  if (columnDataType.renderAs === "type-knowledge-select") {
    return (
      <TypeKnowledgeSelectEditor
        required={columnDataType.required}
        xKey={columnDataType.key}
        name={columnDataType.name}
        get={getValue}
        set={set}
      />
    );
  }

  if (columnDataType.renderAs === "knowledge-childrens-ids-multi-search-box") {
    return (
      <div
        className="form-group"
        style={{
          maxHeight: "512px",
          overflowY: "scroll",
        }}
      >
        <MulipleSelectChildren
          parentId={getFromGlobalState("id") || null}
          childrensIdsReadonly={getValue}
          childrensIds={getValue}
          setChildrensIds={set}
        />
      </div>
    );
  }

  if (columnDataType.renderAs === "entity-select") {
    return (
      <EntitySelectEditor
        required={columnDataType.required}
        xKey={columnDataType.key}
        name={columnDataType.name}
        get={getValue}
        set={set}
      />
    );
  }

  if (columnDataType.renderAs === "date") {
    return (
      <DateInputEditor
        required={columnDataType.required}
        xKey={columnDataType.key}
        name={columnDataType.name}
        get={getValue}
        set={set}
      />
    );
  }

  if (columnDataType.renderAs === "md-editor") {
    return (
      <MarkdownInputEditor
        required={columnDataType.required}
        xKey={columnDataType.key}
        name={columnDataType.name}
        get={getValue}
        set={set}
      />
    );
  }

  if (columnDataType.renderAs === "images-upload") {
    if (mode === "update") {
      if (id) {
        return (
          <MediaInputEditor
            relatedTableId={id}
            listEntitiesLowerCaseName={listEntitiesLowerCaseName}
            xKey={columnDataType.key}
            name={columnDataType.name}
            layout="edit"
          />
        );
      } else {
        return (
          <div className="form-group panel">
            <div style={{ color: "var(--p3)" }}>Something wrong the id is {typeof id}</div>
          </div>
        );
      }
    } else {
      return (
        <div className="form-group panel">
          <label htmlFor={`${columnDataType.key}`}>{`Update mode required`}:</label>
          <div id={`${columnDataType.key}`} className="flex-block">
            <div>Please save the {readEntityLowerCaseName} the you can upload images</div>
          </div>
        </div>
      );
    }
  }

  return null;
};

export default RenderElementEditor;
