import React from "react";

interface RenderTagsProps {
  tagsAsArray: string[];
}

const RenderTags: React.FC<RenderTagsProps> = ({ tagsAsArray }) => {
  if (tagsAsArray.length <= 0) return null;
  return (
    <>
      {tagsAsArray.map((el, index) => {
        if (el === "" || el === " " || el === ",") {
          return null;
        }
        return (
          <div
            key={`${el}-${index}`}
            style={{
              borderRadius: "3px",
              paddingInline: "6px",
              margin: "4px",
              background: "var(--p1)",
              color: "var(--n2)"
            }}
          >
            {el}
          </div>
        );
      })}
    </>
  );
};

export default RenderTags;
