import { getTheNearElement, timeDiff } from "../../../../../utils";

export const getNumberOfParent = (timeZoom: number, startTime: string, endTime: string) => {
  const dbg = !true;

  dbg && console.log("maxTime =>", "23:59");
  const approx_max = getTheNearElement("23:59", timeZoom, false, !true);
  dbg && console.log("approx_max =>", approx_max);

  dbg && console.log("startTime =>", startTime);
  const approx_start = getTheNearElement(startTime, timeZoom, false, !true);
  dbg && console.log("approx_start =>", approx_start);

  dbg && console.log("endTime =>", endTime);
  const approx_end = getTheNearElement(endTime, timeZoom, true, !true);
  dbg && console.log("approx_end =>", approx_end);

  const diffAsHoursStartEnd = timeDiff(approx_end, approx_start);
  dbg && console.log("diffAsHoursStartEnd =>", diffAsHoursStartEnd);

  const diffAsHoursMaxEnd = timeDiff(approx_max, approx_end);
  dbg && console.log("diffAsHoursMaxEnd =>", diffAsHoursMaxEnd);

  dbg && console.log(`${approx_end} - ${approx_start} = ${diffAsHoursStartEnd}`);

  const numberOfParentHeight = (diffAsHoursStartEnd * 60) / timeZoom;
  const numberOfParentTop = (diffAsHoursMaxEnd * 60) / timeZoom + 1;

  dbg && console.log("numberOfParentHeight =>", numberOfParentHeight);

  return { numberOfParentHeight, numberOfParentTop, approx_start, approx_end };
};
