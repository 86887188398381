import { CANCELED, COMPLETED, IN_PROGRESS, UPDATED } from "../../../lib/constants";

export const STRING_FILTER_SEARCH_TYPES = ["contains", "equal", "not_equal", "start_with", "end_with"];
export type StringFilterSearchTypes = "contains" | "equal" | "not_equal" | "start_with" | "end_with";

export const NUMBER_FILTER_SEARCH_TYPES = ["equal", "not_equal", "gt", "gte", "lt", "lte", "in", "out"];
export type NumberFilterSearchTypes = "equal" | "not_equal" | "gt" | "gte" | "lt" | "lte" | "in" | "out";

export const DATE_FILTER_SEARCH_TYPES = ["default", "equal", "not_equal", "gt", "gte", "lt", "lte", "in", "out"];
export type DateFilterSearchTypes = "default" | "equal" | "not_equal" | "gt" | "gte" | "lt" | "lte" | "in" | "out";

export const STATUS_FILTER_SEARCH_TYPES = ["equal", "not_equal"];
export type StatusFilterSearchTypes = "equal" | "not_equal";

export const INPUT_BOOLEAN_FILTER_SEARCH_TYPES = ["true", "false"];
export type InputBooleanFilterSearchTypes = "true" | "false";

export const INPUT_STATUS_FILTER_SEARCH_TYPES = [IN_PROGRESS, UPDATED, CANCELED, COMPLETED];
export type InputStatusFilterSearchTypes = "IN_PROGRESS" | "UPDATED" | "CANCELED" | "COMPLETED";
