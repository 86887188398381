import React, { useState } from "react";
import { encryptObject } from "../../../lib/crypt";
import TextInput from "../../Form/Input/TextInput/TextInput";
import SearchResult from "./SearchResult";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface SearchBoardProps {
  name: string;
  setID: React.Dispatch<React.SetStateAction<string>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
  inputBorderRadius?: string;
}

const SearchBoard: React.FC<SearchBoardProps> = ({
  name,
  setID,
  searchText,
  setSearchText,
  setSearching,
  inputBorderRadius,
}) => {
  const [startSearching, setStartSearching] = useState(false);

  React.useEffect(() => {
    let timerId: any;

    if (
      searchText !== "" &&
      searchText !== undefined &&
      searchText !== null &&
      searchText.length >= 2 &&
      searchText.length <= 128
    ) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setStartSearching(true);
      }, 1000);
    }

    return () => {
      setStartSearching(false);
      clearTimeout(timerId);
    };
  }, [searchText]);

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div className="search-board">
        <TextInput
          style={{
            borderRadius: inputBorderRadius,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          type="search"
          value={searchText}
          autoComplete="off"
          autoFocus
          onChange={(e) => setSearchText(e.target.value)}
          pattern=".{1,128}"
          required
          title="1 to 128 characters"
          id={`${name}`}
        />
        {startSearching && (
          <SearchResult
            setID={setID}
            setSearching={setSearching}
            challenge={encryptObject({
              searchFor: searchText.toLowerCase(),
            })}
          />
        )}
      </div>
    </>
  );
};

export default SearchBoard;
