import React from "react";
import BasePage from "../Base/BasePage";
import { getCRLUD } from "../Base/operation/get/crlud";
import { postDataType } from "./postDataType";

const crlud = getCRLUD("post", "posts");

const PostsPage: React.FC = () => {
  return <BasePage title="Posts" crlud={crlud} entityDataType={postDataType} />;
};

export default PostsPage;
