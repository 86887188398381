import MDEditor from "@uiw/react-md-editor";
import React, { useState } from "react";
import Icon from "../../../../../../Components/Icon";
import { desecureText } from "../../../../../../lib/desecureText";

interface MarkdownInputEditorProps {
  xKey: string;
  name: string;
  get: string;
  set: any;
  required: boolean;
}

const MarkdownInputEditor: React.FC<MarkdownInputEditorProps> = ({ xKey, name, get, set }) => {
  const [minimized, setMinimized] = useState(false);
  return (
    <div
      className="form-group panel"
      style={{
        maxHeight: minimized === true ? "360px" : "unset",
        overflowY: "scroll",
      }}
    >
      <div className="flex-block">
        <Icon
          style={{ marginLeft: "auto" }}
          onClick={() => setMinimized((e) => !e)}
          name={minimized ? "expand" : "collapse"}
        />
      </div>
      <label htmlFor={`${xKey.toString()}`}>{`${name.toString()}`}:</label>
      <div
        className="flex-block md-editor-body-input-0"
        style={{
          flexDirection: "column",
        }}
      >
        <MDEditor
          preview="edit"
          value={desecureText(get)}
          onChange={set}
          height={(window.innerHeight / 3) * 2}
          style={{
            width: "100%",
          }}
        />
        <MDEditor.Markdown source={desecureText(get)} />
      </div>
    </div>
  );
};

export default MarkdownInputEditor;
