// Modules
import { useQuery } from "@apollo/client";

// Functions
import { decryptObject } from "../../../../../lib/crypt";

// Queries
import { TOPICS } from "../../../../../apollo/graphql/knowledge";

export const useQueryTopics = (challenge: any) => {
  const { data, error, loading } = useQuery(TOPICS, {
    variables: { challenge },
  });

  if (loading) return { error: null, loading, data: null };

  if (error) return { error: error.message, loading: false, data: null };

  if (data && data.topics) {
    const topics = decryptObject(data.topics).topics;

    if (decryptObject(data.topics).topics) {
      return { error: null, loading: false, data: [...topics] };
    } else {
      return { error: "error 41534486", loading: false, data: null };
    }
  } else {
    return { error: "error 5646743", loading: false, data: null };
  }
};
