// Modules
import React, { RefObject } from "react";

// Components
/// Global
import Icon from "../../../Icon";
import Tooltip from "../../../Tooltip";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface TextInputProps {
  classLabel?: string;
  classInput?: string;
  label?: string;
  icon?: string;
  copy?: string;
  currency?: string;
  tooltip?: string;
  place?: string;
  xref?: RefObject<HTMLInputElement>;
}

type TExtendedProps = TextInputProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const TextInput: React.FC<TExtendedProps> = ({
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  xref,
  ...props
}) => {
  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div className={`field${icon ? " fieldIcon" : ""}${copy ? " fieldCopy" : ""}${currency ? " fieldCurrency" : ""}`}>
        {label && (
          <div className={label ? " classLabel" : ""}>
            {label}{" "}
            {tooltip && <Tooltip className={"tooltip"} title={tooltip} icon="info" place={place ? place : "right"} />}
          </div>
        )}
        <div className={"wrap"}>
          <input ref={xref ? xref : null} className={`${classInput ? classInput : " input"}`} {...props} />
          {icon && (
            <div className={"icon"}>
              <Icon name={icon} size="24" />{" "}
            </div>
          )}
          {copy && (
            <button className={"copy"}>
              <Icon name="copy" size="24" />{" "}
            </button>
          )}
          {currency && <div className={"currency"}>{currency}</div>}
        </div>
      </div>
    </>
  );
};

export default TextInput;
