import { EntityDataType } from "../../../types/Entity";

interface entityGetterProps {
  columnKeyToGet: string;
  entityState: any;
  dataTypeEditable: EntityDataType;
}

export const entityGetter = ({ columnKeyToGet, entityState }: entityGetterProps) => {
  const columnState: any = entityState[columnKeyToGet];

  return columnState;
};
