export enum LayoutStates {
  MOBILE_SIDEBAR = 0,
  SMALL_SIDEBAR,
  MEDIUM_SIDEBAR,
  LARGE_SIDEBAR,
  AUTO,
}

export const layoutStateLength = Object.keys(LayoutStates).length / 2;

export const layout_setting_key = "app_application_layout";

export const getLayoutStatesObj = () => {
  const asKeys = Object.keys(LayoutStates);

  let obj: any = [];

  for (let index = 0; index < layoutStateLength; index++) {
    obj.push({
      key: asKeys[index],
      value: asKeys[index + layoutStateLength],
    });
  }

  return obj;
};
