// Types
import { RouteType, RouteTypeWithElement } from "../../types/RouteTypes";

// Pages
import MediaEntypoint from "../../../../pages/Medias";

export const mediasPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Medias Page",
    name: "medias-page",
    table: "media",
    absolutePath: "/preferences/medias",
    path: "",
    Element: MediaEntypoint,
  },
];

export const mediasPageRoutesMetadata: RouteType[] = mediasPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
