import React from "react";
import moment from "moment";
import useCustomToast from "../../../../../hooks/useCustomToast";
import { decodeHTML } from "../../../../../lib/decodeHTML";
import Button from "../../../../../Components/Button/Button";
import Icon from "../../../../../Components/Icon";
import RenderTags from "../../../../../Components/TagsInput/RenderTags/RenderTags";
import InputPreservedId from "../../../../../Components/SearchBox/InputPreservedId/InputPreservedId";
import { useOnScreen } from "../../../../../hooks/useOnScreen";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface MediaProps {
  index: any;
  media: any;
  onUpdateMedia: () => void;
  onDeleteMedia: () => void;
}

const Media: React.FC<MediaProps> = ({
  index,
  onUpdateMedia,
  onDeleteMedia,
  media: {
    id,
    title,
    views,
    tags,
    name,
    domain,
    specification,
    topic,
    image,
    publishDate,
    createdAt,
    updatedAt,
  },
}) => {
  const toast = useCustomToast();

  // Ref for the element that we want to detect whether on screen
  const ref = React.useRef<any>();
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.
  const onScreen = useOnScreen(ref, "-300px");

  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (onScreen && !visible) {
      setVisible(true);
    }
  }, [onScreen, visible]);

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div className="media" ref={ref}>
        <h2 className="number">{index + 1}</h2>
        <div className="left-side">
          <div className="group-heading">
            <h2
              className="copy"
              onClick={() => {
                return toast({ copy: decodeHTML(title) });
              }}
            >
              {decodeHTML(title)}
            </h2>
            (
            <h3
              className="copy"
              onClick={() => {
                return toast({ copy: name });
              }}
            >
              {name}
            </h3>
            )
          </div>
          <div className="flex-block">
            <div
              className="flex-block"
              style={{
                flexDirection: "column",
              }}
            >
              <div className="flex-block">
                <div>Domain:</div>
                <div className="fb-2">
                  {visible && domain && (
                    <InputPreservedId silentMode={true} setSearching={() => toast({ copy: domain })} id={domain} />
                  )}
                </div>
              </div>{" "}
              <div className="flex-block">
                <div>Specification:</div>
                <div className="fb-2">
                  {visible && specification && (
                    <InputPreservedId
                      silentMode={true}
                      setSearching={() => toast({ copy: specification })}
                      id={specification}
                    />
                  )}
                </div>
              </div>{" "}
              <div className="flex-block">
                <div>Topic:</div>
                <div className="fb-2">
                  {visible && topic && (
                    <InputPreservedId silentMode={true} setSearching={() => toast({ copy: topic })} id={topic} />
                  )}
                </div>
              </div>
            </div>
            <div className="flex-block">
              <img src={image} alt={id} />
            </div>
          </div>
          <div className="flex-block">
            <div>Tags:</div>
            <div className="fb-2">{tags && <RenderTags tagsAsArray={tags && tags.split(",")} />}</div>
          </div>
        </div>
        <div className="right-side">
          <div className="flex-block action-block">
            <Button onClick={() => onUpdateMedia()}>
              <Icon name="edit" />
            </Button>

            <Button colorScheme="#de5246" onClick={() => onDeleteMedia()}>
              <Icon name="trash" />
            </Button>
          </div>
          <h3
            className=" id"
            onClick={() => {
              return toast({ copy: id! });
            }}
          >
            # {id}
          </h3>
          <div className="flex-block type">
            Views:&nbsp;&nbsp;<h3>{views}</h3>
          </div>
          <div className="flex-block">
            publishDate:&nbsp;&nbsp;<span>{moment(publishDate).format("dddd DD MMM YYYY")}</span>
          </div>{" "}
          <div className="flex-block">
            Created At:&nbsp;&nbsp;<span>{moment(createdAt).format("dddd DD MMM YYYY - HH:mm")}</span>
          </div>
          <div className="flex-block">
            Updated At: &nbsp;&nbsp;
            <span>
              {updatedAt !== createdAt ? moment(updatedAt).format("dddd DD MMM YYYY - HH:mm") : <Icon name="home" />}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Media;
