// Modules
import React from "react";
import { Link } from "react-router-dom";
import { useCurrentRoute } from "../../hooks/useCurrentRoute";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

export type TabType = {
  title: string;
  name: string;
  path: string;
};

interface TabComponentProps {
  absolutePath: string;
  chlids: TabType[];
}

type TExtendedProps = TabComponentProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TabComponent: React.FC<TExtendedProps> = ({ absolutePath, chlids, ...props }) => {
  const activeRoute = useCurrentRoute();

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <div className="tabs" {...props}>
        {chlids.map(({ name, title, path }, index: number) => (
          <Link
            to={`${absolutePath}/${path}`}
            className={`tab${activeRoute === path ? " active" : ""}`}
            key={`${name}-${index}`}
          >
            {title}
          </Link>
        ))}
      </div>
    </>
  );
};

export default TabComponent;
