import React from "react";
import { getTime } from "../utils";

export const useTime = (refreshCycle = 100) => {
  // Returns the current time
  // and queues re-renders every `refreshCycle` milliseconds (default: 100ms)

  const [now, setNow] = React.useState(getTime());

  React.useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle);

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCycle, setInterval, clearInterval, setNow, getTime]);

  // console.log("useTime > now =>  ", now);

  return now;
};
