import { useMutation } from "@apollo/client";
import React, { useCallback, useContext, useEffect } from "react";
import { UPDATE_TASK_MANAGER_SETTINGS } from "../../../apollo/graphql/task_manager";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import DisplayError from "../../../Components/Error/ErrorComponent";
import { useAltPlusKeyPress } from "../../../hooks/useAltPlusKeyPress";
import useMediaQuery from "../../../hooks/useMediaQuery";
import TopSettingsPanel from "../Components/TopSettingsPanel";
import { ITaskManagerContext, TaskManagerContext } from "../context";
import TaskManagerSettings from "../Settings/TaskManagerSettings";
import { scrollIntoNearest } from "../utils";
import { onChangeSelected } from "./HorizontalNavigator";

import TimeLine from "./Timeline/Timeline";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

const MainPanel: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  const [isSettingDrawerOpen, setSettingDrawer] = React.useState(false);

  const [updateTaskManagerSettingsMutation, { loading, error }] = useMutation(UPDATE_TASK_MANAGER_SETTINGS, {
    refetchQueries: ["QUERY_TASK_MANAGER_SETTINGS"],
  });

  const { taskManagerStore } = useContext<ITaskManagerContext>(TaskManagerContext);

  const {
    client: { nearest },
    server,
  } = taskManagerStore;

  const { timeZoom, selected } = server;

  const onCloseDrawerHandler = () => setSettingDrawer(false);
  const onOpenDrawerHandler = () => setSettingDrawer(true);

  const zeroPress = useAltPlusKeyPress("0");
  const upPress = useAltPlusKeyPress("ArrowUp");
  const asterkisPress = useAltPlusKeyPress("*");
  const dotPress = useAltPlusKeyPress(".");
  const downPress = useAltPlusKeyPress("ArrowDown");

  // React.useEffect(() => {
  //   if (!("Notification" in window)) {
  //     console.log("Browser does not support desktop notification");
  //   } else {
  //     Notification.requestPermission();
  //   }
  //   return () => {};
  // }, []);

  /**
   *  Scroll to nearest -> ALT + 0
   */
  const scrollIntoNearestTime = useCallback(() => !loading && scrollIntoNearest(nearest), [loading, nearest]);

  /**
   *  Select up -> ALT + Arrow Up
   */
  const selectUpTime = useCallback(
    () => !loading && onChangeSelected("up", selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server),
    [loading, nearest, selected, server, timeZoom, updateTaskManagerSettingsMutation]
  );

  /**
   *  Select nearest -> ALT + *
   */
  const selectNearestTime = useCallback(
    () => !loading && onChangeSelected("", selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server),
    [loading, selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server]
  );

  /**
   *  Scroll to selected -> ALT + .
   */
  const scrollToSelected = useCallback(() => !loading && scrollIntoNearest(selected), [loading, selected]);

  /**
   *  Select down -> ALT + Arrow Down
   */
  const selectDownTime = useCallback(
    () => !loading && onChangeSelected("down", selected, timeZoom, nearest, updateTaskManagerSettingsMutation, server),
    [loading, nearest, selected, server, timeZoom, updateTaskManagerSettingsMutation]
  );

  useEffect(() => {
    if (zeroPress) {
      scrollIntoNearestTime();
    }
  }, [zeroPress, scrollIntoNearestTime]);

  useEffect(() => {
    if (upPress) {
      selectUpTime();
    }
  }, [upPress, selectUpTime]);

  useEffect(() => {
    if (asterkisPress) {
      selectNearestTime();
    }
  }, [asterkisPress, selectNearestTime]);

  useEffect(() => {
    if (dotPress) {
      scrollToSelected();
    }
  }, [dotPress, scrollToSelected]);

  useEffect(() => {
    if (downPress) {
      selectDownTime();
    }
  }, [downPress, selectDownTime]);

  if (error) {
    return <DisplayError layout="modal" errorUniqueReference="91967000-9bd6-47fa-996c-5e89099b1b55" error={error} />;
  }

  return (
    <>
      <React.Suspense
        fallback={
          <LoadingComponent
            loadingUniqueReference="71c794c7-da1c-436f-aa0e-e9e3d238f3e7"
            message="MainPanel Component styles"
            transparency={1}
          />
        }
      >
        <Styling />
      </React.Suspense>

      <div className="main-panel">
        {/* <button onClick={() => notifyMe()}>Notify me!</button> */}
        <div className="panel-wrapper">
          {!isMobile && <div className="mobile-adjust"></div>}
          <TopSettingsPanel onOpenDrawerHandler={onOpenDrawerHandler} />
          <div>
            <TimeLine />
          </div>
        </div>

        <TaskManagerSettings isOpen={isSettingDrawerOpen} onClose={onCloseDrawerHandler} />
      </div>
    </>
  );
};

// function notifyMe() {
//   if (!("Notification" in window)) {
//     // Check if the browser supports notifications
//     alert("This browser does not support desktop notification");
//   } else if (Notification.permission === "granted") {
//     // Check whether notification permissions have already been granted;
//     // if so, create a notification
//     const notification = new Notification("Hi there!");
//     // …
//   } else if (Notification.permission !== "denied") {
//     // We need to ask the user for permission
//     Notification.requestPermission().then((permission) => {
//       // If the user accepts, let's create a notification
//       if (permission === "granted") {
//         const notification = new Notification("Hi there!");
//         // …
//       }
//     });
//   }

//   // At last, if the user has denied notifications, and you
//   // want to be respectful there is no need to bother them anymore.
// }

export default MainPanel;
