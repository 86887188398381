// Modules
import React from "react";
import moment from "moment";
import Calendar from "react-calendar";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

// Types
import { FilterType } from "../../../../../pages/Base/Pagination/Filter/FilterType";

// Functions
import { updateFilterSearchType, updateFilterValue } from "../utils";
import { getAttrOfItemBykey, getValueOfItemBykey } from "../../../../../lib/object";

// Components
import Select from "../../../../Select/Select";
import Button from "../../../../Button/Button";

// Constants
import { DATE_FILTER_SEARCH_TYPES } from "../../../constants";

interface FilterModalInputTypeDateProps {
  selectedFilterKey: string;
  localFilters: FilterType[] | null;
  setLocalFilters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  onFilter: (localFilters: any, clear?: boolean | undefined) => void;
}

const FilterModalInputTypeDate: React.FC<FilterModalInputTypeDateProps> = ({
  selectedFilterKey,
  localFilters,
  setLocalFilters,
  onFilter,
}) => {
  const currentSearchType = getAttrOfItemBykey(selectedFilterKey, localFilters, "searchType");

  const currentValue = getValueOfItemBykey(selectedFilterKey, localFilters);
  // console.log("currentValue=>", currentValue);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "8px",
        }}
      >
        <Select
          defaultValue={currentSearchType}
          style={{
            width: "100%",
          }}
          onChange={(e) =>
            e.target.value && updateFilterSearchType(e.target.value, selectedFilterKey, localFilters, setLocalFilters)
          }
        >
          {DATE_FILTER_SEARCH_TYPES.map((el) => (
            <option key={`key-${el}`} value={`${el}`}>
              {el}
            </option>
          ))}
        </Select>
      </div>

      {["equal", "not_equal", "gt", "gte", "lt", "lte"].includes(currentSearchType) && (
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <Calendar
            prev2Label={null}
            next2Label={null}
            value={
              currentValue && currentValue.length <= 8 ? moment(currentValue, "YYYYMMDD").toDate() : moment().toDate()
            }
            onChange={(value: any) => {
              const value__ = moment(value).format("YYYYMMDD");
              updateFilterValue(value__, selectedFilterKey, localFilters, setLocalFilters);
            }}
          />
        </div>
      )}

      {["in", "out"].includes(currentSearchType) && (
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <DateRangePicker
            clearIcon={null}
            rangeDivider="&nbsp;&nbsp;&nbsp;&nbsp;_&nbsp;&nbsp;&nbsp;&nbsp;"
            format="dd/MM/yyyy"
            value={
              currentValue && currentValue.length > 8
                ? [
                    moment(JSON.parse(currentValue)[0], "YYYYMMDD").toDate(),
                    moment(JSON.parse(currentValue)[1], "YYYYMMDD").toDate(),
                  ]
                : [moment().toDate(), moment().add(1, "days").toDate()]
            }
            onChange={(value: any) => {
              const value__ = JSON.stringify([
                moment(value[0]).format("YYYYMMDD"),
                moment(value[1]).format("YYYYMMDD"),
              ]);
              updateFilterValue(value__, selectedFilterKey, localFilters, setLocalFilters);
            }}
          />
        </div>
      )}
      {currentSearchType === "default" && (
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {["yesterday", "last_week", "last_month"].map((el) => (
              <Button
                style={{
                  borderWidth: currentValue === el ? "3px" : "",
                  width: "100%",
                  borderRadius: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
                disabled={currentValue === el}
                key={el}
                onClick={() => {
                  updateFilterValue(el, selectedFilterKey, localFilters, onFilter);
                }}
                colorScheme="yellow"
              >
                {el}
              </Button>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {["today", "this_week", "this_month"].map((el) => (
              <Button
                style={{
                  borderWidth: currentValue === el ? "3px" : "",
                  width: "100%",
                  borderRadius: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
                disabled={currentValue === el}
                key={el}
                onClick={() => {
                  updateFilterValue(el, selectedFilterKey, localFilters, onFilter);
                }}
                colorScheme="blue"
              >
                {el}
              </Button>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {["tomorrow", "next_week", "next_month"].map((el) => (
              <Button
                style={{
                  borderWidth: currentValue === el ? "3px" : "",
                  width: "100%",
                  borderRadius: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
                disabled={currentValue === el}
                key={el}
                onClick={() => {
                  updateFilterValue(el, selectedFilterKey, localFilters, onFilter);
                }}
                colorScheme="green"
              >
                {el}
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterModalInputTypeDate;
