// Enums
import { LayoutStates } from "./LayoutStates";

// Functions
export const getSidebarForceClassName = (layoutState: LayoutStates) => {
  switch (+layoutState) {
    case LayoutStates.MOBILE_SIDEBAR:
      return "force-mobile-sidebar";

    case LayoutStates.SMALL_SIDEBAR:
      return "force-small-sidebar";

    case LayoutStates.MEDIUM_SIDEBAR:
      return "force-medium-sidebar";

    case LayoutStates.LARGE_SIDEBAR:
      return "force-large-sidebar";
    case LayoutStates.AUTO:
      return "";
  }
  return;
};
