import CryptoJS from "crypto-js";
import { config } from "../config";

/**  DECLARATIONS */
const keySize = 256;
// const ivSize = 128;
const iterations = 100;
const passKey = config.C_J_K;

const _encryptObject = (obj: any) => {
  return _encryptText(JSON.stringify(obj));
};

const _decryptObject = (str: any) => {
  return JSON.parse(decryptText(str));
};

const _encryptText = (msg: any) => {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);

  const key = CryptoJS.PBKDF2(passKey, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  const encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  const transitmessage = salt.toString() + iv.toString() + encrypted.toString();

  return transitmessage
    .toString()
    .replace(/\+/g, "f5O9t3f6uOd52rr")
    .replace(/\//g, "ss6d8v4e56f3G5eg84")
    .replace(/=/g, "99t6G5eg8GGE6g58");
};

const _decryptText = (transitMessageParam: any) => {
  const transitmessage = transitMessageParam
    .toString()
    .replace(/f5O9t3f6uOd52rr/g, "+")
    .replace(/ss6d8v4e56f3G5eg84/g, "/")
    .replace(/99t6G5eg8GGE6g58/g, "=");

  const salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  const iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
  const encrypted = transitmessage.substring(64);

  const key = CryptoJS.PBKDF2(passKey, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const encryptObject = (e: any) => _encryptObject(e);
export const decryptObject = (e: any) => _decryptObject(e);

export const encryptText = (e: any) => _encryptText(e);
export const decryptText = (e: any) => _decryptText(e);
