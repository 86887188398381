// Modules
import { Navigate, Route, Routes } from "react-router-dom";

export const renderRoutes = (routes: any) => (
  <Routes>
    {routes.map((route: any, routeIndex: any) => {
      const { children, Element } = route;

      if (children) {
        return (
          <Route key={`nestedRouteIndex-${routeIndex}`} element={<Element />}>
            {children.map((nestedRoutes: any, nestedRouteIndex: any) => {
              const hasChildren = nestedRoutes.children;
              if (hasChildren) {
                return (
                  <Route
                    key={`nestedRouteIndex-${nestedRouteIndex}`}
                    path={`${nestedRoutes.path}/*`}
                    element={<nestedRoutes.ChildrenRoutes />}
                  />
                );
              } else {
                return (
                  <Route
                    key={`nestedRouteIndex-${nestedRouteIndex}`}
                    path={nestedRoutes.path}
                    element={<nestedRoutes.Element />}
                  />
                );
              }
            })}
            <Route path="*" element={<Navigate to={children[0].path} replace />} />
          </Route>
        );
      } else {
        return <Route key={`nestedRouteIndex-${routeIndex}`} path={route.path} element={<route.Element />} />;
      }
    })}
  </Routes>
);
