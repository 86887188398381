import React from "react";
import useCustomToast from "../../../../../hooks/useCustomToast";

interface CopyIDBoxProps {
  name: string;
  text: string;
}

const CopyIDBox: React.FC<CopyIDBoxProps> = ({ name, text }) => {
  const toast = useCustomToast();

  return (
    <h3
      className="id"
      onClick={() => {
        return toast({ copy: text! });
      }}
      title={name}
      style={{ marginInline: 0 }}
    >
      # {text}
    </h3>
  );
};

export default CopyIDBox;
