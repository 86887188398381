import React from "react";
import useCustomToast from "../../../../../hooks/useCustomToast";
import { decodeHTML } from "../../../../../lib/decodeHTML";

interface TitleProps {
  name: string;
  text: string;
}

const Title: React.FC<TitleProps> = ({ name, text }) => {
  const toast = useCustomToast();

  return (
    <div className="group-heading">
      <h2
        className="copy"
        onClick={() => {
          return toast({ copy: decodeHTML(text) });
        }}
        title={name}
      >
        {decodeHTML(text)}
      </h2>
    </div>
  );
};

export default Title;
