import React from "react";
import Icon from "../../../../Components/Icon";
import CurrentTimelineDate from "../CurrentTimelineDate";

interface TopSettingsPanelProps {
  onOpenDrawerHandler: () => void;
}

const TopSettingsPanel: React.FC<TopSettingsPanelProps> = ({ onOpenDrawerHandler }) => {
  return (
    <div className="top-settings-panel">
      <div className="current-date">
        <CurrentTimelineDate />
      </div>
      <Icon onClick={() => onOpenDrawerHandler()} name="setting" />
    </div>
  );
};

export default TopSettingsPanel;
