// Modules
import React from "react";
import { useMutation } from "@apollo/client";

// Queries
import { UPDATE_SETTING } from "../../../../../apollo/graphql/setting";

// Components
/// Global
import LoadingComponent from "../../../../../App/Controller/Loader/LoadingComponent";
import Button from "../../../../Button/Button";
import DisplayError from "../../../../Error/ErrorComponent";
import Icon from "../../../../Icon";

// Functions
import { decryptObject, encryptObject } from "../../../../../lib/crypt";
import TextInput from "../../TextInput/TextInput";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface SecretSettingInputProps {
  name: string;
  xKey: string;
  readSettingOnly: boolean;
}

const SecretSettingInput: React.FC<SecretSettingInputProps> = ({ name, xKey, readSettingOnly }) => {
  //   const toast = useToast();
  const [show_secret, set_show_secret] = React.useState(false);

  const [secret, set_secret] = React.useState("***********");

  const [updateSettingMutation, { loading, error }] = useMutation(UPDATE_SETTING, {
    refetchQueries: ["SETTING"],
  });

  if (loading) {
    <LoadingComponent loadingUniqueReference="e3169578-b9fc-45b7-930a-027e76f48fdf" message={`setting ${name}`} />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="9d4e42b9-c5e3-4ba1-9e89-0e78cc3cc128" error={error} />;
  }

  const updateSettingHandler = async (newUpdates: any) => {
    if (!loading && updateSettingMutation !== null) {
      const { data } = await updateSettingMutation({
        variables: {
          challenge: encryptObject({ ...newUpdates, key: xKey }),
        },
      });
      if (!data) {
        // console.log("something went wrong 01");
      }
      // console.log(data);

      const { msg } = decryptObject(data["updateSetting"]);
      if (!msg) {
        // console.log("something went wrong 02");
      }

      if (msg === "updated") {
        set_secret("");

        // toast({
        //   title: `media updated`,
        //   description: `weve updated your media for you`,
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true,
        // });

        await setTimeout(() => {
          window.location.href = `/settings`;
        }, 300);
      }
    }
  };

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <div className="setting-input">
        <label className="setting-input__header" htmlFor={xKey}>
          <p className="setting-input__header__name">{name} : </p>
          <p className="setting-input__header__key">{xKey}</p>
        </label>
        <div className="setting-input__input-group">
          <TextInput
            className="input setting-input__input-group__input"
            style={show_secret ? { borderRadius: "12px 0 0 12px" } : {}}
            autoComplete="off"
            type="text"
            readOnly={readSettingOnly}
            placeholder={readSettingOnly ? `${name} is read only` : `Enter ${name}`}
            disabled={readSettingOnly}
            id={xKey}
            value={readSettingOnly ? `${name} is read only` : show_secret ? secret : "***********"}
            onFocus={() => {
              set_show_secret(true);
              secret === "***********" && set_secret("");
            }}
            onBlur={() => {
              set_show_secret(false);
              secret === "" && set_secret("***********");
            }}
            onChange={(e) => {
              !readSettingOnly && set_secret(e.target.value);
            }}
          />

          {!readSettingOnly && secret !== "***********" && (
            <div className="setting-input__input-group__input-right-element">
              <Button
                style={{ borderRadius: "0 12px 12px 0" }}
                disabled={secret === ""}
                onClick={() => updateSettingHandler({ value: secret })}
              >
                <Icon name="save" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SecretSettingInput;
