// Functions
import { encryptObject } from "../lib/crypt";

// Constants
import { onError, servicesEndpoint, successToast } from "../constant/customToast";

export const truncateData = async (tableName: string) => {
  var formData = new FormData();

  var request = new XMLHttpRequest();

  await request.open(
    "POST",
    `${servicesEndpoint}/purge?q=${encryptObject({
      tableName,
    })}`
  );

  await request.send(formData);

  request.onload = () => {
    if (request.readyState === 4) {
      if (request.status === 200) {
        successToast(`Table ${tableName} truncated`);
      } else {
        onError(request.statusText);
      }
    }
  };
  request.onerror = () => {
    onError(request.statusText);
  };
};
