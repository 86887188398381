// Modules
import React from "react";
import { useMutation } from "@apollo/client";

// Mutations
import { CREATE_TASK, DELETE_TASK, UPDATE_TASK } from "../../../../apollo/graphql/task";

// Components
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import Button from "../../../../Components/Button/Button";
import DisplayError from "../../../../Components/Error/ErrorComponent";
import Input from "../../../../Components/Form/Input";
import TextInput from "../../../../Components/Form/Input/TextInput/TextInput";
import Icon from "../../../../Components/Icon";
import Modal from "../../../../Components/Modal/Modal";
import Select from "../../../../Components/Select/Select";

// Functions
import { onDurationChanged } from "./functions/onDurationChanged";
import { onSaveTask } from "./functions/onSaveTask";
import { onStartChanged } from "./functions/onStartChanged";
import { toggleFixNotIn } from "./functions/toggleFixNotIn";
import { ITaskManagerContext, TaskManagerContext } from "../../context";
import moment from "moment";
import { onEndChanged } from "./functions/onEndChanged";
import { capitalizeFirstLetter } from "../../../../lib/string";
import { onDeleteTask } from "./functions/onDeleteTask";
import { APP_DATE_FORMAT } from "../../../../lib/date";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

const addTime = (x: any, y: any) => {
  const momentX = moment(x, "HH:mm");

  const result = momentX.add(y, "minutes");

  return result.format("HH:mm");
};

interface CreateTaskModalProps {
  mode: "create" | "update";
  taskToEdit: any;
  setTaskToEdit: any;
  isOpen: string;
  onClose: () => void;
}

let _defaultTask = {
  title: "Do",
  category: "uncategorized",
  status: "pending",
  date: moment().format(APP_DATE_FORMAT),
  startTime: "12:00",
  duration: "0:30",
  endTime: "12:30",
  bgColor: "#16AED4",
  textColor: "#222222",
  timeColor: "#333333",
  fix: "end",
  editing: "start",
};

let defaultTask = { ..._defaultTask };

const choices = ["start", "duration", "end"];

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({ mode, taskToEdit, setTaskToEdit, isOpen, onClose }) => {
  const { taskManagerStore } = React.useContext<ITaskManagerContext>(TaskManagerContext);

  const { server } = taskManagerStore;

  const { timeZoom, currentTimelineDate } = server;

  if (mode === "update") {
    defaultTask = {
      title: taskToEdit.title,
      category: taskToEdit.category,
      status: taskToEdit.status,
      date: taskToEdit.date,
      startTime: taskToEdit.startTime,
      duration: taskToEdit.duration,
      endTime: taskToEdit.endTime,
      bgColor: taskToEdit.bgColor,
      textColor: taskToEdit.textColor,
      timeColor: taskToEdit.timeColor,
      fix: defaultTask.fix,
      editing: defaultTask.editing,
    };
  } else {
    defaultTask = { ..._defaultTask };
    defaultTask.date = moment(currentTimelineDate, APP_DATE_FORMAT).format(APP_DATE_FORMAT);
  }

  let defaultStartTime = isOpen !== "" ? `${isOpen}` : defaultTask.startTime;
  let defaultDuration = `0:${timeZoom}` || defaultTask.duration;
  let defaultEndTime = isOpen !== "" ? `${addTime(`${isOpen}`, `${timeZoom}`)}` : defaultTask.endTime;

  if (mode === "update") {
    defaultStartTime = taskToEdit.startTime;
    defaultDuration = taskToEdit.duration;
    defaultEndTime = taskToEdit.endTime;
  }

  const [title, setTitle] = React.useState(defaultTask.title);
  const [category, setCategory] = React.useState(defaultTask.category);
  const [status, setStatus] = React.useState(defaultTask.status);
  const [date, setDate] = React.useState(defaultTask.date);
  const [startTime, setStartTime] = React.useState(defaultStartTime);
  const [duration, setDuration] = React.useState(defaultDuration);
  const [endTime, setEndTime] = React.useState(defaultEndTime);
  const [bgColor, setBgColor] = React.useState(defaultTask.bgColor);
  const [textColor, setTextColor] = React.useState(defaultTask.textColor);
  const [timeColor, setTimeColor] = React.useState(defaultTask.timeColor);

  const [fix, setFix] = React.useState(defaultTask.fix);
  const [editing, setEditing] = React.useState(defaultTask.editing);

  let task: any = { title, category, status, startTime, endTime, duration, bgColor, textColor, timeColor, date };
  if (mode === "update") {
    const id = taskToEdit.id;
    task = { id, ...task };
  }
  const [createTask, { loading: loadingCreate, error: errorCreate }] = useMutation(CREATE_TASK, {
    refetchQueries: ["TASKS"],
  });

  const [updateTask, { loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_TASK, {
    refetchQueries: ["TASKS"],
  });

  const [deleteTask, { loading: loadingDelete, error: errorDelete }] = useMutation(DELETE_TASK, {
    refetchQueries: ["TASKS"],
  });

  const reset = (defaultStartTime: any, defaultDuration: any, defaultEndTime: any) => {
    setTitle(defaultTask.title);
    setCategory(defaultTask.category);
    setStatus(defaultTask.status);
    setStartTime(defaultStartTime);
    setDate(defaultTask.date);
    setDuration(defaultDuration);
    setEndTime(defaultEndTime);
    setBgColor(defaultTask.bgColor);
    setTextColor(defaultTask.textColor);
    setTimeColor(defaultTask.timeColor);
  };

  React.useEffect(() => {
    reset(defaultStartTime, defaultDuration, defaultEndTime);
    return () => {
      reset(defaultStartTime, defaultDuration, defaultEndTime);
    };
  }, [defaultStartTime, defaultDuration, defaultEndTime]);

  const loading = loadingCreate || loadingUpdate || loadingDelete;
  if (loading) {
    <LoadingComponent loadingUniqueReference="74be3a2a-4d86-4014-8e35-08893cf68b14" message={`create task`} />;
  }

  const error = errorCreate || errorUpdate || errorDelete;

  if (error) {
    return <DisplayError errorUniqueReference="ef62918a-214f-45f7-8a1d-eba05eda8fa9" error={error} />;
  }

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <Modal
        center
        activeCond={isOpen !== ""}
        Header={<h4>{capitalizeFirstLetter(mode)} your task</h4>}
        Footer={
          <div className="stack">
            {mode === "update" && (
              <div className="left-side">
                <Button
                  size="md1"
                  colorScheme="$p3"
                  onClick={() => onDeleteTask(loading, deleteTask, task.id, () => setTaskToEdit(null), "delete")}
                >
                  <Icon name="trash" />
                  Delete
                </Button>
              </div>
            )}
            <div className="right-side">
              <Button
                size="md1"
                colorScheme="$p1"
                onClick={() => {
                  mode === "create" && onSaveTask(loading, createTask, task, onClose, mode);
                  mode === "update" && onSaveTask(loading, updateTask, task, () => setTaskToEdit(null), mode);
                }}
              >
                <Icon name="save" />
                Save
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                size="md1"
                colorScheme="gray"
                onClick={() => {
                  if (mode === "update") {
                    setTaskToEdit(null);
                  } else {
                    onClose();
                  }
                }}
              >
                <Icon name="close" />
                Cancel
              </Button>
            </div>
          </div>
        }
        onClose={() => {
          if (mode === "update") {
            setTaskToEdit(null);
          } else {
            onClose();
          }
        }}
      >
        <div className="create-task-modal">
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <TextInput
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="title"
              name="title"
              type="title"
              placeholder="Your title"
              required
              icon="message"
            />
          </div>

          <div className="form-group">
            <label htmlFor="date">Date: {`${date}`}</label>
            <TextInput
              value={date}
              onChange={(e) => {
                // YYYY-MM-DD
                setDate(e.target.value);
              }}
              id="date"
              name="date"
              type="date"
              placeholder="Your date"
              required
              icon="message"
            />
          </div>

          <div className="form-controls">
            <div className="form-group">
              <div className="label-wrapper">
                <label htmlFor="startTime">Start Time: </label>
                <Icon
                  name={editing === "start" ? "edit" : fix === "start" ? "lock" : "unlock"}
                  className={editing !== "start" ? "" : "disabled"}
                  onClick={() => toggleFixNotIn(editing, choices, fix, setFix)}
                />
              </div>

              <div className="input-wrapper compact">
                <TextInput
                  onClick={() => {
                    setEditing("start");
                    if (fix === "start") {
                      setFix("end");
                    }
                  }}
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  placeholder="Start Time"
                />
                <Button onClick={() => onStartChanged(startTime, endTime, fix, duration, setDuration, setEndTime)}>
                  <Icon name="check" />
                </Button>
              </div>
            </div>

            <div className="form-group">
              <div className="label-wrapper">
                <label htmlFor="duration">Duration: </label>
                <Icon
                  name={editing === "duration" ? "edit" : fix === "duration" ? "lock" : "unlock"}
                  className={editing !== "duration" ? "" : "disabled"}
                  onClick={() => toggleFixNotIn(editing, choices, fix, setFix)}
                />
              </div>

              <div className="input-wrapper compact">
                <TextInput
                  id="duration"
                  onClick={() => {
                    setEditing("duration");
                    if (fix === "duration") {
                      setFix("start");
                    }
                  }}
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  placeholder="Duration"
                />
                <Button onClick={() => onDurationChanged(duration, startTime, endTime, setEndTime, setStartTime, fix)}>
                  <Icon name="check" />
                </Button>
              </div>
            </div>

            <div className="form-group">
              <div className="label-wrapper">
                <label htmlFor="end">End Time: </label>
                <Icon
                  name={editing === "end" ? "edit" : fix === "end" ? "lock" : "unlock"}
                  className={editing !== "end" ? "" : "disabled"}
                  onClick={() => toggleFixNotIn(editing, choices, fix, setFix)}
                />
              </div>

              <div className="input-wrapper compact">
                <TextInput
                  id="end"
                  onClick={() => {
                    setEditing("end");
                    if (fix === "end") {
                      setFix("start");
                    }
                  }}
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  placeholder="End Time"
                />
                <Button onClick={() => onEndChanged(startTime, endTime, fix, duration, setDuration, setStartTime)}>
                  <Icon name="check" />
                </Button>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label style={{ marginBottom: "4px" }} htmlFor="category">
              Category:
            </label>
            <Select id="category" value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="uncategorized">uncategorized</option>
              <option value="learning">Learning</option>
              <option value="work">Work</option>
              <option value="project">Project</option>
              <option value="relax">Relax</option>
              <option value="sleep">Sleep</option>
              <option value="holiday">Holiday</option>
              <option value="priority-0">priority 0</option>
              <option value="priority-1">priority 1</option>
              <option value="priority-2">priority 2</option>
              <option value="priority-3">priority 3</option>
              <option value="priority-4">priority 4</option>
              <option value="priority-5">priority 5</option>
            </Select>
          </div>

          <div className="form-group">
            <label style={{ marginBottom: "4px" }} htmlFor="status">
              Status:
            </label>
            <Select id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
            </Select>
          </div>

          <div className="form-controls">
            <div className="form-group">
              <label htmlFor="background">Background:</label>
              <Input id="background" value={bgColor} onChange={(e) => setBgColor(e.target.value)} type="color" />
            </div>
            <div className="form-group">
              <label htmlFor="text">Text:</label>
              <Input
                id="text"
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
                type="color"
                placeholder="Text"
              />
            </div>
            <div className="form-group">
              <label htmlFor="time-color">Time:</label>
              <Input
                id="time-color"
                value={timeColor}
                onChange={(e) => setTimeColor(e.target.value)}
                type="color"
                placeholder="Time"
              />
            </div>
          </div>

          <div className="form-controls">
            <div className="form-group">
              <label htmlFor="preview">Preview:</label>

              <div
                id="preview"
                style={{
                  color: textColor,
                  background: bgColor,
                }}
                className="preview"
              >
                <b style={{ color: timeColor }}>{duration}</b> &nbsp;|&nbsp; {title}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateTaskModal;
