// Modules
import React from "react";

// Loader
import LoadingComponent from "../../../../../App/Controller/Loader/LoadingComponent";

// Functions
import { decodeHTML } from "../../../../../lib/decodeHTML";

// Types

// Components
import DisplayError from "../../../../Error/ErrorComponent";
import Select from "../../../../Select/Select";
import { emptyCategoryContentType } from "../../../Categories";

// Hooks
import { useQueryTopics } from "./useQueryTopics";

interface TopicsProps {
  challenge: string | null;
  currentTopicId: emptyCategoryContentType;
  setCurrentTopicId: any;
}

const Topics: React.FC<TopicsProps> = ({ challenge, currentTopicId, setCurrentTopicId }) => {
  const { error, loading, data } = useQueryTopics(challenge);

  if (loading) {
    <LoadingComponent
      loadingUniqueReference="0e1c2f85-ca98-4e01-ae1e-5c71a91e50cd"
      message={`specification from server`}
    />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="cd5e1d01-f085-4721-b20f-d5d2343c98ff" error={error} />;
  }

  let ReturnJSX: any = null;

  if (data) {
    if (data.length > 0) {
      ReturnJSX = (
        <>
          <option value="">Select a topic</option>
          {data.map((topic) => (
            <option key={topic.id} data-name={topic.name} value={topic.id}>
              {decodeHTML(topic.title)}
            </option>
          ))}
        </>
      );
    } else {
      ReturnJSX = (
        <option disabled={true} value="">
          No topic found
        </option>
      );
    }
  } else {
    ReturnJSX = null;
  }

  return (
    <div className="form-group" style={{ marginBottom: "4px", width: `${100 / 3}%` }}>
      <label style={{ marginBottom: "4px" }} htmlFor="topic">
        Topic:
      </label>
      <Select
        id="topic"
        value={currentTopicId.id}
        onChange={(e) => {
          const id = e.target.value;
          const name: any = document.querySelector(`#topic option[value = '${id}']`)?.getAttribute("data-name") || "";
          setCurrentTopicId({
            id,
            name,
          });
        }}
      >
        {ReturnJSX}
      </Select>
    </div>
  );
};

export default Topics;
