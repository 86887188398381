import Swal from "sweetalert2";
import { decryptObject, encryptObject } from "../../../lib/crypt";

export const onDeleteHandler = async (deleteChildren: any, idToUnlink: string) => {
  const { data: deleteChildrenData } = await deleteChildren({
    variables: {
      challenge: encryptObject({ id: idToUnlink }),
    },
  });

  if (deleteChildrenData && deleteChildrenData.unlinkChildren) {
    // console.log(deleteChildrenData.unlinkChildren);

    const respMsg = decryptObject(deleteChildrenData.unlinkChildren);
    // console.log(respMsg);

    if (!respMsg.msg) {
      Swal.fire({
        icon: "error",
        title: `Something Wrong on unlink ${idToUnlink}`,
        html: `${respMsg.error}`,
      });
    } else if (respMsg.msg) {
      Swal.fire("Deteled!", `${idToUnlink} has been unlinked.`, "success").then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: "error",
        title: `Something Wrong on the server`,
        html: `Check the log for more details`,
      });
    }
  } else {
    Swal.fire({
      icon: "error",
      title: `Something Wrong`,
      html: `On unlink ${idToUnlink}`,
    });
  }
};
