// Modules
import React from "react";
import useDarkMode from "use-dark-mode";
import { useMutation } from "@apollo/client";

// GraphQL mutations
import { SIGNIN_MUTATION } from "../../../../apollo/graphql/user";

// Loader
import LoadingComponent from "../../Loader/LoadingComponent";

// Components
import Button from "../../../../Components/Button/Button";
import DarkModeToggle from "../../../../Components/DarkModeToggle/DarkModeToggle";
import DisplayError from "../../../../Components/Error/ErrorComponent";
import Form from "../../../../Components/Form";
import Field from "../../../../Components/Form/Field";
import TextInput from "../../../../Components/Form/Input/TextInput/TextInput";

// Functions
import { decryptObject, encryptObject } from "../../../../lib/crypt";
import { useLoading } from "../../Loader/useLoading";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

const LoginPage: React.FC = () => {
  const { loading, setLoading } = useLoading();

  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === false ? "dark" : "light";

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [signIn, { loading: signinLoading, error }] = useMutation(SIGNIN_MUTATION, {
    refetchQueries: ["CURRENT_USER_QUERY"],
  });

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!signinLoading && signIn !== null) {
      try {
        await signIn({
          variables: { challenge: encryptObject({ email, password }) },
        });
        // GuardComponent will handel that when "refetchQueries" will trigger
      } catch (error) {
        process.env.DEBUG && console.error(error);
      }
    }
  };

  React.useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  if (loading) {
    return <LoadingComponent loadingUniqueReference="453bd6fa-91b7-4aec-b421-13e4f370a22e" message="authenticating" />;
  }

  if (error && error.message) {
    const decrypted_error = JSON.parse(decryptObject(error.message));

    const { x_rate_limit_remaining } = decrypted_error;

    if (x_rate_limit_remaining === 0) {
      localStorage.setItem("ga__54684565", "411" + window.btoa(new Date().toString()) + "254");
      localStorage.setItem("ga_465489", window.btoa(new Date().toString()));

      window.location.reload();
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <LoadingComponent
            loadingUniqueReference="f0dd4832-9760-4106-8484-d20d867f335d"
            message="Login page styles"
            transparency={1}
          />
        }
      >
        <Styling />
      </React.Suspense>

      <div className="login-page">
        <div className="wrapper">
          <img className="logo" src={`/images/logo/logo_text_${isDarkMode}.png`} alt="Logo" />
          <div className="content">
            <div className="header">
              <h2 className="title">Let's Get Started</h2>
              <p className="subtitle">Sign in to continue to the Dashboard.</p>
            </div>
            <div className="body">
              {error && (
                <DisplayError
                  errorUniqueReference="53ce24e9-a966-4b56-b5f9-8a29c855d068"
                  error={error}
                  type="login-errors"
                />
              )}
              <Form onSubmit={(e) => !signinLoading && submitHandler(e)}>
                <TextInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  placeholder="Your email"
                  autoComplete="username"
                  required
                  icon="mail"
                />
                <TextInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  required
                  icon="lock"
                />
                <Field className="field">
                  <Button disabled={signinLoading} full>
                    Login
                  </Button>
                </Field>
                <Field className="field">
                  <DarkModeToggle />
                </Field>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
