import React from "react";
import { useQuery } from "@apollo/client";
import { decryptObject, encryptObject } from "../../../../../../lib/crypt";
import { TASKS } from "../../../../../../apollo/graphql/task";

export const useQueryTasks = (currentTimelineDate: any) => {
  const [challenge, setChallenge] = React.useState<any>(
    encryptObject({
      filter: [
        {
          key: "date",
          name: "date",
          value: currentTimelineDate,
          searchType: "equal",
        },
      ],
    })
  );

  const { data, error, loading } = useQuery(TASKS, {
    variables: { challenge },
  });

  React.useEffect(() => {
    setChallenge(
      encryptObject({
        filter: [
          {
            key: "date",
            name: "date",
            value: currentTimelineDate,
            searchType: "equal",
          },
        ],
      })
    );

    return () => {
      setChallenge(null);
    };
  }, [currentTimelineDate]);

  if (loading) return { error: null, loading, data: null };

  if (error) return { error: error.message, loading: false, data: null };

  if (data && data.tasks) {
    const tasks = decryptObject(data.tasks).tasks;

    if (decryptObject(data.tasks).tasks) {
      return { error: null, loading: false, data: [...tasks] };
    } else {
      return { error: "error 891615", loading: false, data: null };
    }
  } else {
    return { error: "error 7157851", loading: false, data: null };
  }
};
