import moment from "moment";
import { timeDiffAsTimeFormat } from "../../../utils";

export const onEndChanged = (
  startTime: any,
  endTime: any,
  fix: any,
  duration: any,
  setDuration: any,
  setStartTime: any
) => {
  // convert all string to time obj
  const momentTimeObjStartTime = moment(startTime, "HH:mm");
  const momentTimeObjEndTime = moment(endTime, "HH:mm");

  // check if all are valid
  if (momentTimeObjStartTime.isValid() === false) {
    console.error("Start time is not valid time!");
  } else {
    // console.info("Start time is valid time!");
  }

  if (momentTimeObjEndTime.isValid() === false) {
    console.error("End time is not valid time!");
  } else {
    // console.info("Start time is valid time!");
  }

  let result = "";

  if (fix === "start") {
    result = timeDiffAsTimeFormat(endTime, startTime);

    if (!moment(result, "HH:mm").isValid()) {
      console.error("result of start - start time is not valid time!");
    } else {
      // console.log("set duration", result);

      setDuration(result);
    }
  }

  if (fix === "duration") {
    result = timeDiffAsTimeFormat(endTime, duration);

    if (!moment(result, "HH:mm").isValid()) {
      console.error("result of end - start time is not valid time!");
    } else {
      // console.log("set duration", result);

      setStartTime(result);
    }
  }

  // console.info("result", result);
};
