import { decryptObject, encryptObject } from "../../../../../lib/crypt";
import { onError, successToast } from "../../../../../constant/customToast";

export const onDeleteTask = async (loading:any, deleteTask:any, id:any, onClose:any, mode:any) => {
  if (!loading && deleteTask !== null) {
    const { data } = await deleteTask({
      variables: {
        challenge: encryptObject({ id }),
      },
    });

    if (!data) {
      onError(`something went wrong on mutate !data`);
    }

    const { msg } = decryptObject(data[`${mode}Task`]);

    if (!msg) {
      onError(`something went wrong on mutate ${id}`);
    }

    if (msg === `${mode}d`) {
      successToast(`weve ${mode}d the task ${id} for you`);
      onClose();
    }
  }
};
