import React from "react";

// Types
import { EntityColumnType } from "../../../types/Entity";

// Components
import CopyIDBox from "../Components/CopyIDBox";
import Title from "../Components/Title";
import DateTime from "../Components/DateTime";
import RenderTags from "../../../../../Components/TagsInput/RenderTags/RenderTags";
import InputPreservedId from "../../../../../Components/SearchBox/InputPreservedId/InputPreservedId";
import useCustomToast from "../../../../../hooks/useCustomToast";
import MDEditor from "@uiw/react-md-editor";
import MediaInputEditor from "../../EntityModal/Components/MediaInputEditor";
import { useOnScreen } from "../../../../../hooks/useOnScreen";
import { desecureText } from "../../../../../lib/desecureText";

interface RenderElementProps {
  columnDataType: EntityColumnType;
  columnDataValue: string;
  entity: any;
  listEntitiesLowerCaseName: string;
}

const RenderElement: React.FC<RenderElementProps> = ({
  columnDataType,
  columnDataValue,
  entity,
  listEntitiesLowerCaseName,
}) => {
  const toast = useCustomToast();

  // // --Ref for the element that we want to detect whether on screen
  const refDetectScreen = React.useRef<any>();
  // // --Call the hook passing in ref and root margin
  // // --In this case it would only be considered onScreen if more ...
  // // --... than 300px of element is visible.
  const onScreen = useOnScreen(refDetectScreen, "-300px");

  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (onScreen && !visible) {
      setVisible(true);
    }
  }, [onScreen, visible]);

  if (columnDataType.renderAs === "null") {
    return null;
  }

  if (columnDataType.renderAs === "title") {
    return <Title name={columnDataType.name} text={columnDataValue} />;
  }

  if (columnDataType.renderAs === "copy-id-box" || columnDataType.key === "name") {
    return <CopyIDBox name={columnDataType.name} text={columnDataValue} />;
  }

  if (columnDataType.renderAs === "datetime") {
    return <DateTime name={columnDataType.name} date={columnDataValue} />;
  }

  if (
    ["text", "status-select", "type-knowledge-select", "date", "views", "entity-select"].includes(
      columnDataType.renderAs
    )
  ) {
    return (
      <span title={columnDataType.name}>
        {columnDataType.name}:{" "}
        <b style={{ color: "#21e1ae" }}>
          {columnDataValue} {columnDataType.renderAs === "date" ? `(YYYY-MM-DD)` : null}
        </b>
      </span>
    );
  }

  if (columnDataType.renderAs === "paragraph") {
    return (
      <p title={columnDataType.name}>
        {columnDataType.name}: {columnDataValue}
      </p>
    );
  }

  if (columnDataType.renderAs === "knowledge-parent-id-search-box") {
    return (
      <div ref={refDetectScreen}>
        {visible && (
          <InputPreservedId
            silentMode={true}
            setSearching={() => toast({ copy: columnDataValue })}
            id={columnDataValue}
          />
        )}
      </div>
    );
  }

  if (columnDataType.renderAs === "categories-select") {
    return (
      <div className="flex-block" style={{ marginInline: 0 }}>
        <InputPreservedId
          silentMode={true}
          setSearching={() => toast({ copy: entity["domain"] })}
          id={entity["domain"]}
        />
        <InputPreservedId
          silentMode={true}
          setSearching={() => toast({ copy: entity["specification"] })}
          id={entity["specification"]}
        />
        <InputPreservedId
          silentMode={true}
          setSearching={() => toast({ copy: entity["topic"] })}
          id={entity["topic"]}
        />
      </div>
    );
  }

  if (columnDataType.renderAs === "title-name-tags-group") {
    if (columnDataType.key === "title") {
      return (
        <div>
          <Title name={columnDataType.name} text={columnDataValue} />
        </div>
      );
    }

    if (columnDataType.key === "tags") {
      return (
        <div className="flex-block" style={{ marginInline: 0 }}>
          {columnDataType.name}: <RenderTags tagsAsArray={columnDataValue.split(",")} />
        </div>
      );
    }
  }

  if (columnDataType.renderAs === "md-editor") {
    return (
      <div>
        {columnDataType.name}: <br />
        <div className="flex-block panel" style={{ marginInline: 0 }}>
          <MDEditor.Markdown source={desecureText(columnDataValue)} />
        </div>
      </div>
    );
  }

  if (columnDataType.renderAs === "images-upload") {
    return (
      <MediaInputEditor
        relatedTableId={entity.id}
        listEntitiesLowerCaseName={listEntitiesLowerCaseName}
        xKey={columnDataType.key}
        name={columnDataType.name}
        layout="preview"
      />
    );
  }

  return null;
};

export default RenderElement;
