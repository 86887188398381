// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import PostEntypoint from "../../../pages/Posts";

export const postsPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Posts Page",
    name: "Posts-page",
    table: "post",
    absolutePath: "/posts",
    path: "",
    Element: PostEntypoint,
  },
];

export const postsPageRoutesMetadata: RouteType[] = postsPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
