import moment from "moment";

import { timeDiffAsTimeFormat } from "../../../utils";

export const onDurationChanged = (
  durationValue: string,
  startTime: string,
  endTime: string,
  setEndTime: React.Dispatch<React.SetStateAction<string>>,
  setStartTime: React.Dispatch<React.SetStateAction<string>>,
  fix: string
) => {
  // convert all string to time obj
  const momentTimeObjStartTime = moment(startTime, "HH:mm");
  const momentTimeObjDuration = moment(durationValue, "HH:mm");
  const momentTimeObjEndTime = moment(endTime, "HH:mm");

  // check if all are valid
  if (!momentTimeObjStartTime.isValid()) {
    throw Error("Start time is not valid time!");
  }
  if (!momentTimeObjDuration.isValid()) {
    throw Error("Duration is not valid time!");
  }
  if (!momentTimeObjEndTime.isValid()) {
    throw Error("End time is not valid time!");
  }

  if (fix === "start") {
    const result = momentTimeObjStartTime.add(durationValue, "minutes");

    if (!result.isValid()) {
      throw Error("Start time is not valid time!");
    } else {
      setEndTime(result.format("HH:mm"));
    }
  } else {
    // end fixed

    const result = timeDiffAsTimeFormat(endTime, durationValue);

    if (!result) {
      throw Error("Start time is not valid time!");
    } else {
      setStartTime(result);
    }
  }
};
