import { gql } from "@apollo/client";

export const SETTING = gql`
  query SETTING($challenge: String = "") {
    setting(challenge: $challenge)
  }
`;

export const UPDATE_SETTING = gql`
  mutation UPDATE_SETTING($challenge: String!) {
    updateSetting(challenge: $challenge)
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation UPDATE_SETTINGS($challenge: String!) {
    updateSettings(challenge: $challenge)
  }
`;
