import React from "react";
import useDarkMode from "use-dark-mode";
import Icon from "../../../../Components/Icon";

interface ScrollToProps {
  direction: "top" | "bottom";
}

export const normal_topbar_min_height = 80;
const ScrollTo: React.FC<ScrollToProps> = ({ direction }) => {
  const darkMode = useDarkMode();
  const defaultColor = darkMode.value ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
  const [color, setColor] = React.useState(defaultColor);

  return (
    <Icon
      name={`arrow-${direction}`}
      style={{
        fill: color,
        zIndex: "20",
        position: "fixed",
        top: direction === "top" ? `${normal_topbar_min_height}px` : "unset",
        bottom: direction === "bottom" ? `0` : "unset",
        margin: "16px",
        marginLeft: "-16px",
        width: "20px",
        height: "20px",
        cursor: "pointer",
      }}
      onMouseEnter={() => setColor(darkMode.value ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)")}
      onMouseLeave={() => setColor(defaultColor)}
      onClick={() => {
        const topDOM = window.document.getElementById(direction === "top" ? "root" : "bottom");
        topDOM && topDOM.scrollIntoView({ behavior: "smooth" });
      }}
    />
  );
};

export default ScrollTo;
