import React from "react";
import MediasList from "./Components/MediasList/MediasList";

const MediaEntypoint: React.FC = () => {
  return (
    <div>
      <div className="h3 title">Medias page</div>
      <MediasList />
    </div>
  );
};

export default MediaEntypoint;
