import { isOverlapping } from "../../../../utils/isTimeRangesOverlap";

export const getOverlapping = (mainIntervall: any) => {
  const dbg = false;
  let normalTasksList: any = [];
  let overlapedTasksList: any = [];

  const idIndex = 2;

  const INTERVALS = mainIntervall.map((task: any) => [task.startTime, task.endTime, task.id]);

  dbg && console.log("INTERVALS =>", INTERVALS);

  for (const badInterval of mainIntervall) {
    const currentInterval = [badInterval.startTime, badInterval.endTime, badInterval.id];
    const returnOverlapping = isOverlapping(
      INTERVALS.filter((el: any) => el[idIndex] !== badInterval.id),
      currentInterval
    );
    if (returnOverlapping) {
      const combUUID1 = returnOverlapping.this_one_overlap[idIndex] + "_" + returnOverlapping.with_interval[idIndex];
      dbg && console.log("combUUID1 => ", combUUID1);

      const combUUID2 = returnOverlapping.with_interval[idIndex] + "_" + returnOverlapping.this_one_overlap[idIndex];
      dbg && console.log("combUUID2 => ", combUUID2);

      const isAlreadyExist = overlapedTasksList.filter((el:any) => el.uuid === combUUID1 || el.uuid === combUUID2);
      dbg && console.log("isAlreadyExist => ", isAlreadyExist);

      if (isAlreadyExist.length === 0) {
        const first = mainIntervall.filter((el: any) => el.id === returnOverlapping.this_one_overlap[idIndex])[0];
        const second = mainIntervall.filter((el: any) => el.id === returnOverlapping.with_interval[idIndex])[0];
        dbg && console.log("first, second", first, second);

        const allIntervalTime = [
          { original: first.startTime, number: +first.startTime.replace(":", "") },
          { original: first.endTime, number: +first.endTime.replace(":", "") },
          { original: second.startTime, number: +second.startTime.replace(":", "") },
          { original: second.endTime, number: +second.endTime.replace(":", "") },
        ];
        let minTime = allIntervalTime[0];
        let maxTime = allIntervalTime[3];

        allIntervalTime.forEach((element) => {
          if (element.number < minTime.number) {
            minTime = { ...element };
          }

          if (element.number > maxTime.number) {
            maxTime = { ...element };
          }
        });

        dbg && console.log([minTime, maxTime]);

        overlapedTasksList.push({
          uuid: combUUID1,
          tasks: [first, second],
          startTime: minTime.original,
          endTime: maxTime.original,
        });
      }
    } else {
      normalTasksList.push(badInterval);
    }
  }

  dbg && console.log("normalTasksList => ", normalTasksList);
  dbg && console.log("overlapedTasksList => ", overlapedTasksList);
  return { normalTasksList, overlapedTasksList };
};

// const getMultOverlap = (mult: number, overlapedTasksList: any, tasks: any) => {
//   console.log("mult => ", mult);
//   let newObj: any = {};

//   // check if there are overlapping > 2

//   tasks.forEach(({ id }) => {
//     const resultLeft = overlapedTasksList.filter((el) => `${el.uuid}`.includes(`_${id}`));

//     if (resultLeft.length > 0) {
//       // console.log(" resultLeft => ", resultLeft);

//       const resultRight = overlapedTasksList.filter((el) => `${el.uuid}`.includes(`${id}_`));

//       if (resultRight.length > 0) {
//         mult++;

//         console.log(`${resultLeft[0].uuid}_  <=> _${resultRight[0].uuid}`);

//         newObj.uuid = `${resultLeft[0].tasks[0].id}_${resultLeft[0].tasks[1].id}_${resultRight[0].tasks[1].id}`;
//         newObj.tasks = [resultLeft[0].tasks[0], resultLeft[0].tasks[1], resultRight[0].tasks[1]];

//         console.log("newObj => ", newObj);
//       }
//     }
//   });
//   return newObj;
// };
