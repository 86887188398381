import React from "react";
import Button from "../../../../../../Components/Button/Button";
import TextInput from "../../../../../../Components/Form/Input/TextInput/TextInput";
import Icon from "../../../../../../Components/Icon";
import TagsInput from "../../../../../../Components/TagsInput/TagsInput";
import { EntityColumnType, EntityDataType } from "../../../../types/Entity";
import { ModalModeType } from "../../types";
import { onGenerateNameFromTitleAndSetTagsHandler } from "./handlers/onGenerateNameFromTitleAndSetTagsHandler";

export type GroupTitleNameSyncedParamCaseSingleType = {
  columnDataType: EntityColumnType;
  get: any;
  set: any;
};

interface RenderTitleNameTagsGroupEditorProps {
  mode: ModalModeType;
  get: any;
  set: any;
  dataType: EntityDataType;
  setEntityState: any;
}

const RenderTitleNameTagsGroupEditor: React.FC<RenderTitleNameTagsGroupEditorProps> = ({
  get,
  set,
  dataType,
  setEntityState,
}) => {
  const [isAutoCleanTitleChecked, setIsAutoCleanTitleChecked] = React.useState(false);

  const title = {
    columnDataType: dataType.filter((el) => el.key === "title")[0],
    get: () => get("title"),
    set: (value: any) => set("title", value),
  };

  const name = {
    columnDataType: dataType.filter((el) => el.key === "name")[0],
    get: () => get("name"),
    set: (value: any) => set("name", value),
  };

  const tags = {
    columnDataType: dataType.filter((el) => el.key === "tags")[0],
    get: () => get("tags"),
    set: (value: any) => set("tags", value),
  };

  const { columnDataType: nameColumnDataType, get: getName, set: setName } = name;
  const { columnDataType: titleColumnDataType, get: getTitle, set: setTitle } = title;
  const { columnDataType: tagsColumnDataType, get: getTags, set: setTags } = tags;

  const setNameAndTags = ({ name, tags }: any) => setEntityState((s: any) => ({ ...s, name, tags }));

  return (
    <div
      className="panel"
      style={{
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <div className="form-group">
        <label htmlFor={titleColumnDataType.key}>{titleColumnDataType.name}:</label>
        <div className="flex-block">
          <TextInput
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            autoComplete="off"
            value={getTitle()}
            onChange={(e) => {
              let _val = e.target.value;

              if (isAutoCleanTitleChecked && _val.split(". ")[1]) {
                _val = _val.split(". ")[1];
              }

              setTitle(_val);
            }}
            id={titleColumnDataType.key}
            name={titleColumnDataType.name}
            type="text"
            placeholder={`Enter the ${titleColumnDataType.name}`}
            required={titleColumnDataType.required}
            icon="message"
          />
          <Button
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            title="Generate name from this title"
            onClick={() => onGenerateNameFromTitleAndSetTagsHandler({ title: getTitle(), setNameAndTags })}
          >
            <Icon name="ticket" />
          </Button>
        </div>

        <div
          className="flex-block"
          style={{
            display: "flex",
            marginTop: "4px",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            style={{
              width: "16px",
              height: "16px",
              cursor: "pointer",
            }}
            id="isAutoCleanTitleChecked"
            value={isAutoCleanTitleChecked ? "1" : "0"}
            onChange={() => setIsAutoCleanTitleChecked(!isAutoCleanTitleChecked)}
          />

          <label
            style={{
              cursor: "pointer",
            }}
            htmlFor="isAutoCleanTitleChecked"
          >
            &nbsp;&nbsp;Auto Clean Title
          </label>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor={nameColumnDataType.key}>{nameColumnDataType.name}:</label>
        <div className="flex-block">
          <TextInput
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            autoComplete="off"
            value={getName()}
            onChange={(e) => setName(e.target.value)}
            id={nameColumnDataType.key}
            name={nameColumnDataType.name}
            type="text"
            placeholder={`Enter the ${nameColumnDataType.name}`}
            required={nameColumnDataType.required}
            icon="message"
          />
          <Button
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            title="Search on Google"
            onClick={() => window.open(`https://www.google.com/search?q=${getTitle().replaceAll("&", " ")}`, "_blank")}
          >
            <Icon name="search" />
          </Button>
        </div>
      </div>
      <div
        className="form-group"
        style={{
          maxHeight: "360px",
          overflowY: "scroll",
        }}
      >
        <label htmlFor="name">{tagsColumnDataType.name}:</label>
        <TagsInput tags={getTags()} setTags={setTags} />
      </div>
    </div>
  );
};

export default RenderTitleNameTagsGroupEditor;
