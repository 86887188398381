import { useQuery } from "@apollo/client";
import React from "react";
import { SEARCH_KNOWLEDGES } from "../../../../apollo/graphql/knowledge";
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import { decryptObject } from "../../../../lib/crypt";
import DisplayError from "../../../Error/ErrorComponent";
import SearchItem from "../SearchItem";

interface SearchResultProps {
  setID: React.Dispatch<React.SetStateAction<string>>;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
  challenge: string;
}

const dbg = false;

const SearchResult: React.FC<SearchResultProps> = ({ setID, challenge, setSearching }) => {
  const { data, error, loading } = useQuery(SEARCH_KNOWLEDGES, {
    variables: { challenge },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return (
      <LoadingComponent loadingUniqueReference="1cac0d9e-8ee2-4247-b970-dc760835c9c6" message={`SEARCH_KNOWLEDGES`} />
    );
  }

  if (error) {
    return <DisplayError errorUniqueReference="fa1994a0-ce1f-498c-af92-4495bd040c75" error={error} />;
  }

  if (data && dbg) {
    console.log(data);
  }

  if (data && data.searchKnowledges) {
    const knowledges = decryptObject(data.searchKnowledges).knowledges;
    // const pages = decryptObject(data.searchKnowledges).pages;

    // console.log(pages);
    // console.log(knowledges);

    if (knowledges.length > 0) {
      return (
        <div className="search-results">
          {knowledges.map((el: any, index: number) => (
            <SearchItem key={`SearchItem-${index}-${el.id}`} setSearching={setSearching} setID={setID} obj={el} />
          ))}
        </div>
      );
    } else {
      return (
        <div className="flex-column">
          <h3>No result found</h3>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default SearchResult;
