import React from "react";
import Button from "../../Button/Button";
import Icon from "../../Icon";

interface MinimizeButtonProps {
  isMediasMin: boolean;
  setIsMediasMin: React.Dispatch<React.SetStateAction<boolean>>;
}

const MinimizeButton: React.FC<MinimizeButtonProps> = ({ isMediasMin, setIsMediasMin }) => {
  return (
    <div className="flex-end">
      <Button
        className="upload-btn"
        onClick={() => {
          setIsMediasMin(!isMediasMin);
          let isMediasMin_param = "";
          if (isMediasMin) {
            isMediasMin_param = "false";
          } else {
            isMediasMin_param = "true";
          }
          if (window.history.pushState) {
            var newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?isMediasMin=" +
              isMediasMin_param;
            window.history.pushState({ path: newurl }, "", newurl);
          }
        }}
      >
        <Icon name={!isMediasMin ? "collapse" : "expand"} />
      </Button>
    </div>
  );
};

export default MinimizeButton;
