// Functions
import { encryptObject } from "../lib/crypt";

// Constants
import { servicesEndpoint } from "../constant/customToast";

export const exportData = async (tableName: string) => {
  const anchor = document.createElement("a");
  anchor.href = `${servicesEndpoint}/export?q=${encryptObject({
    tableName,
  })}`;

  document.body.appendChild(anchor);
  anchor.click();
};
