// Global
import React from "react";
import { ToastContainer } from "react-toastify";

// Local
import LayoutContainer from "./Layout/LayoutContainer";

// Children
import RoutesComponent from "./Routes/RoutesComponent";

// Styles
import "react-toastify/dist/ReactToastify.css";

const AppComponent: React.FC = () => {


  return (
    <LayoutContainer>
      <RoutesComponent />
      <ToastContainer />
    </LayoutContainer>
  );
};

export default AppComponent;
