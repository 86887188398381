// Modules
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

// Handlers
import { onDeleteEntityHandler } from "../handlers/onDeleteEntityHandler";

// Hooks
import { usePaginationMetadata } from "../Pagination/hooks/usePaginationMetadata";

// Types
import { FilterType } from "../Pagination/Filter/FilterType";
import { CrludType } from "../types/CrludType";
import { EntityDataType } from "../types/Entity";

// Functions
import { decryptObject, encryptObject } from "../../../lib/crypt";

// Constants
import { defaultPagination } from "../Pagination/constants/pagination";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import DisplayError from "../../../Components/Error/ErrorComponent";

// Components
/// Global
import Button from "../../../Components/Button/Button";
import Icon from "../../../Components/Icon";
import FilterComponent from "../../../Components/Filter";
import NoContentIllustration from "../../../Components/NoContentIllustration";
import { onFilterHandler } from "../Pagination/Filter/onFilterHandler";
import Pagination from "../../../Components/Pagination/Pagination";
import Entity from "./Entity/Entity";
import EntityModal from "./EntityModal/EntityModal";
import { routes } from "../../../App/Routes/RoutesComponent";

interface ListEntitiesProps {
  entityDataType: EntityDataType;
  crlud: CrludType;
}

let defaultChallenge = encryptObject(defaultPagination);

const ListEntities: React.FC<ListEntitiesProps> = ({ entityDataType, crlud }) => {
  const entitiesListRoutes = routes.filter((el) => el.table);

  const navigateTo = useNavigate();
  const location = useLocation();

  const entityFilters = entityDataType.map(({ side, ...rest }) => rest);

  const entityDataTypeThatCanEdit = entityDataType.filter((el) => el.canEdit === true);
  const entityDataTypeRequiredOnCreateOrUpdate = entityDataType.filter((el) => el.required === true);

  const { challenge, defaultSearch, cpyDefaultSearch } = usePaginationMetadata(entityFilters);

  const [filters, setFilter] = React.useState<FilterType[] | null>(defaultSearch);

  const [currentToDelete, setCurrentEntityToDelete] = React.useState<{
    id: string;
    title: string;
  } | null>(null);

  const [openAddNewModal, setOpenAddNewModal] = React.useState("");
  const [entityToEdit, setEntityToEdit] = React.useState(null);
  const mode = entityToEdit !== null && entityToEdit !== undefined ? "update" : "create";

  // mutations / queries >>------------------------------------------------------------------

  const listEntitiesLowerCaseName = crlud.list.operationName.toLowerCase();
  const ENTITIES = crlud.list.operationName.toUpperCase();
  const readEntityLowerCaseName = crlud.read.operationName.toLowerCase();
  const {
    data,
    error: errorList,
    loading: loadingList,
  } = useQuery(crlud.list.operation, {
    variables: { challenge: challenge ? challenge : defaultChallenge },
    fetchPolicy: "no-cache",
  });

  const [deleteEntity, { loading: loadingDelete, error: errorDelete }] = useMutation(crlud.delete.operation, {
    refetchQueries: [ENTITIES],
  });

  const loading = loadingList || loadingDelete;
  const error = errorList || errorDelete;

  // mutations / queries <<------------------------------------------------------------------

  // useEffects >>------------------------------------------------------------------

  React.useEffect(() => {
    return () => {
      entityFilters.forEach((filter) => {
        const _single_filter: FilterType = {
          key: filter.key,
          name: filter.name ? filter.name : filter.key,
          value: null,
          searchType: filter.searchType,
          type: filter.type,
        };
        defaultSearch.push(_single_filter);
      });
      setFilter([...cpyDefaultSearch]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (data && data[listEntitiesLowerCaseName] && decryptObject(data[listEntitiesLowerCaseName]).filter) {
      setFilter(decryptObject(data[listEntitiesLowerCaseName]).filter);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (currentToDelete) {
      onDeleteEntityHandler({
        id: currentToDelete.id,
        operationName: crlud.delete.operationName,
        entityName: readEntityLowerCaseName,
        loading,
        deleteEntity,
        onClose: () => setCurrentEntityToDelete(null),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToDelete]);

  React.useEffect(() => {
    // Detect wether or not create on params
    if (location.search) {
      const params = new URLSearchParams(location.search);

      if (params.has("action")) {
        const __action = params.get("action");

        if (__action === "create" && params.has("parentId")) {
          const __parentId = params.get("parentId");
          console.log(__action, __parentId);

          setTimeout(() => {
            setOpenAddNewModal(`${__parentId}`);
          }, 200);
        }
      }
    }
  }, [location.search]);

  // useEffects <<------------------------------------------------------------------

  if (loading) {
    return (
      <LoadingComponent
        loadingUniqueReference={"loading-base-" + readEntityLowerCaseName}
        message={`${listEntitiesLowerCaseName} list`}
      />
    );
  }

  if (error) {
    return <DisplayError errorUniqueReference={"error-base-" + readEntityLowerCaseName} error={error} />;
  }

  if (data && data[listEntitiesLowerCaseName]) {
    const entities = decryptObject(data[listEntitiesLowerCaseName])[listEntitiesLowerCaseName];
    const pages = decryptObject(data[listEntitiesLowerCaseName]).pages;

    return (
      <div className="base-page__list-entities">
        <div className="base-page__list-entities__panel">
          <Button
            onClick={() => setOpenAddNewModal("btn-trigger-create")}
            className="base-page__list-entities__panel__button-create"
          >
            <div className="base-page__list-entities__panel__button-create__inner-wrapper">
              <Icon name="add" />
              <span>CREATE</span>
            </div>
          </Button>

          {readEntityLowerCaseName === "knowledge" && (
            <Button
              onClick={() => navigateTo("map")}
              style={{
                paddingRight: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingRight: "16px",
                }}
              >
                <Icon name="map" /> <span>MAP</span>
              </div>
            </Button>
          )}
        </div>
        <FilterComponent
          filters={filters}
          onFilter={(localFilters: any, clear?: boolean) =>
            onFilterHandler({
              listEntitiesLowerCaseName,
              localFilters,
              clear,
              challenge,
              defaultChallenge,
              navigateTo,
            })
          }
          defaultSearch={defaultSearch}
          rowHeight={40}
          dropdownTop="292px"
          filterModalInputTop="15vh"
          filterModalInputLeft="15vw"
        />
        {(!entities || !pages) && <NoContentIllustration hint="Try to clear filter" />}
        {entities && pages > 0 && (
          <div className="base-page__list-entities__entities-container">
            {entities.map((entity: any, i: any) => (
              <Entity
                listEntitiesLowerCaseName={listEntitiesLowerCaseName}
                key={i}
                index={i}
                entityDataType={entityDataType}
                onUpdateEntity={() => setEntityToEdit(entity)}
                entity={entity}
                onDeleteEntity={() => setCurrentEntityToDelete(entity)}
              />
            ))}
          </div>
        )}
        {pages > 0 && <Pagination entity={listEntitiesLowerCaseName} pages={pages} />}
        <EntityModal
          entityDataTypeThatCanEdit={entityDataTypeThatCanEdit}
          entityDataTypeRequiredOnCreateOrUpdate={entityDataTypeRequiredOnCreateOrUpdate}
          readEntityLowerCaseName={readEntityLowerCaseName}
          listEntitiesLowerCaseName={listEntitiesLowerCaseName}
          opertaions={{
            create: crlud.create,
            update: crlud.update,
            delete: crlud.delete,
          }}
          mode={mode}
          entityToEdit={entityToEdit}
          setEntityToEdit={setEntityToEdit}
          openAddNewModal={openAddNewModal}
          isOpen={mode === "update" || openAddNewModal !== "" ? true : false}
          onClose={() => {
            setOpenAddNewModal("");
            navigateTo(`${entitiesListRoutes.filter((el) => el.table === readEntityLowerCaseName)[0].absolutePath}`);
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default ListEntities;
