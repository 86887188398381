// Modules
import React from "react";
import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

// Loaders
import LoadingComponent from "../../App/Controller/Loader/LoadingComponent";

// Functions
import { decryptObject, encryptObject } from "../../lib/crypt";

// Components
/// Global
import DisplayError from "../Error/ErrorComponent";
/// Children
import QueryMedia from "./Media/QueryMedia";

// Context

// Type
import { scrollIntoView } from "./functions/scrollIntoView";
import MinimizeButton from "./Components/MinimizeButton";
import UploadInputFullMode from "./Components/UploadInputFullMode/UploadInputFullMode";
import { MEDIAS_IDS } from "../../apollo/graphql/media";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

export type MediaInputlayoutType = "preview" | "edit";

interface MediaInputComponentProps {
  layout: MediaInputlayoutType;
  relatedTableId: string;
  folder: string;
}

const MediaInputComponent: React.FC<MediaInputComponentProps> = ({ relatedTableId, folder, layout }) => {
  const [_challenge] = React.useState(encryptObject({ relatedTableId }));
  const [mediasIDs, setMediaIDs] = React.useState<any>(null);

  const [searchParams] = useSearchParams();

  const [isMediasMin, setIsMediasMin] = React.useState(searchParams.get("isMediasMin") === "true");

  const { data, error, loading } = useQuery(MEDIAS_IDS, {
    variables: { challenge: _challenge },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!scrollIntoView(searchParams)) {
        scrollIntoView(searchParams);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [searchParams]);

  React.useEffect(() => {
    if (data && data.mediasIds && decryptObject(data.mediasIds)) {
      const mediasIds: string[] = decryptObject(data.mediasIds);
      setMediaIDs(mediasIds);
    }
    return () => {
      setMediaIDs(null);
    };
  }, [data]);

  if (loading) {
    <LoadingComponent loadingUniqueReference="dc017d11-d51b-4343-bf8b-57f6cec9e222" message="Media" />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="367cbb1c-594c-4baa-accb-231fe773bec6" error={error} />;
  }

  if (mediasIDs) {
    return (
      <>
        <React.Suspense fallback={null}>
          <Styling />
        </React.Suspense>

        <div className="medias_input_wrapper" style={{ borderColor: "transparent" }}>
          {layout === "edit" && <MinimizeButton isMediasMin={isMediasMin} setIsMediasMin={setIsMediasMin} />}
          {layout === "edit" && !isMediasMin && (
            <UploadInputFullMode folder={folder} relatedTableId={relatedTableId} mediasIds={mediasIDs} />
          )}
          <div className="query-media">
            {mediasIDs !== null &&
              mediasIDs !== "" &&
              mediasIDs.map((mediaId: string, i: number) => {
                return (
                  <QueryMedia
                    layout={layout}
                    folder={folder}
                    isMediasMin={isMediasMin}
                    key={i}
                    challenge={encryptObject({ id: mediaId })}
                    mediasIds={mediasIDs}
                    relatedTableId={relatedTableId}
                  />
                );
              })}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <DisplayError
        errorUniqueReference="3ae5c2e5-aafa-44ff-89dc-c74f2d08adc5"
        error={{ error: "Error on fetch Medias" }}
      />
    );
  }
};

export default React.memo(MediaInputComponent);
