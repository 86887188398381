// Modules
import React from "react";
import { useQuery } from "@apollo/client";

// Queries
import { MAP_KNOWLEDGES } from "../../../apollo/graphql/knowledge";

// Functions
import { decryptObject, encryptObject } from "../../../lib/crypt";

// Loader
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";

// Components
import DisplayError from "../../../Components/Error/ErrorComponent";
/// Children
import KnowledgeMapItem from "./KnowledgeMapItem/KnowledgeMapItem";

interface MapKnowledgesProps {
  challenge: string;
}

type returnType = {
  id: string;
  title: string;
  type: string;
  description: string;
  childrensIds: string;
};

const MapKnowledges: React.FC<MapKnowledgesProps> = ({ challenge }) => {
  const [parentIdOfchilds, setParentIdOfchilds] = React.useState<null | string>(null);

  const { data, error, loading } = useQuery(MAP_KNOWLEDGES, {
    variables: { challenge },
    fetchPolicy: "no-cache",
  });

  const messagesEndRef = React.useRef<HTMLInputElement>(null);

  const scrollToBottom = () => {
    messagesEndRef && messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [data]);

  if (loading) {
    <LoadingComponent loadingUniqueReference="c25b57d8-814f-4c56-b57c-161c00939e27" message={`setting layout`} />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="28da946e-bd68-4df7-9307-9a261319c065" error={error} />;
  }

  // if (data) {
  //   console.log(data);
  // }

  if (data && data.mapKnowledges) {
    const knowledges = decryptObject(data.mapKnowledges).knowledges;
    // const pages = decryptObject(data.mapKnowledges).pages;

    // console.log(pages);
    //  console.log(knowledges);

    if (knowledges.length > 0) {
      return (
        <>
          <div className="knowledge">
            {knowledges.map((know: returnType) => (
              <KnowledgeMapItem
                key={know.id}
                {...know}
                parentIdOfchilds={parentIdOfchilds}
                setParentIdOfchilds={setParentIdOfchilds}
              />
            ))}
          </div>

          {parentIdOfchilds && (
            <MapKnowledges
              challenge={encryptObject({
                parentId: `'${parentIdOfchilds}'`,
                type: null,
              })}
            />
          )}
          <div ref={messagesEndRef} />
        </>
      );
    } else {
      return null;
      // return (
      //   <Flex justifyContent="center" alignItems="center">
      //     <Text>
      //       <em>New knowledge will appear here soon</em>
      //     </Text>
      //   </Flex>
      // );
    }
  } else {
    return null;
  }
};

export default MapKnowledges;
