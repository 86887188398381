import { gql } from "@apollo/client";

export const HOSTS = gql`
  query HOSTS($challenge: String = "") {
    hosts(challenge: $challenge)
  }
`;

export const HOST = gql`
  query HOST($challenge: String!) {
    host(challenge: $challenge)
  }
`;

export const CREATE_HOST = gql`
  mutation CREATE_HOST($challenge: String!) {
    createHost(challenge: $challenge)
  }
`;

export const UPDATE_HOST = gql`
  mutation UPDATE_HOST($challenge: String!) {
    updateHost(challenge: $challenge)
  }
`;

export const DELETE_HOST = gql`
  mutation DELETE_HOST($challenge: String!) {
    deleteHost(challenge: $challenge)
  }
`;
