import { gql } from "@apollo/client";

export const query = (entity: string) => ({
  operation: gql`
    query ${entity.toUpperCase()}($challenge: String!) {
      ${entity}(challenge: $challenge)
    }
  `,
  operationName: `${entity}`,
});

export const queryOpertaion = (entity: string, opertaion: string) => ({ [opertaion]: query(entity) });
