import moment from "moment";
import React from "react";
import { decodeHTML } from "../../../../lib/decodeHTML";
import { knowledgeType } from "../../../KnowledgesMap/knowledgeType";

interface SearchItemProps {
  obj: knowledgeType;
  setID: React.Dispatch<React.SetStateAction<string>>;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchItem: React.FC<SearchItemProps> = ({
  obj: { id, title, type, createdAt, parentId, childrensIds },
  setID,
  setSearching,
}) => {
  return (
    <div
      className="search-item"
      onClick={() => {
        id && setID(id);
        setSearching(false);
      }}
      title={`
      
      --- parentId --- \n
      ${parentId}
      ------------------ \n

      --- childrensIds --- \n
      ${childrensIds}
      ------------------ \n
      `}
    >
      {decodeHTML(title)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {type}{" "}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {moment(createdAt).utcOffset("+0200").format("DD/MM/YYYY HH:mm")} (GMT+2)
    </div>
  );
};

export default SearchItem;
