import React from "react";

// Components
import ListEntities from "./Components/ListEntities";

// Types
import { CrludType } from "./types/CrludType";
import { EntityDataType } from "./types/Entity";

interface BasePageProps {
  title: string;
  entityDataType: EntityDataType;
  crlud: CrludType;
}

const BasePage: React.FC<BasePageProps> = ({ title, entityDataType, crlud }) => {
  return (
    <div className="base-page" id={`${crlud.list.operationName.toUpperCase()}-PAGE`}>
      <div className="base-page__page-title">{title}</div>
      <ListEntities entityDataType={entityDataType} crlud={crlud} />
    </div>
  );
};

export default BasePage;
