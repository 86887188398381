// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { indexPageRoutesWithElementMetadata } from "./indexMetaData";

const IndexPageRoutes: React.FC = () => {
  return (
    <Routes>
      {indexPageRoutesWithElementMetadata.map(({ path, Element }, index) => (
        <Route key={index} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default IndexPageRoutes;
