import React from "react";
import BasePage from "../../Base/BasePage";
import { getCRLUD } from "../../Base/operation/get/crlud";
import { paletteDataType } from "./palettesDataType";

const crlud = getCRLUD("palette", "palettes");

const PalettesPage: React.FC = () => {
  return <BasePage title="Palettes" crlud={crlud} entityDataType={paletteDataType} />;
};

export default PalettesPage;
