import { useQuery } from "@apollo/client";
import React from "react";
import SourceComponent from ".";
import { SOURCE } from "../../../../apollo/graphql/source";
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import { decryptObject } from "../../../../lib/crypt";
import DisplayError from "../../../Error/ErrorComponent";
import { MediaInputlayoutType } from "../../MainComponent";

interface QuerySourceProps {
  layout: MediaInputlayoutType;
  challenge: string;
  index: number;
  isMediasMin: boolean;
  hosts: any;
  folder: string;
}

const QuerySource: React.FC<QuerySourceProps> = ({ challenge, index, isMediasMin, hosts, folder, layout }) => {
  const { data, error, loading } = useQuery(SOURCE, {
    variables: { challenge },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    <LoadingComponent loadingUniqueReference="5ed442e7-d966-4dcf-b082-a7c65d94fbd3" message="Media" />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="3807425f-90c5-43f2-9ab1-d8db64669f22" error={error} />;
  }

  // console.log("QuerySource [source] => ", data);

  if (data && data.source && decryptObject(data.source) && decryptObject(data.source).source) {
    // console.log("QuerySource [source] => ", decryptObject(data.source));

    return (
      <SourceComponent
        layout={layout}
        source={decryptObject(data.source).source}
        index={index}
        isMediasMin={isMediasMin}
        hosts={hosts}
        folder={folder}
      />
    );
  } else {
    return null;
  }
};

export default QuerySource;
