// Modules
import React, { useEffect } from "react";
import NProgress from "nprogress";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

// Constants
const DEBUG = false;

interface NProgressProps {
  is_done?: boolean;
  message?: string;
}

const NProgressComponent: React.FC<NProgressProps> = ({ is_done, message }) => {
  useEffect(() => {
    NProgress.configure({ trickleSpeed: 175 });

    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  if (is_done) {
    NProgress.done();
  }

  return (
    <>
      <Styling /> {DEBUG && <p>Loading {message}</p>}
    </>
  );
};
export default NProgressComponent;
