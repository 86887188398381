export const media_filters = [
  {
    key: "id",
    name: "id",
    searchType: "equal",
    type: "string",
  },
  {
    key: "title",
    name: "title",
    searchType: "contains",
    type: "string",
  },
  {
    key: "name",
    name: "name",
    searchType: "equal",
    type: "string",
  },
  {
    key: "type",
    name: "type",
    searchType: "contains",
    type: "string",
  },
  {
    key: "description",
    name: "description",
    searchType: "contains",
    type: "string",
  },
  {
    key: "tags",
    name: "tags",
    searchType: "contains",
    type: "string",
  },
  {
    key: "parentId",
    name: "parentId",
    searchType: "contains",
    type: "string",
  },
  {
    key: "childrensIds",
    name: "childrensIds",
    searchType: "contains",
    type: "string",
  },
  {
    key: "createdAt",
    name: "createdAt",
    searchType: "equal",
    type: "date",
  },
  {
    key: "updatedAt",
    name: "updatedAt",
    searchType: "equal",
    type: "date",
  },
];
