// Modules
import React from "react";

// Functions
import { truncateData } from "../../../../maintenance/truncateData";

// Components
import Button from "../../../../Components/Button/Button";
import { getTables } from "../getTables";

// Constants

const TruncateDataPage: React.FC = () => {
  const tables = getTables();
  return (
    <div>
      <h2>Truncate Data</h2>
      <br />
      <div>
        {tables.length > 0 &&
          tables.map((table:any, index:any) => (
          <span key={`truncate-${index}`}>
            <Button style={{ margin: "8px" }} onClick={() => truncateData(table)}>
              Truncate {table}s
            </Button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TruncateDataPage;
