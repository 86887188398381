// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import ProjectEntypoint from "../../../pages/Projects";

export const projectsPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Projects Page",
    name: "Projects-page",
    table: "project",
    absolutePath: "/projects",
    path: "",
    Element: ProjectEntypoint,
  },
];

export const projectsPageRoutesMetadata: RouteType[] = projectsPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
