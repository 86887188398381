import { useQuery } from "@apollo/client";
import React from "react";
import MediaComponnent from ".";
import { MEDIA } from "../../../apollo/graphql/media";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import { decryptObject } from "../../../lib/crypt";
import DisplayError from "../../Error/ErrorComponent";
import { MediaInputlayoutType } from "../MainComponent";
import { MediaType } from "../types/media";

interface QueryMediaProps {
  layout: MediaInputlayoutType;
  challenge: string;
  mediasIds: any;
  relatedTableId: string;
  isMediasMin: boolean;
  folder: string;
}

const QueryMedia: React.FC<QueryMediaProps> = ({
  challenge,
  mediasIds,
  relatedTableId,
  isMediasMin,
  folder,
  layout,
}) => {
  const { data, error, loading } = useQuery(MEDIA, {
    variables: { challenge },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    <LoadingComponent loadingUniqueReference="795346fc-e79a-461b-b427-a765344a98f3" message="Media" />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="650e929e-0391-408e-ba95-67403ff4f0c6" error={error} />;
  }

  // console.log("QueryMedia [media] => ", data);

  if (
    data &&
    data.media &&
    decryptObject(data.media) &&
    decryptObject(data.media).media &&
    decryptObject(data.media).media.alt
  ) {
    const media: MediaType = decryptObject(data.media).media;
    // console.log("QueryMedia [media] => ", media);

    return (
      <MediaComponnent
        layout={layout}
        folder={folder}
        media={media}
        mediasIds={mediasIds}
        relatedTableId={relatedTableId}
        isMediasMin={isMediasMin}
      />
    );
  } else {
    return <div>error 2837802</div>;
  }
};

export default QueryMedia;
