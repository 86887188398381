// Modules
import React from "react";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface SelectProps {
  size?: "sm" | "md";
  full?: boolean;
  children: any;
}

type TExtendedProps = SelectProps &
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const Select: React.FC<TExtendedProps> = ({ size = "md", full, children, ...props }) => {
  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <div className="select-wrapper" style={{ width: props.style?.width, maxWidth: props.style?.maxWidth }}>
        <select
          className={`select ${props.className ? props.className : ""}${full ? " full" : ""} ${size ? size : ""}`}
          {...props}
        >
          {children}
        </select>
        {/* I use width style here to prevent big Icons */}
        <div
          style={{
            width: "1.5rem",
          }}
          className="icon-wrapper"
        >
          <svg viewBox="0 0 24 24" role="presentation" className="icon" focusable="false" aria-hidden="true">
            <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        </div>
      </div>
    </>
  );
};

export default Select;
