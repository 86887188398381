import React, { useState } from "react";

interface FlexProps {
  children?: any;
  w?: string;
  fd?: "row" | "row-reverse" | "column" | "column-reverse";
  border?: string;
  borderColor?: string;
  br?: string;
  p?: string;
  py?: string;
  px?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  m?: string;
  my?: string;
  mx?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  bg?: string;
  jc?:
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "left"
    | "right"
    | "normal"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "safe center"
    | "unsafe center"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset";

  ai?:
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "left"
    | "right"
    | "normal"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "safe center"
    | "unsafe center"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset";

  borderBottomColor?: string;
  borderBottomWidth?: string;
  borderBottomStyle?: any;
  cursor?: string;
  _hover?: any;
}

type TExtendedProps = FlexProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Flex: React.FC<TExtendedProps> = ({
  children,
  w,
  fd,
  border,
  borderColor,
  br,
  p,
  py,
  px,
  pt,
  pb,
  pl,
  pr,
  m,
  my,
  mx,
  mt,
  mb,
  ml,
  mr,
  bg,
  jc,
  ai,
  borderBottomColor,
  borderBottomWidth,
  borderBottomStyle,
  cursor,
  _hover,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  const style = {
    display: "flex",
    width: w,
    flexDirection: fd,
    border,
    borderColor,
    borderRadius: br,
    padding: p,
    paddingBlock: py,
    paddingInline: px,
    paddingTop: pt,
    paddingBottom: pb,
    paddingLeft: pl,
    paddingRight: pr,
    margin: m,
    marginBlock: my,
    marginInline: mx,
    marginTop: mt,
    marginBottom: mb,
    marginLeft: ml,
    marginRight: mr,
    background: bg,
    justifyContent: jc,
    alignItems: ai,
    borderBottomColor,
    borderBottomWidth,
    borderBottomStyle,
    cursor,
  };

  return (
    <div
      style={
        hover
          ? {
              ...style,
              ..._hover,
            }
          : style
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {children}
    </div>
  );
};

export default Flex;
