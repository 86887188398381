// Modules
import React from "react";

// Loader
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";

// Functions
import { decodeHTML } from "../../../../lib/decodeHTML";

// Types

// Components
import DisplayError from "../../../Error/ErrorComponent";
import Select from "../../../Select/Select";
import { emptyCategoryContentType } from "../../Categories";

// Hooks
import { useQuerySpecifications } from "./useQuerySpecifications";

interface SpecificationsProps {
  challenge: string;
  currentSpecification: emptyCategoryContentType;
  setCurrentSpecification: any;
}

const Specifications: React.FC<SpecificationsProps> = ({
  challenge,
  currentSpecification,
  setCurrentSpecification,
}) => {
  const { error, loading, data } = useQuerySpecifications(challenge);

  if (loading) {
    <LoadingComponent
      loadingUniqueReference="6a632c39-3cba-4bc8-b6b0-6d7841f9913f"
      message={`specification from server`}
    />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="ee04719a-3e73-4608-aafb-b15c7405760f" error={error} />;
  }

  let ReturnJSX: any = null;

  if (data) {
    if (data.length > 0) {
      ReturnJSX = (
        <>
          <option value="">Select a specification</option>
          {data.map((specification) => (
            <option key={specification.id} data-name={specification.name} value={specification.id}>
              {decodeHTML(specification.title)}
            </option>
          ))}
        </>
      );
    } else {
      ReturnJSX = (
        <option disabled={true} value="">
          No specification found
        </option>
      );
    }
  } else {
    ReturnJSX = null;
  }

  return (
    <div className="form-group" style={{ marginBottom: "4px", marginRight: "16px", width: `${100 / 3}%` }}>
      <label style={{ marginBottom: "4px" }} htmlFor="specification">
        Specification:
      </label>
      <Select
        id="specification"
        value={currentSpecification.id}
        onChange={(e) => {
          const id = e.target.value;
          const name: any =
            document.querySelector(`#specification option[value = '${id}']`)?.getAttribute("data-name") || "";
          setCurrentSpecification({
            id,
            name,
          });
        }}
      >
        {ReturnJSX}
      </Select>
    </div>
  );
};

export default Specifications;
