// Modules
import React from "react";

// Renderers
import { renderRoutes } from "../../utils/renderRoutes";

// Metadata
import { backupDataPageRoutesMetadataWithElement } from "./BackupDataMetadata";

const BackupDataPageRoutes: React.FC = () => {
  return renderRoutes(backupDataPageRoutesMetadataWithElement);
};

export default BackupDataPageRoutes;
