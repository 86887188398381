import React from "react";
import MediasInput from "../../../../../../Components/MediasInput";
import { MediaInputlayoutType } from "../../../../../../Components/MediasInput/MainComponent";

interface MediaInputEditorProps {
  listEntitiesLowerCaseName: string;
  xKey: string;
  name: string;
  relatedTableId: string;
  layout: MediaInputlayoutType;
}

const MediaInputEditor: React.FC<MediaInputEditorProps> = ({
  listEntitiesLowerCaseName,
  xKey,
  name,
  relatedTableId,
  layout
}) => {
  return (
    <div className="form-group panel">
      <label htmlFor={`${xKey.toString()}`}>{`${name.toString()}`}:</label>
      <div className="flex-block">
        <MediasInput relatedTableId={relatedTableId} folder={listEntitiesLowerCaseName} layout={layout} />
      </div>
    </div>
  );
};

export default MediaInputEditor;
