// Modules
import React from "react";

// Functions
import { exportData } from "../../../../maintenance/exportData";

// Components
import Button from "../../../../Components/Button/Button";

// Constants
import { getTables } from "../getTables";

const ExportDataPage: React.FC = () => {
  const tables = getTables();
  return (
    <div>
      <h2>Export data</h2>
      <br />
      <div>
        {tables.length > 0 &&
          tables.map((table: any, index: any) => (
            <span key={`export-${index}`}>
              <Button style={{ margin: "8px" }} onClick={() => exportData(table)}>
                Export {table}s
              </Button>
            </span>
          ))}
      </div>
    </div>
  );
};

export default ExportDataPage;
