// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import TaskManagerEntypoint from "../../../pages/TaskManager";

export const taskManagerPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "TaskManager Page",
    name: "taskManager-page",
    table: "task",
    absolutePath: "/",
    path: "",
    Element: TaskManagerEntypoint,
  },
];

export const taskManagerPageRoutesMetadata: RouteType[] = taskManagerPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
