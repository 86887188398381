// Modules
import React from "react";
import { useMutation } from "@apollo/client";

// Components
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import Button from "../../../../Components/Button/Button";
import Icon from "../../../../Components/Icon";
import Modal from "../../../../Components/Modal/Modal";

// Functions
import { capitalizeFirstLetter } from "../../../../lib/string";
import { onSaveEntityHandler } from "../../handlers/onSaveEntityHandler";
import { useSearchParams } from "react-router-dom";
import { EntityColumnType, EntityDataType } from "../../types/Entity";
import RenderElementEditor from "./RenderElementEditor/RenderElementEditor";
import { OpertaionType } from "../../types/CrludType";
import { onDeleteEntityHandler } from "../../handlers/onDeleteEntityHandler";
import { ModalModeType } from "./types";
import { getEntityState } from "./functions/getEntityState";
import { entityGetter } from "./functions/entityGetter";
import { entitySetter } from "./functions/entitySetter";
import RenderTitleNameTagsGroupEditor from "./Components/RenderTitleNameTagsEditor";
import RenderCategoriesSelectGroupEditor from "./Components/RenderCategoriesSelectGroupEditor";
import Swal from "sweetalert2";

interface EntityModalProps {
  entityDataTypeThatCanEdit: EntityDataType;
  entityDataTypeRequiredOnCreateOrUpdate: EntityDataType;
  readEntityLowerCaseName: string;
  listEntitiesLowerCaseName: string;
  opertaions: {
    create: OpertaionType;
    update: OpertaionType;
    delete: OpertaionType;
  };
  mode: ModalModeType;
  entityToEdit: any;
  setEntityToEdit: any;
  isOpen: boolean;
  openAddNewModal: string;
  onClose: () => void;
}

const EntityModal: React.FC<EntityModalProps> = ({
  openAddNewModal,
  entityDataTypeThatCanEdit,
  entityDataTypeRequiredOnCreateOrUpdate,
  readEntityLowerCaseName,
  listEntitiesLowerCaseName,
  opertaions,
  mode,
  entityToEdit,
  setEntityToEdit,
  isOpen,
  onClose,
}) => {
  // ->-------------------------- Entity State ---------------------------
  // ->>-------------------------- unify state object ---------------------------

  const getState = getEntityState({
    mode,
    dataTypeEditable: entityDataTypeThatCanEdit,
    entityData: entityToEdit,
  });

  // -<<-------------------------- State one source of truth ---------------------------

  const [entityState, setEntityState] = React.useState(getState);

  // -<-------------------------- Getter / Setter ---------------------------

  const get = (columnKeyToGet: string) =>
    entityGetter({ columnKeyToGet, entityState, dataTypeEditable: entityDataTypeThatCanEdit });

  const set = (columnKeyToGet: string, value: any) =>
    entitySetter({ columnKeyToGet, value, entityState, setEntityState, dataTypeEditable: entityDataTypeThatCanEdit });

  const filtedArrayForParentID = entityDataTypeThatCanEdit.filter((el) => el.key === "parentId");

  // -<-------------------------- Entity State ---------------------------
  // ->-------------------------- split state by single OR group ---------------------------
  // copy entityDataTypeThatCanEdit
  let singleEntityDataTypeThatCanEdit: EntityDataType = [];

  // get [TITLE-NAME-TAGS] group
  // 1st: "title-name-tags-group"
  // 2nd: "title-name-tags-group"
  // 3rd: "title-name-tags-group"
  let titleNameTagsGroup: EntityDataType = [];
  let categoriesSelect: EntityDataType = [];

  // filter and arrange
  entityDataTypeThatCanEdit.forEach((dataColumn: EntityColumnType) => {
    if (dataColumn.renderAs === "title-name-tags-group") {
      titleNameTagsGroup.push(dataColumn);
    } else if (dataColumn.renderAs === "categories-select") {
      categoriesSelect.push({
        key: "domain",
        name: "Domain",
        unique: false,
        canEdit: true,
        side: "right",
        renderAs: "categories-select",
        searchType: "equal",
        type: "string",
        required: true,
      });
      categoriesSelect.push({
        key: "specification",
        name: "Specification",
        unique: false,
        canEdit: true,
        side: "right",
        renderAs: "categories-select",
        searchType: "equal",
        type: "string",
        required: true,
      });
      categoriesSelect.push({
        key: "topic",
        name: "Topic",
        unique: false,
        canEdit: true,
        side: "right",
        renderAs: "categories-select",
        searchType: "equal",
        type: "string",
        required: true,
      });
    } else {
      singleEntityDataTypeThatCanEdit.push(dataColumn);
    }
  });

  // ->-------------------------- split state by single OR group ---------------------------

  const [clearForm, setClearForm] = React.useState(true);

  const [searchParams] = useSearchParams();
  const _redirectToList = searchParams.get("redirectToList");
  const [redirectToList, setRedirectToList] = React.useState(_redirectToList === "false" ? false : true);
  const ENTITIES = `${listEntitiesLowerCaseName.toUpperCase()}`;

  const [createEntity, { loading: loadingCreate, error: errorCreate }] = useMutation(opertaions.create.operation, {
    refetchQueries: [ENTITIES],
  });

  const [updateEntity, { loading: loadingUpdate, error: errorUpdate }] = useMutation(opertaions.update.operation, {
    refetchQueries: [ENTITIES],
  });

  const [deleteEntity, { loading: loadingDelete, error: errorDelete }] = useMutation(opertaions.delete.operation, {
    refetchQueries: [ENTITIES],
  });

  // ->-------------------------- functions ---------------------------

  const onUpdateCloseModal = () => setEntityToEdit(null);

  const reset = React.useCallback(() => {
    //reset all children
    setEntityState(getState);
  }, [getState]);

  const onClearForm = () => {
    if (clearForm) {
      reset();

      setClearForm(true);
      if (_redirectToList === "true") {
        setRedirectToList(true);
      }

      if (!searchParams.get("parentId")) {
        if (filtedArrayForParentID.length > 0) {
          set("parentId", "");
        }
      }
    }
  };
  const onRedirectToList = () => {
    if (redirectToList) {
      onClose();
    }
  };

  // -<-------------------------- functions ---------------------------

  // ->-------------------------- useEffect ---------------------------
  React.useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  React.useEffect(() => {
    if (openAddNewModal && openAddNewModal !== "btn-trigger-create") {
      if (filtedArrayForParentID.length > 0) {
        set("parentId", openAddNewModal);

        let oldState = { ...getState };
        oldState.parentId = openAddNewModal;
        oldState.type = "section";

        setEntityState(oldState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAddNewModal]);
  // -<-------------------------- useEffect ---------------------------

  const loading = loadingCreate || loadingUpdate || loadingDelete;
  if (loading) {
    <LoadingComponent
      loadingUniqueReference={`loading-${readEntityLowerCaseName}`}
      message={`${mode} ${readEntityLowerCaseName}`}
    />;
  }

  const error = errorCreate || errorUpdate || errorDelete;

  if (error) {
    Swal.fire({
      icon: "error",
      title: `Error on ${readEntityLowerCaseName}`,
      html: `${error && error.message}`,
    });
    return null;
  }

  return (
    <Modal
      maxWidth="95vw"
      center
      activeCond={isOpen}
      Header={
        <h4>
          {capitalizeFirstLetter(mode)} your {readEntityLowerCaseName}
        </h4>
      }
      Footer={
        <div className="stack entity-modal-footer">
          <div className="left-side">
            <Button
              onClick={() => setClearForm((s) => !s)}
              className={`button-icon ${clearForm ? "selected" : ""}`}
              title="Clear form after submit"
            >
              <Icon name="reload" />
            </Button>
            <Button
              onClick={() => setRedirectToList((s) => !s)}
              className={`button-icon ${redirectToList ? "selected" : ""}`}
              title="Redirect to entitys list"
            >
              <Icon name="grid" />
            </Button>
            {mode === "update" && (
              <Button
                size="md1"
                colorScheme="$p3"
                onClick={() =>
                  onDeleteEntityHandler({
                    id: entityToEdit.id,
                    operationName: opertaions.delete.operationName,
                    entityName: readEntityLowerCaseName,
                    loading,
                    deleteEntity,
                    onClose: () => setEntityToEdit(null),
                  })
                }
              >
                <Icon name="trash" />
                Delete
              </Button>
            )}
          </div>

          <div className="right-side">
            <Button
              size="md1"
              colorScheme="$p1"
              onClick={() => {
                mode === "create" &&
                  onSaveEntityHandler({
                    entityDataTypeRequiredOnCreateOrUpdate,
                    mode,
                    operationName: opertaions.create.operationName,
                    entityName: readEntityLowerCaseName,
                    loading,
                    createOrUpdateEntity: createEntity,
                    entity: entityState,
                    onClose: () => null,
                    onClearForm,
                    onRedirectToList,
                  });

                mode === "update" &&
                  onSaveEntityHandler({
                    entityDataTypeRequiredOnCreateOrUpdate,
                    mode,
                    operationName: opertaions.update.operationName,
                    entityName: readEntityLowerCaseName,
                    loading,
                    createOrUpdateEntity: updateEntity,
                    entity: entityState,
                    onClose: onUpdateCloseModal,
                    onClearForm: () => null,
                    onRedirectToList: () => null,
                  });
              }}
            >
              <Icon name="save" />
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              size="md1"
              colorScheme="gray"
              onClick={() => {
                if (mode === "update") {
                  onUpdateCloseModal();
                } else {
                  onClose();
                }
              }}
            >
              <Icon name="close" />
              Cancel
            </Button>
          </div>
        </div>
      }
      onClose={() => {
        if (mode === "update") {
          onUpdateCloseModal();
        } else {
          onClose();
        }
      }}
    >
      <div
        className="entity-modal"
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {titleNameTagsGroup.length === 3 && (
          <RenderTitleNameTagsGroupEditor
            mode={mode}
            get={get}
            set={set}
            setEntityState={setEntityState}
            dataType={titleNameTagsGroup}
          />
        )}
        {categoriesSelect.length === 3 && (
          <RenderCategoriesSelectGroupEditor get={get} setEntityState={setEntityState} />
        )}
        {singleEntityDataTypeThatCanEdit.map((entityDataColumn: EntityColumnType) => (
          <React.Fragment key={entityDataColumn.key}>
            <RenderElementEditor
              id={get("id") || undefined}
              mode={mode}
              get={get(entityDataColumn.key)}
              getFromGlobalState={(x: string) => get(x)}
              set={(value: any) => set(entityDataColumn.key, value)}
              readEntityLowerCaseName={readEntityLowerCaseName}
              listEntitiesLowerCaseName={listEntitiesLowerCaseName}
              columnDataType={entityDataColumn}
              columnDataValue={
                mode === "update" ? entityToEdit[entityDataColumn.key] : entityState[entityDataColumn.key]
              }
            />
          </React.Fragment>
        ))}
      </div>
    </Modal>
  );
};

export default EntityModal;
