// Functions
import { encryptObject } from "../lib/crypt";

// Constants
import { onError, servicesEndpoint, successToast } from "../constant/customToast";

export const importData = async (tableName: string) => {
  const importDataInputFile = document.getElementById("importDataInputFile") as HTMLInputElement;

  if (importDataInputFile && importDataInputFile.files && importDataInputFile.files[0]) {
    var formData = new FormData();

    // HTML file input, chosen by user
    formData.append("jsonfile", importDataInputFile.files[0]);

    var request = new XMLHttpRequest();
    await request.open(
      "POST",
      `${servicesEndpoint}/import?q=${encryptObject({
        tableName,
      })}`
    );

    await request.send(formData);

    request.onload = () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          successToast(`Table ${tableName} truncated and all data imported`);
          const importDataForm = document.getElementById("importDataForm") as HTMLFormElement;
          importDataForm.reset();
        } else {
          onError(request.statusText);
        }
      }
    };
    request.onerror = () => onError(request.statusText);
  }
};
