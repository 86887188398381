import React from "react";

interface FormProps {
  children: any;
}

type TExtendedProps = FormProps &
  React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >;

const Form: React.FC<TExtendedProps> = ({ children, ...props }) => {
  return (
    <form className="form" {...props}>
      {children}
    </form>
  );
};

export default Form;
