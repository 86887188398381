import React from "react";
import moment from "moment";

interface DateTimeProps {
  name: string;
  date: string;
}

const DateTime: React.FC<DateTimeProps> = ({ name, date }) => {
  const dateObject = moment(date);
  return dateObject.isValid() ? (
    <div className="flex-block"  style={{ marginInline: 0 }} title={name}>
      {name}:&nbsp;&nbsp;<span style={{color: "#21e1ae"}}>{moment(date).format("dddd DD MMM YYYY - HH:mm")}</span>
    </div>
  ) : null;
};

export default DateTime;
