// Modules
import React from "react";
import useDarkMode from "use-dark-mode";

// Components
import Icon from "../Icon";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface DarkModeToggleProps {
  className?: string;
  visibleSidebar?: boolean;
}

const DarkModeToggle: React.FC<DarkModeToggleProps> = ({ className, visibleSidebar = true }) => {
  const darkMode = useDarkMode(true);

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <label className={`${className ? className : "dark-mode-toggle"} ${visibleSidebar ? " wide" : ""}`}>
        <input className="dark-mode-toggle-input" checked={darkMode.value} onChange={darkMode.toggle} type="checkbox" />
        <span className="dark-mode-toggle-inner">
          <span className="dark-mode-toggle-box">
            <Icon name="sun" size="24" />
            Light
          </span>
          <span className="dark-mode-toggle-box">
            <Icon name="moon" size="24" />
            Dark
          </span>
        </span>
      </label>
    </>
  );
};

export default DarkModeToggle;
