// Modules
import React from "react";
import useDarkMode from "use-dark-mode";

interface Props {
  rowHeight: number;
  children: any;
}

const FilterWrapper: React.FC<Props> = ({ children, rowHeight }) => {
  const darkMode = useDarkMode();
  const isDarkMode = darkMode.value === true;

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        borderBottomWidth: ".125px",
        borderBottomStyle: "solid",
        borderBottomColor: `${isDarkMode ? "#555" : "#333"}`,
        alignItems: "center",
        marginTop: "23px",
        marginBottom: "23px",
        background: isDarkMode ? "var(--n6)" : "var(--n1)",
        minHeight: `${rowHeight}px`,
      }}
    >
      {children}
    </div>
  );
};

export default FilterWrapper;
