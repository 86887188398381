import React from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_MANAGER_SETTINGS } from "../../../../apollo/graphql/task_manager";
import { ITaskManagerContext, TaskManagerContext } from "../../context";
import moment from "moment";
import { encryptObject } from "../../../../lib/crypt";
import TextInput from "../../../../Components/Form/Input/TextInput/TextInput";
import { APP_DATE_FORMAT } from "../../../../lib/date";

const updateServerHandler = (date:any, updateTaskManagerSettingsMutation:any, server:any) => {
  updateTaskManagerSettingsMutation({
    variables: {
      challenge: encryptObject({
        settings: {
          ...server,
          currentTimelineDate: date,
        },
      }),
    },
  });
};

interface DateSettingsProps {}

const DateSettings: React.FC<DateSettingsProps> = () => {
  const [updateTaskManagerSettingsMutation, { loading, error }] = useMutation(UPDATE_TASK_MANAGER_SETTINGS, {
    refetchQueries: ["QUERY_TASK_MANAGER_SETTINGS", "TASKS"],
  });

  const { taskManagerStore } = React.useContext<ITaskManagerContext>(TaskManagerContext);

  const { server } = taskManagerStore;

  const { currentTimelineDate } = server;

  const defaultDate = moment(currentTimelineDate, APP_DATE_FORMAT).format(APP_DATE_FORMAT);

  if (error) return <div>Error5454215121</div>;

  return (
    <div className="form-group">
      <label style={{ display: "block", paddingBottom: "4px" }} htmlFor="date">
        Current Timeline Date:
      </label>
      <TextInput
        value={defaultDate}
        onChange={
          (e) => {
            // YYYY-MM-DD
            !loading && updateServerHandler(e.target.value, updateTaskManagerSettingsMutation, server);
          }
          //
        }
        type="date"
        placeholder="Your date"
        required
        icon="message"
      />
    </div>
  );
};

export default DateSettings;
