import moment from "moment";
import { timeZoomArray } from "../MainPanel/SliderMarkZoomTime/constants";

export const scrollIntoNearest = (nearest: string) => {
  const getCurrentElement = window.document.getElementById(`${nearest}`);
  if (getCurrentElement) {
    const yOffset = -220;
    const y = getCurrentElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });

    // console.log(getCurrentElement);

    // getCurrentElement.scrollIntoView({ behavior: "smooth" });
  }
};

export const generateTime = (step: number) => {
  return Array.from({ length: 24 }, (_, i) => i).reduce((r: any, hour) => {
    r.push(moment({ hour, minute: 0 }).format("HH:mm"));

    const mult = 60 / step - 1;
    // console.log("mult =>", mult);

    Array.from({ length: mult }, (_, i) => i + 1).forEach((mul) => {
      r.push(moment({ hour, minute: mul * step }).format("HH:mm"));
    });

    return r;
  }, []);
};

export const getColor = (type: any) => {
  switch (type) {
    case "o'clock":
      return "#374258";

    case "half":
      return "#2e3a53";

    case "fragment":
      return "#242c3c";

    case "nearest":
      return "#3182ce";
    case "selected":
      return "green";

    default:
      return "blue";
  }
};

export const getBorder = (time: any, nearest: any, timeZoom: any, selected: any) => {
  return selected === time
    ? `1px ridge ${getColor("selected")}`
    : time.split(":")[1] === "00"
    ? `1px ridge ${nearest === time ? getColor("nearest") : getColor("o'clock")}`
    : time.split(":")[1] === `${(60 / timeZoom / 2) * timeZoom}`
    ? `1px dotted ${nearest === time ? getColor("nearest") : getColor("half")}`
    : `1px dashed ${nearest === time ? getColor("nearest") : getColor("fragment")}`;
};

export const getTheNearElement = (time: string, timeZoom: number, roundUp = false, debug = false) => {
  // console.log("time =>", time);

  if (timeZoom === 1) return time;

  const dbg = debug && true;
  const timeAsArray = time.split(":");
  const hours = timeAsArray[0];
  const hoursTens = hours.split("")[0];
  dbg && console.log("hoursTens => ", hoursTens);

  const hoursOnes = hours.split("")[1];
  dbg && console.log("hoursOnes => ", hoursOnes);

  const minutes = timeAsArray[1];
  const minutesTens = minutes.split("")[0];
  dbg && console.log("minutesTens => ", minutesTens);

  const minutesOnes = minutes.split("")[1];
  dbg && console.log("minutesOnes => ", minutesOnes);

  if (+minutes % timeZoom === 0) {
    dbg && console.log("+minutes % timeZoom === 0");
    dbg && console.log(`${+minutes} % ${timeZoom} === 0`);

    return time;
  }

  const leftComp = +minutes % timeZoom;
  const rightComp = timeZoom / 2;

  const cond1 = () => {
    dbg && console.log("+minutes % timeZoom < timeZoom / 2");
    dbg && console.log(`${+minutes} % ${timeZoom} < ${timeZoom} / 2`);

    return moment(time, "HH:mm")
      .subtract(+minutes % timeZoom, "minutes")
      .format("HH:mm");
  };

  const cond2 = () => {
    dbg && console.log("NOT +minutes % timeZoom < timeZoom / 2");
    dbg && console.log(`${+minutes} % ${timeZoom} < ${timeZoom} / 2`);

    return moment(time, "HH:mm")
      .subtract(+minutes % timeZoom, "minutes")
      .add(timeZoom, "minutes")
      .format("HH:mm");
  };

  if (roundUp) {
    if (leftComp <= rightComp) {
      dbg && console.log("1");

      return cond2();
    } else {
      dbg && console.log("2");

      return cond1();
    }
  } else {
    if (leftComp < rightComp) {
      dbg && console.log("3");

      return cond2();
    } else {
      dbg && console.log("4");

      return cond1();
    }
  }
};

//
// getTime helper function
// (helpful for testing)
// (and for showing that this hook isn't specific to any datetime library)
//

export const getTime = () => {
  return moment().format("HH:mm");
};

export const getNearestTime = (timeZoom: number) => {
  let elementToCorrect = timeZoom;
  // console.log("timeZoom =>", timeZoom);

  for (let index = 0; index < timeZoomArray.length; index++) {
    const elementPrev = timeZoomArray[index];
    const elementNext = timeZoomArray[index + 1];
    if (timeZoom >= elementPrev && timeZoom <= elementNext) {
      // console.log(elementPrev, timeZoom, elementNext);
      const avg = (elementPrev + elementNext) / 2;
      // console.log("avg => ", avg);

      if (timeZoom >= avg) {
        elementToCorrect = elementNext;
      } else {
        elementToCorrect = elementPrev;
      }
    }
  }

  return elementToCorrect;
};

// export const getBoundingClientRectObj = (element: HTMLElement): DOMRect => {
//   const { top, right, bottom, left, width, height, x, y, toJSON } = element.getBoundingClientRect();
//   return { top, right, bottom, left, width, height, x, y, toJSON };
// };

// export const useDimensions = (startTime: string, endTime: string, timeZoom: number): dimensionsType | undefined => {
//   const dbg = !true;

//   dbg && console.log("startTime =>", startTime);
//   const approx_start = getTheNearElement(startTime, timeZoom, false, !true);
//   dbg && console.log("approx_start =>", approx_start);

//   dbg && console.log("endTime =>", endTime);
//   const approx_end = getTheNearElement(endTime, timeZoom, true, !true);
//   dbg && console.log("approx_end =>", approx_end);

//   // const isApproxStartVisible = useOnScreenByID(approx_start);
//   // dbg && console.log("isApproxStartVisible =>", isApproxStartVisible);

//   // const isApproxEndVisible = useOnScreenByID(approx_end);
//   // dbg && console.log("isApproxEndVisible =>", isApproxEndVisible);

//   const startDOMElement = window.document.getElementById(`${approx_start}`);
//   const endDOMElement = window.document.getElementById(`${approx_end}`);

//   if (startDOMElement && endDOMElement) {
//     const startBoundingClientRect = getBoundingClientRectObj(startDOMElement!);
//     const endBoundingClientRect = getBoundingClientRectObj(endDOMElement!);

//     const dimensions = { start: { ...startBoundingClientRect }, end: { ...endBoundingClientRect } };

//     return { dimensions, approx_start, approx_end };
//   }
// };

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 64,
        g: 64,
        b: 64,
      };
};

export const timeDiff = (endTime: string, startTime: string) => {
  const _dateEnd = moment(endTime, "HH:mm");
  const _dateStart = moment(startTime, "HH:mm");

  const timeDiff = _dateEnd.diff(_dateStart);
  const diffAsHours = moment.duration(timeDiff).asHours();
  return diffAsHours;
};

export const timeDiffAsTimeFormat = (endTime: string, startTime: string) => {
  const now = moment(`01/01/2023 ${startTime}:00`, "DD/MM/YYYY HH:mm:ss"); //.format("DD/MM/YYYY HH:mm")
  const then = moment(`01/01/2023 ${endTime}:00`, "DD/MM/YYYY HH:mm:ss");

  const ms = then.diff(now);
  const d = moment.duration(ms);
  const s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm");

  if (s.includes("-")) {
    const _now = moment(`01/01/2023 ${startTime}:00`, "DD/MM/YYYY HH:mm:ss"); //.format("DD/MM/YYYY HH:mm")

    const _then = moment(`02/01/2023 ${endTime}:00`, "DD/MM/YYYY HH:mm:ss");

    const _ms = _then.diff(_now);
    const _d = moment.duration(_ms);
    const _s = Math.floor(_d.asHours()) + moment.utc(_ms).format(":mm");

    return `${_s}`;
  }

  return `${s}`;
};

export const timeAddAsTimeFormat = (timeA: string, timeB: string) => {
  return moment(timeA, "HH:mm").add(timeB).format("HH:mm");
};

export const timeDiffInMinutes = (endTime: string, startTime: string) => {
  const _dateEnd = moment(endTime, "HH:mm");
  const _dateStart = moment(startTime, "HH:mm");

  const timeDiff = _dateEnd.diff(_dateStart);
  const dura: any = moment.duration(timeDiff);
  return parseInt(dura.asMinutes());
};

// const useConvertFromGetElementByIdToUseRef = (id: string) => {
//   const node = React.useRef(document.getElementById(id));

//   React.useEffect(() => {
//     const el = document.getElementById(id);
//     if (el) node.current = el;
//   }, [id]);
//   return node;
// };

// export function useOnScreenByID(id: string) {
//   const refNode = useConvertFromGetElementByIdToUseRef(id);
//   return useOnScreen(refNode);
// }

// export function useOnScreen(ref: React.MutableRefObject<HTMLElement | null>) {
//   const [isIntersecting, setIntersecting] = React.useState(false);

//   const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

//   React.useEffect(() => {
//     ref.current && observer.observe(ref.current);
//     // Remove the observer as soon as the component is unmounted
//     return () => {
//       observer.disconnect();
//     };
//   }, []);

//   return isIntersecting;
// }
