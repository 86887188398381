const decodeHtmlDOM = (html: any) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

/**
 * Convert a string to HTML entities
 */
export const htmlEncode = async (aString: any) => {
  return aString.replace(/./gm, function (s: any) {
    return s.match(/[a-z0-9\s]+/i) ? s : "&#" + s.charCodeAt(0) + ";";
  });
};

/**
 * Create string from HTML entities
 */
export const decodeHTML = (htmlEntities: any) => {
  const dec = (htmlEntities + "").replace(/&#\d+;/gm, function (s: any) {
    return String.fromCharCode(s.match(/\d+/gm)[0]);
  });
  return decodeHtmlDOM(dec);
};
