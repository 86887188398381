// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import NProgressComponent from "../../Components/NProgress/NProgressComponent";

// Types
import { RouteType } from "./types/RouteTypes";

// Metadata
import { indexPageRoutesMetadata } from "./Index/indexMetaData";
import { settingsPageRoutesMetadata } from "./Settings/settingsMetadata";
import { backupDataPageRoutesMetadata } from "./Preferences/BackupData/BackupDataMetadata";
import { mediasPageRoutesMetadata } from "./Preferences/Medias/mediasMetaData";
import { questionsPageRoutesMetadata } from "./Questions/questionsMetaData";
import { taskManagerPageRoutesMetadata } from "./TaskManager/taskManagerMetaData";
import { knowledgePageRoutesMetadata } from "./Knowledge/knowledgeMetaData";
import { projectsPageRoutesMetadata } from "./Projects/projectsMetaData";
import { palettesPageRoutesMetadata } from "./Apps/Palettes/palettesMetaData";
import { postsPageRoutesMetadata } from "./Posts/postsMetaData";
import { quotesPageRoutesMetadata } from "./Quotes/quotesMetaData";

// Routes
import IndexPageRoutes from "./Index/IndexPageRoutes";
import SettingsPageRoutes from "./Settings/SettingsPageRoutes";
import BackupDataPageRoutes from "./Preferences/BackupData/BackupDataPageRoutes";
import MediasPageRoutes from "./Preferences/Medias/mediasRoutes";
import TaskManagerPageRoutes from "./TaskManager/taskManagerRoutes";
import KnowledgePageRoutes from "./Knowledge/knowledgeRoutes";
import QuestionsPageRoutes from "./Questions/questionsRoutes";
import ProjectsPageRoutes from "./Projects/projectsRoutes";
import PalettesPageRoutes from "./Apps/Palettes/PalettesRoutes";
import PostsPageRoutes from "./Posts/PostsRoutes";
import QuotesPageRoutes from "./Quotes/questionsRoutes";

const RoutesComponent: React.FC = () => {
  return (
    <React.Suspense fallback={<NProgressComponent />}>
      <Routes>
        <Route path="/*" element={<IndexPageRoutes />} />
        <Route path="/posts/*" element={<PostsPageRoutes />} />
        <Route path="/settings/*" element={<SettingsPageRoutes />} />
        <Route path="/preferences/backup-data/*" element={<BackupDataPageRoutes />} />
        <Route path="/preferences/medias/*" element={<MediasPageRoutes />} />
        <Route path="/task-manager/*" element={<TaskManagerPageRoutes />} />
        <Route path="/knowledges/*" element={<KnowledgePageRoutes />} />
        <Route path="/projects/*" element={<ProjectsPageRoutes />} />
        <Route path="/questions/*" element={<QuestionsPageRoutes />} />
        <Route path="/quotes/*" element={<QuotesPageRoutes />} />
        <Route path="/apps/palettes/*" element={<PalettesPageRoutes />} />
        <Route path="*" element={<div>NotFound</div>} />
      </Routes>
    </React.Suspense>
  );
};

export const routes: RouteType[] = [
  ...indexPageRoutesMetadata,
  ...settingsPageRoutesMetadata,
  ...backupDataPageRoutesMetadata,
  ...mediasPageRoutesMetadata,
  ...taskManagerPageRoutesMetadata,
  ...knowledgePageRoutesMetadata,
  ...questionsPageRoutesMetadata,
  ...projectsPageRoutesMetadata,
  ...palettesPageRoutesMetadata,
  ...postsPageRoutesMetadata,
  ...quotesPageRoutesMetadata,
];

export default RoutesComponent;
