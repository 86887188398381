// modules
import React from "react";

// Loader
import LoadingComponent from "../Controller/Loader/LoadingComponent";

// Components
// Global
import DisplayError from "../../Components/Error/ErrorComponent";
// Children
import LayoutComponent from "./LayoutComponent";

// Hooks
import { useQuerySetting } from "../../Components/Form/Input/SettingInput/hooks/useQuerySetting";

// Providers
import { LayoutProvider } from "./Context/LayoutProvider";
import { layout_setting_key } from "./Context/LayoutStates";

interface LayoutContainerProps {
  children: any;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({ children }) => {
  const { data, loading, error } = useQuerySetting(layout_setting_key);

  const defaultLayoutValue: any = data && data.value;

  if (loading) {
    <LoadingComponent loadingUniqueReference="660176a2-c4e0-4c8b-a647-97ec7e7558e6" message={`setting layout`} />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="f1db0330-83d0-4252-8396-9d07a0fe1f12" error={error} />;
  }



  if (defaultLayoutValue) {


    return (
      <LayoutProvider defaultLayoutValue={defaultLayoutValue}>
        <LayoutComponent>{children}</LayoutComponent>
      </LayoutProvider>
    );
  } else {
    return null;
  }
};

export default LayoutContainer;
