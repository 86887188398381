import React from "react";
import RenderTags from "./RenderTags/RenderTags";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface TagsInputProps {
  tags: string;
  setTags: any;
  disabled?: boolean;
}

const TagsInput: React.FC<TagsInputProps> = ({ tags, setTags, disabled }) => {
  // console.log("tags", tags);

  const [editing, setEditing] = React.useState(false);
  const tagInputRef = React.useRef<any>(null);

  const goEditCond = tags === "" || editing;

  const tagsAsArray = (tags && tags.trim() !== "" && removeExtraSpaces(tags).split(",")) || [];

  React.useEffect(() => {
    if (editing) {
      tagInputRef.current.focus();
    }
  }, [editing]);

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      <div
        className={`tags-input${goEditCond ? " no-edit" : ""}${disabled ? " disabled" : ""}`}
        onBlur={() => {
          setEditing(false);
          const afterT = removeExtraSpaces(tags);
          setTags(afterT);
        }}
        onClick={() => {
          setEditing(true);
        }}
      >
        <div className={`tag-input-preview${goEditCond ? " no-edit" : ""}${disabled ? " disabled" : ""}`}>
          {goEditCond && (
            <textarea
              ref={tagInputRef}
              rows={1}
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
              }
              id="tags"
            />
          )}

          {!goEditCond && <RenderTags tagsAsArray={tagsAsArray} />}
        </div>
      </div>
    </>
  );
};

const removeExtraSpaces = (str: string): any => {
  const asArray = str.replace(",,", ",").replace(/\s\s+/g, " ").split(",");
  let newStr = "";
  asArray.forEach((el, index) => {
    const extraSpace = asArray.length === index || index === 0 ? "" : ", ";
    if (el !== "," && el !== " " && el !== ", " && el !== " ,") newStr += `${extraSpace}${el}`;
  });

  newStr = newStr.trim();
  if (newStr.includes(", ,")) {
    return removeExtraSpaces(newStr);
  } else {
    return newStr;
  }
};
export default TagsInput;
