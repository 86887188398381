import { useMutation } from "@apollo/client";
import React from "react";
import { CREATE_MEDIA, CREATE_MULTIPLE_MEDIA, DELETE_ALL_MEDIA } from "../../../../apollo/graphql/media";
import { BATCH_UPLOAD_SOURCE } from "../../../../apollo/graphql/source";
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import { encryptObject } from "../../../../lib/crypt";
import Button from "../../../Button/Button";
import DisplayError from "../../../Error/ErrorComponent";
import Icon from "../../../Icon";
import { HostContext } from "../../context/HostContext";
import { getUploadBatchStateMsg } from "../../functions/getUploadBatchStateMsg";
import { batchUploadMedia } from "./functions/batchUploadMedia";

interface UploadInputFullModeProps {
  folder: string;
  relatedTableId: string;
  mediasIds: string[];
}

const UploadInputFullMode: React.FC<UploadInputFullModeProps> = ({ folder, relatedTableId, mediasIds }) => {
  const hosts: any = React.useContext(HostContext);

  const [files, setFiles] = React.useState<FileList>();
  const [uploadBatchState, setUploadBatchState] = React.useState<any>(-1);
  const [uploadLoader, setUploadLoader] = React.useState(false);

  const [createMediaMutation, { loading: createMediaLoading, error: createMediaError }] = useMutation(CREATE_MEDIA, {
    refetchQueries: ["MEDIAS_IDS"],
    fetchPolicy: "no-cache",
  });

  const [deleteAllMediaMutation, { loading: deleteAllMediaLoading, error: deleteAllMediaError }] = useMutation(
    DELETE_ALL_MEDIA,
    {
      refetchQueries: ["MEDIAS_IDS"],
      fetchPolicy: "no-cache",
    }
  );

  const [createMultipleMediaMutation, { loading: createMultipleMediaLoading, error: createMultipleMediaError }] =
    useMutation(CREATE_MULTIPLE_MEDIA, {
      refetchQueries: ["MEDIAS_IDS"],
      fetchPolicy: "no-cache",
    });

  const [batchUploadSourceMutation, { loading: batchUploadSourceLoading, error: batchUploadSourceError }] = useMutation(
    BATCH_UPLOAD_SOURCE,
    {
      refetchQueries: ["SOURCES_IDS"],
      fetchPolicy: "no-cache",
    }
  );

  const _loading =
    createMediaLoading || deleteAllMediaLoading || createMultipleMediaLoading || batchUploadSourceLoading;

  if (_loading) {
    <LoadingComponent loadingUniqueReference="c25f9357-6c51-439a-b626-745e0ebc0a70" message="Media" />;
  }

  const allErrors: any = createMediaError || deleteAllMediaError || createMultipleMediaError || batchUploadSourceError;
  if (allErrors) {
    return <DisplayError errorUniqueReference="d4658d2c-b7c3-49ce-a2f2-a388c46657e7" error={allErrors} />;
  }

  return (
    <div>
      <div className="flex-center mb16 panel">
        <input
          multiple
          onChange={(e) => {
            const form_file = e.target.files;
            if (form_file) {
              // console.log(form_file);
              setFiles(form_file);
            }
          }}
          type="file"
        />
        <Button
          className="mt16"
          disabled={files && uploadBatchState === -1 ? false : true}
          onClick={() =>
            batchUploadMedia({
              files,
              setUploadLoader,
              setUploadBatchState,
              deleteAllMediaMutation,
              relatedTableId,
              folder,
              createMultipleMediaMutation,
              hosts,
              batchUploadSourceMutation,
            })
          }
        >
          <Icon name={uploadLoader ? "upload" : "plus"} /> &nbsp;
          {getUploadBatchStateMsg(uploadBatchState)}
        </Button>
      </div>
      <div className="file-preview">
        {files &&
          Array.from(Array(files.length).keys()).map((fileIndex) => (
            <div className="file-preview-container" key={fileIndex}>
              <div className="flie-preview-title">
                File preview ({fileIndex + 1} / {files.length}):
              </div>
              <div className="file-preview-image-container">
                <img alt={`batch upload`} src={URL.createObjectURL(files[fileIndex])} />
              </div>
            </div>
          ))}
      </div>
      <div className="add-media-container">
        <Button
          onClick={() =>
            createMediaMutation({
              variables: {
                challenge: encryptObject({
                  folder,
                  relatedTableId,
                  index: mediasIds.length,
                  alt: `Post preview ${mediasIds.length + 1}`,
                }),
              },
            })
          }
        >
          <Icon name="add" /> &nbsp; Add media
        </Button>
      </div>
    </div>
  );
};

export default UploadInputFullMode;
