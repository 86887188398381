import React from "react";
import { encryptObject } from "../../lib/crypt";
import MapKnowledges from "./MapKnowledges/MapKnowledges";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

const KnowledgesMap: React.FC = () => {
  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div className="knowledges-map">
        <div className="flex-column">
          <div className="flex-py-2">
            <div className="flex-pr-4">Add child:</div> <div className="keyboard-shortcut">ctrl</div>{" "}
            <p>&nbsp;+&nbsp;</p>
            <div className="keyboard-shortcut">click</div>
          </div>
          <div className="flex-py-2">
            <div className="flex-pr-4">Edit:</div> <div className="keyboard-shortcut">Double Click</div>
          </div>
          <div className="flex-py-2">
            <div className="flex-pr-4">Open childrens:</div> <div className="keyboard-shortcut">Click</div>
          </div>
        </div>
        <MapKnowledges
          challenge={encryptObject({
            parentId: `''`,
            type: "domain",
          })}
        />
      </div>
    </>
  );
};

export default KnowledgesMap;
