import { gql } from "@apollo/client";

export const TASKS = gql`
  query TASKS($challenge: String!) {
    tasks(challenge: $challenge)
  }
`;

export const CREATE_TASK = gql`
  mutation CREATE_TASK($challenge: String!) {
    createTask(challenge: $challenge)
  }
`;

export const UPDATE_TASK = gql`
  mutation UPDATE_TASK($challenge: String!) {
    updateTask(challenge: $challenge)
  }
`;

export const DELETE_TASK = gql`
  mutation DELETE_TASK($challenge: String!) {
    deleteTask(challenge: $challenge)
  }
`;
