import axios from "axios";
import { config } from "../../../../../config";
import asyncForEach from "../../../../../lib/asyncForeach";
import { decryptObject, encryptObject } from "../../../../../lib/crypt";
import { getFileType } from "../../../functions/getFileType";
import { getUploadBatchStateMsg } from "../../../functions/getUploadBatchStateMsg";
import { HostType } from "../../../types/host";

const debug = true;

interface batchUploadMediaProps {
  files: FileList | undefined;
  setUploadLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadBatchState: React.Dispatch<any>;
  deleteAllMediaMutation: any;
  relatedTableId: string;
  folder: string;
  createMultipleMediaMutation: any;
  hosts: any;
  batchUploadSourceMutation: any;
}

export const batchUploadMedia = async ({
  files,
  setUploadLoader,
  setUploadBatchState,
  deleteAllMediaMutation,
  relatedTableId,
  folder,
  createMultipleMediaMutation,
  hosts,
  batchUploadSourceMutation,
}: batchUploadMediaProps) => {
  if (files) {
    setUploadLoader(true);

    debug && console.log(`1- delete all medias`);

    // 1- delete all medias
    setUploadBatchState(1);
    debug && console.log(`1- ${getUploadBatchStateMsg(1)}`);

    const resp: any = await deleteAllMediaMutation({
      variables: {
        challenge: encryptObject({
          relatedTableId,
          folder,
        }),
      },
    });

    if (
      resp &&
      resp.data &&
      resp.data.deleteAllMedia &&
      decryptObject(resp.data.deleteAllMedia) &&
      decryptObject(resp.data.deleteAllMedia).msg === "deleted"
    ) {
      setUploadBatchState(2);
      debug && console.log(`2- ${getUploadBatchStateMsg(2)}`);

      // 2- create x media, with x = nbr files to uploads
      setUploadBatchState(3);
      debug && console.log(`3- ${getUploadBatchStateMsg(3)}`);

      const respDAM: any = await createMultipleMediaMutation({
        variables: {
          challenge: encryptObject({
            mediaLength: files.length,
            relatedTableId,
            folder,
          }),
        },
      });

      if (
        respDAM &&
        respDAM.data &&
        respDAM.data.createMultipleMedia &&
        decryptObject(respDAM.data.createMultipleMedia) &&
        decryptObject(respDAM.data.createMultipleMedia).mediaList
      ) {
        setUploadBatchState(4);
        debug && console.log(`4- ${getUploadBatchStateMsg(4)}`);

        const mediaList = decryptObject(respDAM.data.createMultipleMedia).mediaList;
        debug && (await console.log("mediaList => ", mediaList));

        console.log(mediaList.filter((el: any) => el.type === "image"));

        await asyncForEach(mediaList, async (mediaId: any, mediaIndex: number) => {
          // 5- foreach host type add multiple source
          setUploadBatchState(5);
          debug && console.log(`5- ${getUploadBatchStateMsg(5)}`);

          let newSources: any = [];

          const mediaTypeToUpload = getFileType(files[mediaIndex]);
          console.log(mediaTypeToUpload);

          await asyncForEach(
            hosts.filter((h: any) => h.type.toUpperCase() === mediaTypeToUpload.toUpperCase()),
            async (host: HostType, index: number) => {
              let body = new FormData();

              body.append("buffer_file", files[mediaIndex]);
              body.append(
                "parentInfo",
                encryptObject({
                  id: mediaId,
                  folder,
                })
              );

              const response_api: any = await axios({
                method: "post",
                url: `${config.PEP}/api/${mediaTypeToUpload}/${host.name}/upload`,
                data: body,
              });
              // console.log(response_api.data);

              await newSources.push({
                src: response_api.data.link,
                hostId: host.id,
              });
              const messageToSet1 = ` Upload source ${host.name} ${index + 1}/${hosts.length} of media ${
                mediaIndex + 1
              }/${mediaList.length}`;
              await setUploadBatchState(messageToSet1);
              debug && console.log(messageToSet1);
            }
          );

          if (newSources.length > 0) {
            const messageToSet2 = ` batch Upload Source for media ${mediaIndex + 1}/${mediaList.length}`;
            await setUploadBatchState(messageToSet2);
            debug && console.log(messageToSet2);

            await batchUploadSourceMutation({
              variables: {
                challenge: encryptObject({
                  folder,
                  newSources,
                  mediaId: mediaId,
                }),
              },
            });
          }
        });
        await setUploadBatchState(7);
        debug && console.log(`7- ${getUploadBatchStateMsg(7)}`);
      }
    }

    await setUploadBatchState(100);

    debug && console.log(`100%- ${getUploadBatchStateMsg(100)}`);
    setTimeout(() => {
      setUploadBatchState(-1);
      debug && console.log(`-1 reset`);
    }, 3000);
  }
};
