import { EntityDataType, EntityColumnType } from "../../../types/Entity";

interface entitySetterProps {
  columnKeyToGet: string;
  value: any;
  entityState: any;
  setEntityState: any;
  dataTypeEditable: EntityDataType;
}

export const entitySetter = ({
  columnKeyToGet,
  value,
  entityState,
  setEntityState,
  dataTypeEditable,
}: entitySetterProps) => {
  const columnTypeEditable: EntityColumnType = dataTypeEditable.filter((el) => el.key === columnKeyToGet)[0];
  let _entityState = { ...entityState };

  switch (columnTypeEditable.type) {
    case "string":
    case "date":
      _entityState[columnKeyToGet] = `${value.toString()}`;
      setEntityState(_entityState);
      break;
  }
};
