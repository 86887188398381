import { gql } from "@apollo/client";

const Entity = (entity: any) => entity.charAt(0).toUpperCase() + entity.slice(1); // "Entity"

export const mutation = (entity: string, operation: string) => ({
  operation: gql`
    mutation ${operation.toUpperCase()}_${entity.toUpperCase()}($challenge: String!) {
      ${operation}${Entity(entity)}(challenge: $challenge)
    }
  `,
  operationName: `${operation}${Entity(entity)}`,
});

export const mutationOpertaion = (entity: string, opertaion: string) => ({
  [opertaion]: mutation(entity, `${opertaion}`),
});
