// Modules
import React from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface ContextMenuProps {
  data: any;
  actions: any;
}

const defaultCoord = { x: -99, y: -99 };

type TExtendedProps = ContextMenuProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ContextMenu: React.FC<TExtendedProps> = ({ data, actions, ...props }) => {
  const { position } = data;
  const { x, y } = position;
  const [active, setActive] = React.useState(position !== defaultCoord);
  const contextMenuRef = React.useRef(null);

  React.useEffect(() => {
    if (active) {
      disableBodyScroll(contextMenuRef as any);
    } else {
      clearAllBodyScrollLocks();
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [active]);

  React.useEffect(() => {
    if (position !== defaultCoord) {
      disableBodyScroll(contextMenuRef as any);
      setActive(true);
    } else {
      setActive(false);
    }
    return () => {
      setActive(false);
    };
  }, [position]);

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>
      {active && (
        <div className="timeline-item-menu" onClick={() => setActive(false)} {...props} ref={contextMenuRef}>
          <div
            className="menu-wrapper"
            style={{
              marginLeft: `${x}px`,
              marginTop: `${y}px`,
            }}
          >
            <div className="menu">
              {actions.map(({ name, onClick }: any, index: number) => (
                <div
                  key={`${name}-${index}`}
                  className="menu__menu-item"
                  onClick={() => {
                    setActive(false);
                    onClick();
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContextMenu;
