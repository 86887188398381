import { useQuery } from "@apollo/client";
import React from "react";
import { KNOWLEDGE } from "../../../apollo/graphql/knowledge";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import { decryptObject, encryptObject } from "../../../lib/crypt";
import { decodeHTML } from "../../../lib/decodeHTML";
import DisplayError from "../../Error/ErrorComponent";
import TextInput from "../../Form/Input/TextInput/TextInput";

interface InputPreservedIdProps {
  id: string;
  silentMode?: boolean;
  inputBorderRadius?: string;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputPreservedIdInner: React.FC<InputPreservedIdProps> = ({
  id,
  setSearching,
  inputBorderRadius,
  silentMode = false,
}) => {
  // console.log("id => ", decryptObject(id));

  const { data, error, loading } = useQuery(KNOWLEDGE, {
    variables: { challenge: id },
    fetchPolicy: "no-cache",
  });

  if (!silentMode && loading) {
    return <LoadingComponent loadingUniqueReference="dabd82b1-a01e-4b23-a8e7-e0d4a30cdf63" message="KNOWLEDGE" />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="018ab614-6400-4c01-a914-ec239b0989ca" error={error} />;
  }

  // console.log("QueryKnowledge [knowledge] => ", knowledge);

  if (data && data.knowledge) {
    const knowledge = decryptObject(data.knowledge).knowledge;
    if (knowledge && knowledge.id) {
      // console.log("QueryKnowledge [knowledge] => ", knowledge);

      return (
        <TextInput
          style={
            inputBorderRadius
              ? {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }
              : {}
          }
          type="search"
          readOnly={true}
          defaultValue={decodeHTML(knowledge.title)}
          onClick={() => setSearching(true)}
          pattern=".{1,128}"
          required
          title="1 to 128 characters"
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const InputPreservedId: React.FC<InputPreservedIdProps> = ({ id, setSearching, inputBorderRadius, silentMode }) => {
  const [_challenge, setChallenge] = React.useState("");

  React.useEffect(() => {
    if (id) {
      setChallenge(encryptObject({ id }));
    } else {
      setChallenge("");
    }
    return () => {
      setChallenge("");
    };
  }, [id]);

  if (_challenge !== "") {
    return <InputPreservedIdInner silentMode={silentMode} id={_challenge} setSearching={setSearching} inputBorderRadius={inputBorderRadius} />;
  } else {
    return null;
  }
};

export default InputPreservedId;
