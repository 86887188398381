// Types
import { RouteType, RouteTypeWithElement } from "../../types/RouteTypes";

// Routes
import ServicesPageRoutes from "./ServicesPageRoutes";

// Pages
import GitHubSettingsPage from "../../../../pages/Settings/ServicesPage/GitHubSettingsPage";
import ImgBBSettingsPage from "../../../../pages/Settings/ServicesPage/ImgBBSettingsPage";
import ServicesSettingsPage from "../../../../pages/Settings/ServicesPage/ServicesSettingsPage";

export const servicesPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Services Settings Page",
    name: "services-settings-page",
    absolutePath: "/settings/services",
    path: "services",
    Element: ServicesSettingsPage,
    children: [
      {
        title: "ImgBB Service Setting Page",
        name: "img-bb-service-setting-page",
        absolutePath: "/settings/services/img-bb",
        path: "img-bb",
        Element: ImgBBSettingsPage,
      },
      {
        title: "Github Service Setting Page",
        name: "github-service-setting-page",
        absolutePath: "/settings/services/github",
        path: "github",
        Element: GitHubSettingsPage,
      },
    ],
    ChildrenRoutes: ServicesPageRoutes,
  },
];

export const servicesPageRoutesMetadata: RouteType[] = servicesPageRoutesWithElementMetadata.map(
  ({ Element, ...rest }) => rest
);
