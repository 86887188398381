// modules
import React from "react";

// Components
import LoadingComponent from "../../../../../App/Controller/Loader/LoadingComponent";
import DisplayError from "../../../../../Components/Error/ErrorComponent";
import TaskComponent from "./TaskComponent/TaskComponent";

// Functions
import { getOverlapping } from "./functions/getOverlapping";
import { useQueryTasks } from "./hooks/useQueryTasks";
import { ITaskManagerContext, TaskManagerContext } from "../../../context";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface RenderTasksProps {
  setTaskToEdit: any;
}

const RenderTasks: React.FC<RenderTasksProps> = ({ setTaskToEdit }) => {
  const {
    taskManagerStore: {
      server: { currentTimelineDate },
    },
  } = React.useContext<ITaskManagerContext>(TaskManagerContext);

  const { error, loading, data } = useQueryTasks(currentTimelineDate);

  if (loading) {
    <LoadingComponent loadingUniqueReference="f7b500bc-1199-4283-988b-3bab572d5e2f" message={`tasks from server`} />;
  }

  if (error) {
    return <DisplayError errorUniqueReference="d79abb8d-9726-482c-bb76-7342e9700951" error={error} />;
  }

  if (data) {
    if (data.length > 0) {
      const tasks = [...data];

      const sortedTasksWithDuration = tasks.sort((a, b) => {
        if (a.duration > b.duration) {
          return -1;
        }
        if (a.duration < b.duration) {
          return 1;
        }
        return 0;
      });

      let { normalTasksList, overlapedTasksList } = getOverlapping(sortedTasksWithDuration);

      return (
        <>
          <React.Suspense fallback={null}>
            <Styling />
          </React.Suspense>
          <div className="render-tasks" id="task-renderer">
            <div className="wrapper">
              {normalTasksList && normalTasksList.length > 0 && (
                <div className="normal-tasks-list">
                  {normalTasksList.map((task: any) => (
                    <div key={task.id} onClick={() => setTaskToEdit(task)}>
                      <TaskComponent type="normal" task={task} />
                    </div>
                  ))}
                </div>
              )}
              {overlapedTasksList &&
                overlapedTasksList.length > 0 &&
                overlapedTasksList.map((doubleTask: any) => (
                  <div className="double-tasks-list" key={doubleTask.uuid}>
                    {doubleTask.tasks.map((innerTasks: any, index: any) => (
                      <div key={innerTasks.id} onClick={() => setTaskToEdit(innerTasks)}>
                        <TaskComponent
                          type="overlaped"
                          task={doubleTask.tasks[index]}
                          overlapedLength={doubleTask.tasks.length}
                          index={index}
                        />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </>
      );
    } else {
    }
  } else {
    return (
      <DisplayError
        errorUniqueReference="42267599-b8e1-4626-ac92-bf51d83d8fc4"
        error={Error("Something wrong on 'useQueryTasks'")}
      />
    );
  }

  // console.log(error, loading, data);

  return null;
};

export default RenderTasks;
