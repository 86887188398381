import Swal from "sweetalert2";
import { decryptObject, encryptObject } from "../../../../../lib/crypt";
import { onError, successToast } from "../../../../../constant/customToast";

export const onSaveMedia = async (
  mode: any,
  loading: any,
  createOrUpdateMedia: any,
  question: any,
  onClose: any,
  onClearForm: any,
  onRedirectToList: any
) => {
  if (!loading && createOrUpdateMedia !== null) {
    if (question.title.length > 128 || question.title.length < 1) {
      return Swal.fire({
        icon: "error",
        title: `Error on 'title' field`,
        html: `Please match the requested format: <strong>1 to 128 characters</strong>`,
      });
    }

    if (question.name.length > 128 || question.name.length < 1) {
      return Swal.fire({
        icon: "error",
        title: `Error on 'name' field`,
        html: `Please match the requested format: <strong>1 to 128 characters</strong>`,
      });
    }

    const { data } = await createOrUpdateMedia({
      variables: {
        challenge: encryptObject({ ...question }),
      },
    });

    if (!data) {
      onError(`something went wrong on mutate !data`);
    }

    console.log(mode);

    const { msg } = decryptObject(data[`${mode}Media`]);

    if (!msg) {
      onError(`something went wrong on mutate ${question.title}`);
    }

    if (msg === `${mode}d`) {
      successToast(`weve ${mode}d the question ${question.title} for you`);
      mode === "update" && onClose();
    }

    if (mode === "create") {
      onClearForm();
    }

    onRedirectToList();
  }

  return;
};
