// Modules
import { useQuery } from "@apollo/client";

// Functions
import { decryptObject } from "../../../../lib/crypt";

// Queries
import { HOSTS } from "../../../../apollo/graphql/host";

export const useQueryHosts = (challenge: any) => {
  const { data, error, loading } = useQuery(HOSTS, {
    variables: { challenge },
  });

  if (loading) return { error: null, loading, data: null };

  if (error) return { error: error.message, loading: false, data: null };

  if (data && data.hosts) {
    const hosts = decryptObject(data.hosts).hosts;

    if (decryptObject(data.hosts).hosts) {
      return { error: null, loading: false, data: [...hosts] };
    } else {
      return { error: "error 41534486", loading: false, data: null };
    }
  } else {
    return { error: "error 5646743", loading: false, data: null };
  }
};
