// Modules
import { useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const useCreatePortalInBody = (attr = "data-body-portal") => {
  const wrapperRef = useRef<any>(null);
  if (wrapperRef.current === null && typeof document !== "undefined") {
    const div = document.createElement("div");
    div.setAttribute("uuid", `${attr}`);
    wrapperRef.current = div;
  }
  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper || typeof document === "undefined") {
      return;
    }
    document.body.appendChild(wrapper);
    return () => {
      document.body.removeChild(wrapper);
    };
  }, []);

  return (children: any) => wrapperRef.current && createPortal(children, wrapperRef.current);
};
