import React from "react";

interface NoContentIllustrationProps {
  hint?: string;
}
const NoContentIllustration: React.FC<NoContentIllustrationProps> = ({ hint }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "32px",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingBlock: "200px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>No content available</p>
          <b>{hint}</b>
        </div>
      </div>
    </div>
  );
};

export default NoContentIllustration;
