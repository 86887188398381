import React from "react";
import QueryTaskManagerSettings from "./container/QueryTaskManagerSettings";

interface TaskManagerEntypointProps {}

const TaskManagerEntypoint: React.FC<TaskManagerEntypointProps> = () => {
  return <QueryTaskManagerSettings />;
};

export default TaskManagerEntypoint;
