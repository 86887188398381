import { onError, successToast } from "../../../constant/customToast";
import { decryptObject, encryptObject } from "../../../lib/crypt";

interface onDeleteEntityHandlerProps {
  id: string;
  operationName: string;
  entityName: string;
  loading: boolean;
  deleteEntity: any;
  onClose: any;
}

export const onDeleteEntityHandler = async ({
  id,
  operationName,
  entityName,
  loading,
  deleteEntity,
  onClose,
}: onDeleteEntityHandlerProps) => {
  if (!loading && deleteEntity !== null) {
    const { data } = await deleteEntity({
      variables: {
        challenge: encryptObject({ id }),
      },
    });

    if (!data) {
      onError(`something went wrong on delete ${entityName} !data`);
    }

    const { msg } = decryptObject(data[operationName]);

    if (!msg) {
      onError(`something went wrong on mutate ${id}`);
    }

    if (msg === `deleted`) {
      successToast(`weve deleted the ${entityName} ${id} for you`);
      onClose();
    }
  }
};
