/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Categories, { emptyCategoryContent } from "../../../../../../Components/Categories/Categories";

interface RenderCategoriesSelectGroupEditorProps {
  get: any;
  setEntityState: any;
}

const RenderCategoriesSelectGroupEditor: React.FC<RenderCategoriesSelectGroupEditorProps> = ({
  get,
  setEntityState,
}) => {
  const domain = get("domain");
  const specification = get("specification");
  const topic = get("topic");

  const setCategory = React.useCallback(
    ({ domainArg, specificationArg, topicArg }: any) =>
      setEntityState((s: any) => ({
        ...s,
        domain: domainArg,
        specification: specificationArg,
        topic: topicArg,
      })),
    []
  );

  const setDomain = React.useCallback(
    (domainArg: any) =>
      setEntityState((s: any) => ({
        ...s,
        domain: domainArg,
        specification: emptyCategoryContent,
        topic: emptyCategoryContent,
      })),
    []
  );

  const setSpecification = React.useCallback(
    (specificationArg: any) =>
      setEntityState((prevCategoryState: any) => ({
        ...prevCategoryState,
        specification: specificationArg,
        topic: emptyCategoryContent,
      })),
    []
  );

  const setTopic = React.useCallback(
    (topicArg: any) => setEntityState((prevCategoryState: any) => ({ ...prevCategoryState, topic: topicArg })),
    []
  );

  return (
    <div
      className="panel"
      style={{
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Categories
        setCategory={setCategory}
        domain={domain}
        setDomain={setDomain}
        specification={specification}
        setSpecification={setSpecification}
        topic={topic}
        setTopic={setTopic}
      />
    </div>
  );
};

export default RenderCategoriesSelectGroupEditor;
