import { decryptObject, encryptObject } from "../../../../../lib/crypt";
import { onError, successToast } from "../../../../../constant/customToast";

export const onSaveTask = async (loading:any, createOrUpdateTask:any, task:any, onClose:any, mode:any) => {
  if (!loading && createOrUpdateTask !== null) {
    const { data } = await createOrUpdateTask({
      variables: {
        challenge: encryptObject({ ...task }),
      },
    });

    if (!data) {
      onError(`something went wrong on mutate !data`);
    }

    const { msg } = decryptObject(data[`${mode}Task`]);

    if (!msg) {
      onError(`something went wrong on mutate ${task.title}`);
    }

    if (msg === `${mode}d`) {
      successToast(`weve ${mode}d the task ${task.title} for you`);
      onClose();
    }
  }
};
