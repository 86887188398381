import React from "react";
import Button from "../../../../../../Components/Button/Button";
import Icon from "../../../../../../Components/Icon";

import useMediaQuery from "../../../../../../hooks/useMediaQuery";
import { ITaskManagerContext, TaskManagerContext } from "../../../../context";
import { hexToRgb, timeDiffInMinutes } from "../../../../utils";
import { TIMELINE__HEIGHT, TIMELINE__TIME_HEIGHT } from "../../timelineConstants";
import { getNumberOfParent } from "./functions/getNumberOfParent";

interface TaskComponentProps {
  task: any;
  type: "normal" | "overlaped";
  overlapedLength?: number;
  index?: number;
}
const TaskComponent: React.FC<TaskComponentProps> = ({
  task: { title, bgColor, textColor, startTime, endTime, timeColor, duration, status, date },
  overlapedLength = 0,
  index = 0,
  type,
}) => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  const [minimized, setMinimized] = React.useState(!isMobile);

  const { taskManagerStore } = React.useContext<ITaskManagerContext>(TaskManagerContext);

  const {
    server: { timeZoom },
  } = taskManagerStore;

  const { numberOfParentHeight, numberOfParentTop, approx_start, approx_end } = getNumberOfParent(
    timeZoom,
    startTime,
    endTime
  );

  const addMargFor1MinuteOnly = timeZoom === 1 ? -5 : 0;

  let height = Math.abs(addMargFor1MinuteOnly + TIMELINE__HEIGHT * numberOfParentHeight);

  let top =
    addMargFor1MinuteOnly +
    TIMELINE__HEIGHT * (numberOfParentTop + 1) -
    (TIMELINE__HEIGHT - TIMELINE__TIME_HEIGHT * 0.5);

  // handle the condition form the bottom if numberOfParentHeight = -95
  if (numberOfParentHeight === -95) {
    top = addMargFor1MinuteOnly + TIMELINE__HEIGHT * 1 - (TIMELINE__HEIGHT - TIMELINE__TIME_HEIGHT * 0.5);
  }

  let exactTiming = true;

  if (approx_start !== startTime || approx_end !== endTime) {
    exactTiming = false;
  }

  if (!exactTiming && type === "overlaped") {
    height = Math.abs(addMargFor1MinuteOnly + TIMELINE__HEIGHT * (numberOfParentHeight + 1));

    top =
      addMargFor1MinuteOnly + TIMELINE__HEIGHT * numberOfParentTop - (TIMELINE__HEIGHT - TIMELINE__TIME_HEIGHT * 0.5);

    if (overlapedLength === 2 && index === 1) {
      top =
        addMargFor1MinuteOnly +
        TIMELINE__HEIGHT * (numberOfParentTop + 1) -
        (TIMELINE__HEIGHT - TIMELINE__TIME_HEIGHT * 0.5);
    }
  }

  const rgbBG = hexToRgb(`${bgColor}`);

  const _bg_color = `${rgbBG.r}, ${rgbBG.g}, ${rgbBG.b}`;

  const gradient = `linear-gradient(
        to bottom,
         rgba(${_bg_color}, ${approx_end !== endTime ? "0" : 1}) 0%,
         rgba(${_bg_color}, 1) 20%,
         rgba(${_bg_color}, 1) 80%,
         rgba(${_bg_color}, ${approx_start !== startTime ? "0" : 1}) 100%
    )`;

  const baseLeft = 32;

  const baseWidth = `calc(100% - ${baseLeft * 3.5}px)`;
  const width = overlapedLength > 0 ? `calc((100% - ${baseLeft * 6}px)/(2*${minimized ? 2 : 1}))` : baseWidth;
  const left =
    index > 0
      ? `calc( ( (100% - ${baseLeft}px) / (2*${minimized ? 2 : 1}) ) + ${baseLeft * index}px )`
      : `${baseLeft}px`;

  const taskStyles = {
    color: textColor,
    top: `${top}px`,
    marginLeft: `${left}`,
    width: `${width}`,
    opacity: timeDiffInMinutes(endTime, startTime) >= timeZoom ? "1" : ".5",
    height: `${height}px`,
    maxHeight: `${height}px`,
    minHeight: `${TIMELINE__HEIGHT}px`,
    background: exactTiming ? `rgba(${_bg_color}, 1)` : gradient,
    fontSize: !minimized ? "14px" : "12px",
  };

  return (
    <div className="task" style={taskStyles}>
      <div className="task-wrapper">
        <div style={{ display: "flex" }}>
          <div className="task-status">
            <Icon name={status === "pending" ? "schedule" : "check"} />
          </div>

          <span style={{ color: timeColor }}>
            <p>{endTime}</p>
            <b style={{ color: timeColor }}>
              <em>&nbsp;{duration}&nbsp;</em>
            </b>
            <p>{startTime}</p>
          </span>

          <div className="task-date">
            <b>{date}</b>
          </div>
        </div>
        <b>{title}</b>
        <Button
          colorScheme={bgColor}
          size="sm"
          onClick={() => setMinimized((e) => !e)}
          style={{
            padding: 0,
            margin: 0,
            width: "24px",
            height: "24px",
          }}
        >
          <Icon name={minimized ? "desktop" : "mobile"} />
        </Button>
      </div>
    </div>
  );
};

export default TaskComponent;
