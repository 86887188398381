import React from "react";
import { Outlet } from "react-router-dom";
import { servicesPageRoutesMetadata } from "../../../App/Routes/Settings/Services/servicesMetadata";
import TabComponent from "../../../Components/Tab/Tab";

const ServicesSettingsPage: React.FC = () => {

  const { children, absolutePath } = servicesPageRoutesMetadata[0];

  return (
    <div>
      {children && <TabComponent absolutePath={absolutePath} chlids={children} />} <Outlet />
    </div>
  );
};

export default ServicesSettingsPage;
