export const arrayMove = (_array: any, oldIndex: any, newIndex: any) => {
  while (oldIndex < 0) {
    oldIndex += _array.length;
  }
  while (newIndex < 0) {
    newIndex += _array.length;
  }
  if (newIndex >= _array.length) {
    var k = newIndex - _array.length + 1;
    while (k--) {
      _array.push(undefined);
    }
  }
  _array.splice(newIndex, 0, _array.splice(oldIndex, 1)[0]);
  return _array; // for testing purposes
};
