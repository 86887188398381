import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { UPDATE_TASK_MANAGER_SETTINGS } from "../../../../apollo/graphql/task_manager";
import Button from "../../../../Components/Button/Button";
import { encryptObject } from "../../../../lib/crypt";
import { ITaskManagerContext, TaskManagerContext } from "../../context";
import { getNearestTime } from "../../utils";
import { timeZoomArray } from "./constants";

const SliderMarkZoomTime: React.FC = () => {
  const [updateTaskManagerSettingsMutation, { loading, error }] = useMutation(UPDATE_TASK_MANAGER_SETTINGS, {
    refetchQueries: ["QUERY_TASK_MANAGER_SETTINGS"],
  });

  const { taskManagerStore } = useContext<ITaskManagerContext>(TaskManagerContext);

  const { server } = taskManagerStore;

  const { timeZoom } = server;

  const setTimeZoom = (timeZoomArg: any) => {
    updateTaskManagerSettingsMutation({
      variables: {
        challenge: encryptObject({
          settings: {
            ...server,
            timeZoom: getNearestTime(timeZoomArg),
          },
        }),
      },
    });
  };

  // const factory = () => ({ setTimeZoom, timeZoom });

  // const taskManagerStoreValue = useMemo(factory, [setTimeZoom, timeZoom]);

  // React.useEffect(() => {
  //   const elementToCorrect = getNearestTime(timeZoom);
  //   if (elementToCorrect) {
  //     // console.log("elementToCorrect =>", elementToCorrect);
  //     setTimeZoom(elementToCorrect, setTaskManagerStore, taskManagerStore);
  //   }
  //   return () => {
  //     setTimeZoom(defaultValueTimeZoom, setTaskManagerStore, taskManagerStore);
  //   };
  // }, [timeZoom]);

  if (error) return <div>Error454864834</div>;

  return (
    <div className="horizontal-navigator">
      <h3>Zoom Timeline</h3>
      <div className="group-control mx">
        <p>
          Current Time Zoom: <b className="time-now">{timeZoom}</b>
        </p>
        <hr />
        <div className="btn-group">
          {timeZoomArray.map((el, index) => (
            <div key={`btn-time-zoom-${index}`}>
              <Button
                size="sm"
                className={`${timeZoom === el ? "active" : ""}`}
                // ref={(el) => el && el.style.setProperty("pointer-events", "auto", "important")}
                value={el}
                onClick={() => !loading && setTimeZoom(el)}
              >
                {el}m
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderMarkZoomTime;
