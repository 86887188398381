import React from "react";
import { icons } from "./icons";

interface IconProps {
  size?: string;
  className?: string;
  fill?: string;
  name: string;
}

type TExtendedProps = IconProps & React.SVGProps<SVGSVGElement>;

const Icon: React.FC<TExtendedProps> = ({
  size,
  className,
  fill,
  name,
  ...props
}) => {
  const _size = size ? size : 16;
  const _d = icons[name as keyof typeof icons];
  return (
    <svg
      className={className}
      width={_size}
      height={_size}
      viewBox="0 0 16 16"
      fill={fill}
      {...props}
    >
      {_d && <path d={_d}></path>}
    </svg>
  );
};

export default Icon;
