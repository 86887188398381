import { useMutation } from "@apollo/client";
import React from "react";
import Swal from "sweetalert2";
import { DELETE_KNOWLEDGE, UNLINK_CHILDREN } from "../../../apollo/graphql/knowledge";
import Button from "../../Button/Button";
import Icon from "../../Icon";
import Spinner from "../../Spinner/Spinner";
import { onUnlinkHandler } from "./onUnlinkHandler";

interface UnlinkChildrenProps {
  idToUnlink: string;
  parentId: string;
}

const UnlinkChildren: React.FC<UnlinkChildrenProps> = ({ idToUnlink, parentId }) => {
  //
  const [unlinkChildren, { loading: unlinkChildrenLoading, error: unlinkChildrenError }] = useMutation(
    UNLINK_CHILDREN,
    {
      // refetchQueries: ["KNOWLEDGE"],
      fetchPolicy: "no-cache",
    }
  );

  const [deleteChildren, { loading: deleteChildrenLoading, error: deleteChildrenError }] = useMutation(
    DELETE_KNOWLEDGE,
    {
      // refetchQueries: ["KNOWLEDGE"],
      fetchPolicy: "no-cache",
    }
  );

  // unlink

  if (unlinkChildrenLoading) return <Spinner />;

  if (unlinkChildrenError) {
    Swal.fire({
      icon: "error",
      title: `Error occurred`,
      html: `On unlink ${idToUnlink}`,
    });
  }

  // delete

  if (deleteChildrenLoading) return <Spinner />;

  if (deleteChildrenError) {
    Swal.fire({
      icon: "error",
      title: `Error occurred`,
      html: `On delete ${idToUnlink}`,
    });
  }

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Button
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          height: "40px"
        }}
        disabled={unlinkChildrenLoading}
        onClick={() => onUnlinkHandler(unlinkChildren, idToUnlink, parentId, deleteChildren)}
      >
        <Icon name={!unlinkChildrenLoading ? "close" : "list"} />
      </Button>
    </div>
  );
};

export default UnlinkChildren;
