// Modules
import React from "react";
import { ApolloProvider } from "@apollo/client";

// Configs
import apolloClient from "../../apollo/client";

// Hooks
import { useScript } from "../../hooks/useScript";

// Loader
import LoadingComponent from "./Loader/LoadingComponent";

// Components
/// Children
import AppControllerShield from "./Shield/AppControllerShield";
import AppControllerGuard from "./Guard/AppControllerGuard";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

const AppController: React.FC = () => {
  // darkMode.min.js
  const status = useScript("/98df42a1d5bff0225b58faf035937e89.js");

  if (status === "ready") {
    // Is it temporarily banned from logging in?
    // No worry; if he deletes the entry from local storage, the server will detect that because it is saved on Redis.
    // I created this logic to prevent requesting the server during the denied time.

    const isDenied = localStorage.getItem("ga__54684565");

    return (
      <>
        <React.Suspense
          fallback={
            <LoadingComponent
              loadingUniqueReference="5ac5e6e3-1097-4e71-b01c-5cccc4497327"
              message="global styles (app.css)"
              transparency={1}
            />
          }
        >
          <Styling />
        </React.Suspense>
        <ApolloProvider client={apolloClient}>
          
          {isDenied && <AppControllerShield isDenied={isDenied} />}
          {!isDenied && <AppControllerGuard />}
        </ApolloProvider>
      </>
    );
  } else {
    return (
      <LoadingComponent
        loadingUniqueReference="7e9dd220-9965-479b-abd5-aeaa2e2e941c"
        message="darkMode script"
        transparency={1}
      />
    );
  }
};

export default AppController;
