// Modules
import React from "react";

// Hooks
import { useCreatePortalInBody } from "../../../hooks/useCreatePortalInBody";

// Constants
const activateErrorsReferences = process.env.REACT_APP_ACTIVATE_LOADING_MESSAGES === "true";

interface LoadingComponentProps {
  loadingUniqueReference: string;
  transparency?: number;
  message: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({
  loadingUniqueReference,
  message,
  transparency = 0.461,
}) => {
  const createBodyPortal = useCreatePortalInBody(loadingUniqueReference);

  return createBodyPortal(
    <div
      style={{
        display: "flex",
        position: "fixed",
        zIndex: "1900",
        top: "0",
        left: "0",
        backgroundColor: `rgba(0, 0, 0, ${transparency})`,
        width: "100vw",
        height: "100vh",
        fontSize: "30px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {activateErrorsReferences && <p>Loading {message}</p>}
      <svg
        style={{
          width: "15%",
          height: "15%",
          margin: "20px",
          display: "inline-block !important",
        }}
        version="1.1"
        id="L4"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingComponent;
