import React from "react";
import { disableBodyScroll } from "body-scroll-lock";

import { capitalizeFirstLetter } from "../../lib/string";
import TextInput from "../Form/Input/TextInput/TextInput";
import InputPreservedId from "./InputPreservedId/InputPreservedId";
import SearchBoard from "./SearchBoard/SearchBoard";
import Modal from "../Modal/Modal";

// Styles
const Styling = React.lazy(() => import("./LazyStyle"));

interface SearchBoxProps {
  name: string;
  inputBorderRadius?: string;
  id: string;
  setID: any;
  disabled?: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({ id, name, setID, inputBorderRadius, disabled }) => {
  const [searchText, setSearchText] = React.useState("");
  const [searching, setSearching] = React.useState(false);

  React.useEffect(() => {
    return () => {
      setSearchText("");
      setSearching(false);
    };
  }, []);

  if (searching) {
    disableBodyScroll(document.body);
  } else {
    // clearAllBodyScrollLocks();
  }

  return (
    <>
      <React.Suspense fallback={null}>
        <Styling />
      </React.Suspense>

      <div className="search-box">
        <label className="label-name" htmlFor={`${name}`}>
          {capitalizeFirstLetter(`${name}`)}
        </label>
        {id && <InputPreservedId setSearching={setSearching} id={id} inputBorderRadius={`${inputBorderRadius}`} />}
        {!id && (
          <TextInput
            disabled={disabled}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            type="search"
            readOnly={true}
            onClick={() => setSearching(true)}
            pattern=".{1,128}"
            required
            title="1 to 128 characters"
          />
        )}
        <Modal
          zIndex="1450"
          onClose={() => setSearching(false)}
          maxWidth="100%"
          activeCond={searching}
          Header={<h4>Search {name}</h4>}
        >
          {searching && (
            <SearchBoard
              inputBorderRadius={`${inputBorderRadius}`}
              name={name}
              setID={setID}
              searchText={searchText}
              setSearchText={setSearchText}
              setSearching={setSearching}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default SearchBox;
