import React from "react";
import BasePage from "../Base/BasePage";
import { getCRLUD } from "../Base/operation/get/crlud";
import { questionDataType } from "./questionsDataType";

const crlud = getCRLUD("question", "questions");

const QuestionsPage: React.FC = () => {
  return <BasePage title="Questions" crlud={crlud} entityDataType={questionDataType} />;
};

export default QuestionsPage;
