import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import moment from "moment";
import Switch from "rc-switch";
import React from "react";
import Swal from "sweetalert2";
import { CHANGE_MEDIA_INDEX, DELETE_MEDIA, UPDATE_MEDIA } from "../../../apollo/graphql/media";
import { BATCH_UPLOAD_SOURCE, CREATE_SOURCE, SOURCES_IDS } from "../../../apollo/graphql/source";
import LoadingComponent from "../../../App/Controller/Loader/LoadingComponent";
import { config } from "../../../config";
import { successToast } from "../../../constant/customToast";
import { arrayMove } from "../../../lib/array";
import asyncForEach from "../../../lib/asyncForeach";
import { decryptObject, encryptObject } from "../../../lib/crypt";
import Button from "../../Button/Button";
import DisplayError from "../../Error/ErrorComponent";
import Flex from "../../Flex/Flex";
import TextInput from "../../Form/Input/TextInput/TextInput";
import Icon from "../../Icon";
import { HostContext } from "../context/HostContext";
import { MediaInputlayoutType } from "../MainComponent";
import { HostType } from "../types/host";
import { MediaType } from "../types/media";
import { MediaTypes } from "../types/mediaTypes";
import QuerySource from "./Source/QuerySource";

export type updateMediaType = (
  media: MediaType,
  set_medias?: React.Dispatch<React.SetStateAction<MediaType[]>>
) => void;

const getUploadBatchStateMsg = (uploadBatchState: any) => {
  switch (uploadBatchState) {
    case -1:
      return "batch upload sources";

    case 0:
      return "batch uploading...";

    case 100:
      return "batch upload completed";

    default:
      return `batch upload (${uploadBatchState.toFixed(2)}%)`;
  }
};

interface MediaComponnentProps {
  layout: MediaInputlayoutType;
  media: MediaType;
  mediasIds: any;
  relatedTableId: string;
  isMediasMin: boolean;
  folder: string;
}

const getHostId = (hosts: any, newIndex: any) => {
  // console.log("host ID", hosts[newIndex % hosts.length].id);

  return hosts[newIndex % hosts.length].id;
};

const MediaComponnent: React.FC<MediaComponnentProps> = ({
  media,
  mediasIds,
  relatedTableId,
  isMediasMin,
  folder,
  layout,
}) => {
  // console.log(mediasIds);

  let hosts: any = React.useContext(HostContext);

  const hostsFiltred = hosts.filter((el: any) => el.type.toUpperCase() === media.type.toUpperCase());

  // 👇️ create a ref for the file input
  const inputRef = React.useRef<any>(null);

  const [upload_loader, set_upload_loader] = React.useState(false);
  const [uploadBatchState, setUploadBatchState] = React.useState(-1);
  const [file, set_file] = React.useState<File>();

  const [isActive, setIsActive] = React.useState(media.isActive ? "ON" : "OFF");
  const [alt, setAlt] = React.useState(media.alt);
  const [type] = React.useState(media.type);
  const [__challenge_get_sources_ids] = React.useState(encryptObject({ mediaId: media.id }));

  const {
    data: sourcesIdData,
    error: sourcesIdError,
    loading: sourcesIdLoading,
  } = useQuery(SOURCES_IDS, {
    variables: { challenge: __challenge_get_sources_ids },
    fetchPolicy: "no-cache",
  });

  const [updateMediaMutation, { loading, error }] = useMutation(UPDATE_MEDIA, {
    refetchQueries: ["MEDIA", "SOURCES_IDS"],
  });

  const [changeMediaIndexMutation, { loading: changeMediaIndexLoading, error: changeMediaIndexError }] = useMutation(
    CHANGE_MEDIA_INDEX,
    {
      refetchQueries: ["MEDIAS_IDS"],
    }
  );

  const [deleteMediaMutation, { loading: deleteMediaLoading, error: deleteMediaError }] = useMutation(DELETE_MEDIA, {
    refetchQueries: ["MEDIAS_IDS"],
    fetchPolicy: "no-cache",
  });

  const [createSourceMutation, { loading: createSourceLoading, error: createSourceError }] = useMutation(
    CREATE_SOURCE,
    {
      refetchQueries: ["MEDIAS_IDS"],
      fetchPolicy: "no-cache",
    }
  );

  const [batchUploadSourceMutation, { loading: batchUploadSourceLoading, error: batchUploadSourceError }] = useMutation(
    BATCH_UPLOAD_SOURCE,
    {
      refetchQueries: ["MEDIAS_IDS"],
      fetchPolicy: "no-cache",
    }
  );

  if (uploadBatchState === 100) {
    setTimeout(() => {
      setUploadBatchState(-1);
      // inputRef.current.value = null;
      set_file(undefined);
    }, 1000);
  }

  const updateMediaHandler = async (newUpdates: any) => {
    if (!loading && updateMediaMutation !== null) {
      const { data } = await updateMediaMutation({
        variables: {
          challenge: encryptObject({ ...newUpdates, id: media.id, folder }),
        },
      });
      if (!data) {
        // console.log("something went wrong 01");
      }
      // console.log(data);

      const { msg } = decryptObject(data["updateMedia"]);
      if (!msg) {
        // console.log("something went wrong 02");
      }

      if (msg === "updated") {
        // if (clearForm) {
        //   setStatus(StatusTypes.DRAFT);
        //   setUrl("");
        //   setTitle("");
        //   setSubtitle("");
        //   set_medias(defaultMedia);
        //   setDescription("");
        // }

        successToast("weve updated your media for you");

        // if (redirectToList) {
        //   // navigateTo(`/medias`);
        //   // not working : refetchQueries not working when using string array after mutation #5419
        //   // https://github.com/apollographql/apollo-client/issues/5419
        //   window.location.href = `/medias`;
        // }
      }
    }
  };

  const _loading =
    loading ||
    sourcesIdLoading ||
    createSourceLoading ||
    deleteMediaLoading ||
    changeMediaIndexLoading ||
    batchUploadSourceLoading;

  if (_loading) {
    <LoadingComponent loadingUniqueReference="7c9edde6-f7f5-41b0-b574-bf3e071debb1" message="Media" />;
  }

  const allErrorPossible =
    error || sourcesIdError || createSourceError || deleteMediaError || changeMediaIndexError || batchUploadSourceError;
  if (allErrorPossible) {
    return <DisplayError errorUniqueReference="53589384-6603-4de0-ab75-a42f6a527a23" error={allErrorPossible} />;
  }

  if (sourcesIdData && sourcesIdData.sourcesIds && decryptObject(sourcesIdData.sourcesIds)) {
    const sourcesIds: string[] = decryptObject(sourcesIdData.sourcesIds);

    // console.log("sourcesIds => ", sourcesIds);

    if (!sourcesIds) {
      <LoadingComponent loadingUniqueReference="7c9edde6-f7f5-41b0-b574-bf3e071debb1" message="Media" />;
    }

    return (
      <Flex
        id={`media_n_${media.index + 1}`}
        onMouseEnter={() => {
          if (window.history.pushState) {
            var newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              `?isMediasMin=${isMediasMin ? "true" : "false"}&scroll_into_view=media_n_${media.index + 1}`;

            window.history.pushState({ path: newurl }, "", newurl);
          }
        }}
        w={isMediasMin ? "300px" : "100%"}
        borderColor={media.id ? "rgba(0,0,0,0.6)" : "#c88b8b"}
        className="media-item"
      >
        <Flex jc="space-between">
          {layout === "preview" && <h3>Media N° {media.index + 1}</h3>}
          {layout === "edit" && (
            <p>
              <Icon name={type === MediaTypes.IMAGE ? "image" : ""} /> Media N° {media.index + 1} (#{media.id}) &nbsp;{" "}
              <Switch
                checked={isActive === "ON"}
                onChange={(_checked) => {
                  setIsActive(_checked ? "ON" : "OFF");
                }}
                onBlur={() => {
                  let cmp: string = media.isActive ? "ON" : "OFF";
                  if (isActive !== cmp) {
                    updateMediaHandler({
                      isActive: isActive === "ON" ? true : false,
                    });
                  }
                }}
              />
              &nbsp;&nbsp;|&nbsp;Created At :&nbsp;{" "}
              <span>{moment(media.createdAt).format("dddd DD MMM YYYY - HH:mm")}</span>
              &nbsp;&nbsp;|&nbsp;Updated At :&nbsp;
              <span>
                {media.updatedAt !== media.createdAt ? (
                  moment(media.updatedAt).format("dddd DD MMM YYYY - HH:mm")
                ) : (
                  <Icon color="gray" name="close" />
                )}
              </span>
            </p>
          )}
          {media.index > 0 && layout === "edit" && (
            <Button
              onClick={async () => {
                const result: any = await Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this! " + media.id,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                });

                if (result.isConfirmed) {
                  const { data } = await deleteMediaMutation({
                    variables: {
                      challenge: encryptObject({ id: media.id, relatedTableId }),
                    },
                  });

                  if (!data) {
                    // console.log("something went wrong 01");
                  }
                  // console.log(data);

                  const { msg } = decryptObject(data["deleteMedia"]);
                  if (!msg) {
                    // console.log("something went wrong 02");
                  }

                  if (msg === "deleted") {
                    successToast("weve deleted your media for you");
                  }
                }
              }}
            >
              <Icon name="delete" />
            </Button>
          )}
        </Flex>
        {!isMediasMin && layout === "edit" && (
          <Flex fd="column" w="100%">
            <Flex
              w="100%"
              borderBottomColor="neutral.700"
              borderBottomWidth="1px"
              borderBottomStyle="dashed"
              ai="center"
              py="16px"
            >
              <Flex w="50%">
                <Flex w="100%" ai="center" py="16px">
                  <Flex w="20%">Index :</Flex>
                  <Flex w="80%">
                    {mediasIds.map((_: any, idx: number) => (
                      <Flex
                        key={idx}
                        cursor={media.index === idx ? "not-allowed" : "pointer"}
                        p="16px"
                        m="8px"
                        bg="rgba(0,0,0,0.6)"
                        border={media.index === idx ? "2px dashed black" : "none"}
                        _hover={{ bg: media.index === idx ? "" : "rgba(0,0,0,0.5)" }}
                        onClick={() => {
                          if (media.index !== idx) {
                            const new_order = arrayMove(mediasIds, media.index, idx);
                            // console.log("new_order => ", new_order);
                            changeMediaIndexMutation({
                              variables: {
                                challenge: encryptObject({
                                  new_order,
                                }),
                              },
                            });
                          }
                        }}
                      >
                        {idx + 1}
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
              <Flex w="50%">
                <Flex w="100%" ai="center" py="16px">
                  <Flex w="20%">alt :</Flex>
                  <Flex w="80%">
                    <TextInput
                      value={alt}
                      onChange={(e) => {
                        setAlt(e.target.value);
                      }}
                      onBlur={() => {
                        if (alt !== media.alt) {
                          updateMediaHandler({
                            alt,
                          });
                        }
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        <Flex fd="column" mt="16px">
          {!isMediasMin && layout === "edit" && (
            <>
              <Flex fd="column" my="16px" className="panel">
                <Flex mb="16px">Batch upload sources :</Flex>

                <Flex fd="column">
                  <progress value={uploadBatchState === -1 ? 0 : uploadBatchState} />
                  <Flex ai="center">
                    <input
                      accept={`${media.type}/*`}
                      ref={inputRef}
                      onChange={(e) => {
                        const form_file = e.target.files && e.target.files[0];
                        if (form_file) {
                          // console.log(form_file);
                          set_file(form_file);
                        }
                      }}
                      type="file"
                    />
                    <Button
                      disabled={file && uploadBatchState === -1 ? false : true}
                      onClick={async () => {
                        if (file && hostsFiltred) {
                          set_upload_loader(true);

                          // sourcesIds

                          setUploadBatchState(1);

                          let newSources: any = [];

                          await asyncForEach(hostsFiltred, async (host: HostType, index: number) => {
                            let body = new FormData();
                            body.append("buffer_file", file);
                            body.append("parentInfo", encryptObject({ folder }));

                            const response_api: any = await axios({
                              method: "post",
                              url: `${config.PEP}/api/${media.type}/${host.name}/upload`,
                              data: body,
                            });
                            // console.log(response_api.data);

                            await newSources.push({
                              src: response_api.data.link,
                              hostId: host.id,
                            });

                            await setUploadBatchState(((index + 1) / hostsFiltred.length) * 100);
                          });

                          if (newSources.length > 0) {
                            await batchUploadSourceMutation({
                              variables: {
                                challenge: encryptObject({
                                  newSources,
                                  mediaId: media.id,
                                }),
                              },
                            });
                            // await setTimeout(() => {
                            //   window.location.reload();
                            // }, 300);
                          }
                        }
                      }}
                    >
                      <Icon name={upload_loader ? "upload" : "plus"} />
                      &nbsp;
                      {getUploadBatchStateMsg(uploadBatchState)}
                    </Button>
                  </Flex>
                </Flex>

                {file && (
                  <>
                    <Flex ai="center" py="16px">
                      <Flex w="20%">File preview :</Flex>
                      <Flex w="80%"></Flex>
                    </Flex>
                    <Flex ai="center" py="16px">
                      <img alt={`batch upload`} src={URL.createObjectURL(file)} />
                    </Flex>
                  </>
                )}
              </Flex>
            </>
          )}
          <Flex my="16px">Sources :</Flex>
          {!isMediasMin && layout === "edit" && (
            <Flex mb="16px">
              <Button
                onClick={async () => {
                  await createSourceMutation({
                    variables: {
                      challenge: encryptObject({
                        mediaId: media.id,
                        src: "",
                        hostId: getHostId(hostsFiltred, sourcesIds.length),
                      }),
                    },
                  });
                }}
              >
                <Icon name="plus" /> Add source
              </Button>
            </Flex>
          )}
          <Flex fd="column" border="1px solid red" borderColor="rgba(0,0,0,0.5)" br="6px" p="16px">
            {sourcesIds.map((xSourceId: string, index: number) => (
              <QuerySource
                layout={layout}
                folder={folder}
                key={index}
                index={index}
                hosts={hostsFiltred}
                challenge={encryptObject({ id: xSourceId })}
                isMediasMin={isMediasMin}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    );
  } else {
    return <div>something wrong error code 5467468469</div>;
  }
};

export default MediaComponnent;
