import React from "react";

interface InputProps {}

type TExtendedProps = InputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const Input: React.FC<TExtendedProps> = (props) => {
  return <input className="input" {...props} />;
};

export default Input;
