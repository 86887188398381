import { CrludType } from "../../types/CrludType";
import { mutation } from "../muation";
import { query } from "../query";

export const getCRLUD = (entity: string, entities: string): CrludType => ({
  create: mutation(entity, `create`),
  read: query(entity),
  list: query(entities),
  update: mutation(entity, `update`),
  delete: mutation(entity, `delete`),
});
