// Modules
import React from "react";

// Renderers
import { renderRoutes } from "../../utils/renderRoutes";

// Metadata
import { servicesPageRoutesWithElementMetadata } from "./servicesMetadata";

const ServicesPageRoutes: React.FC = () => {
  return renderRoutes(servicesPageRoutesWithElementMetadata);
};

export default ServicesPageRoutes;
