export const getUploadBatchStateMsg = (uploadBatchState: any) => {
  switch (uploadBatchState) {
    case -1:
      return "Start batch upload medias";

    case 1:
      return "Start delete all medias...";

    case 2:
      return "All medias deleted";

    case 3:
      return "Start create x media, with x = nbr files to uploads";

    case 4:
      return "X media created";

    case 5:
      return "Start foreach host type add multiple source";

    case 6:
      return "All multiple source created";

    case 7:
      return "All multiple source connected to all medias";

    case 100:
      return "Batch upload medias completed";

    default:
      return uploadBatchState;
  }
};
