import moment from "moment";
import React from "react";
import { APP_DATE_FORMAT } from "../../../../lib/date";
import { ITaskManagerContext, TaskManagerContext } from "../../context";

const CurrentTimelineDate: React.FC = () => {
  const {
    taskManagerStore: {
      server: { currentTimelineDate },
    },
  } = React.useContext<ITaskManagerContext>(TaskManagerContext);


  const defaultDate = moment(currentTimelineDate, APP_DATE_FORMAT)
    .add(moment().hours(), "hours")
    .add(moment().minutes(), "minutes")
    .add(moment().seconds(), "seconds");

  return (
    <h3>
      <u>{defaultDate.fromNow() === "a few seconds ago" ? "Today" : defaultDate.fromNow()}</u>&nbsp;&nbsp;
      {defaultDate.format("dddd, DD MMMM YYYY")}{" "}
    </h3>
  );
};

export default CurrentTimelineDate;
