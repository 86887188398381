import _ from "lodash";
import { englishPrepositionList } from "../../../../../../../constant/englishPrepositionList";
import asyncForEach from "../../../../../../../lib/asyncForeach";

interface OnGenerateNameFromTitleHandlerProps {
  title: string;
  setNameAndTags: any;
  urlRef?: any;
}

export const onGenerateNameFromTitleAndSetTagsHandler = async ({
  title,
  setNameAndTags,
  urlRef,
}: OnGenerateNameFromTitleHandlerProps) => {
  const text = await _.kebabCase(title);

  let tagsTT = await `${text}`.replaceAll("-", ",");
  // await console.log("before << tagsTT => ", tagsTT);

  await asyncForEach(englishPrepositionList, async (englishPreposition: any) => {
    const asArray = tagsTT.split(",");

    const searchForStart = `,${englishPreposition}`;

    if (`${asArray[0]}`.includes(searchForStart)) {
      tagsTT = tagsTT.replaceAll(searchForStart, "");
    }

    const searchForEnd = `${englishPreposition},`;
    if (`${asArray[asArray.length - 1]}`.includes(searchForEnd)) {
      tagsTT = tagsTT.replaceAll(searchForEnd, "");
    }

    const searchForMiddle = `,${englishPreposition},`;
    if (`${tagsTT}`.includes(searchForMiddle)) {
      tagsTT = await tagsTT.replaceAll(searchForMiddle, ",");
    }
  });

  // await console.log("after >> tagsTT => ", tagsTT);

  setNameAndTags({ name: text, tags: tagsTT });

  if (urlRef) {
    urlRef && urlRef.current && urlRef.current.focus();
  }
};
