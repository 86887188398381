// Modules
import React from "react";

// Context
import { LayoutContext } from "./LayoutContext";

export function useLayout() {
  const context = React.useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout must be used within LayoutProvider");
  }
  return context;
}
