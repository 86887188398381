// Modules
import React from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";
import MDEditor from "@uiw/react-md-editor";

// Mutations / Queries
import { CREATE_MEDIA, DELETE_MEDIA, UPDATE_MEDIA } from "../../../../apollo/graphql/media";

// Components
import LoadingComponent from "../../../../App/Controller/Loader/LoadingComponent";
import Button from "../../../../Components/Button/Button";
import DisplayError from "../../../../Components/Error/ErrorComponent";
import TextInput from "../../../../Components/Form/Input/TextInput/TextInput";
import Icon from "../../../../Components/Icon";
import Modal from "../../../../Components/Modal/Modal";
import TagsInput from "../../../../Components/TagsInput/TagsInput";

// Handlers

// Functions
import { capitalizeFirstLetter } from "../../../../lib/string";
import { decodeHTML } from "../../../../lib/decodeHTML";
import { onDeleteMedia } from "./functions/onDeleteMedia";
import { onSaveMedia } from "./functions/onSaveMedia";
import { useSearchParams } from "react-router-dom";
import { warningToast } from "../../../../constant/customToast";
import { APP_DATE_FORMAT } from "../../../../lib/date";
import { desecureText } from "../../../../lib/desecureText";

export type emptyCategoryContentType = {
  name: string;
  id: string;
};

export const emptyCategoryContent: emptyCategoryContentType = {
  name: "",
  id: "",
};

interface MediaModalProps {
  mode: "create" | "update";
  mediaToEdit: any;
  setMediaToEdit: any;
  isOpen: boolean;
  openAddNewModal: string;
  onClose: () => void;
}

let _defaultMedia = {
  title: "",
  name: "",
  domain: emptyCategoryContent,
  specification: emptyCategoryContent,
  topic: emptyCategoryContent,
  publishDate: moment().format(APP_DATE_FORMAT),
  mediaBody: "",
  image: "https://via.placeholder.com/400x267.png",
  tags: "",
  answerBody: "",
};

let defaultMedia = { ..._defaultMedia };

const MediaModal: React.FC<MediaModalProps> = ({
  mode,
  mediaToEdit,
  setMediaToEdit,
  isOpen,
  openAddNewModal,
  onClose,
}) => {
  if (mode === "update") {
    defaultMedia = {
      title: decodeHTML(mediaToEdit.title),
      name: mediaToEdit.name,
      domain: {
        id: mediaToEdit.domain,
        name: "",
      },
      specification: {
        id: mediaToEdit.specification,
        name: "",
      },
      topic: {
        id: mediaToEdit.topic,
        name: "",
      },
      publishDate: mediaToEdit.publishDate,
      mediaBody: decodeHTML(mediaToEdit.mediaBody),
      tags: mediaToEdit.tags,
      image: mediaToEdit.image,
      answerBody: decodeHTML(mediaToEdit.answerBody),
    };
  } else {
    defaultMedia = { ..._defaultMedia };
  }

  const [title, setTitle] = React.useState(defaultMedia.title);
  const [name, setName] = React.useState(defaultMedia.name);

  const [category, setCategory] = React.useState({
    domain: emptyCategoryContent,
    specification: emptyCategoryContent,
    topic: emptyCategoryContent,
  });

  const { domain, specification, topic } = category;

  const setDomain = React.useCallback(
    (domainArg: any) =>
      setCategory({ domain: domainArg, specification: emptyCategoryContent, topic: emptyCategoryContent }),
    []
  );

  const setSpecification = React.useCallback(
    (specificationArg: any) =>
      setCategory((prevCategoryState) => ({
        ...prevCategoryState,
        specification: specificationArg,
        topic: emptyCategoryContent,
      })),
    []
  );

  const setTopic = React.useCallback(
    (topicArg: any) => setCategory((prevCategoryState) => ({ ...prevCategoryState, topic: topicArg })),
    []
  );

  const [publishDate, setPublishDate] = React.useState(defaultMedia.publishDate);
  const [mediaBody, setMediaBody] = React.useState<any>(defaultMedia.mediaBody);
  const [tags, setTags] = React.useState(defaultMedia.tags);
  const [answerBody, setAnswerBody] = React.useState<any>(defaultMedia.answerBody);

  const [image, setImage] = React.useState<any>(defaultMedia.image);

  const [blockAllUpdatesExceptX] = React.useState("");

  const [isAutoCleanTitleChecked, setIsAutoCleanTitleChecked] = React.useState(true);

  const urlRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();

  const _redirectToList = searchParams.get("redirectToList");
  // console.log(_redirectToList);

  const [clearForm, setClearForm] = React.useState(true);
  const [redirectToList, setRedirectToList] = React.useState(_redirectToList === "false" ? false : true);

  let media: any = {
    title,
    name,
    domain: domain.id,
    specification: specification.id,
    topic: topic.id,
    publishDate,
    mediaBody,
    tags,
    image,
    answerBody,
  };

  if (mode === "update") {
    const id = mediaToEdit.id;
    media = { id, ...media };
  }

  const [createMedia, { loading: loadingCreate, error: errorCreate }] = useMutation(CREATE_MEDIA, {
    refetchQueries: ["MEDIAS"],
  });

  const [updateMedia, { loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_MEDIA, {
    refetchQueries: ["MEDIAS"],
  });

  const [deleteMedia, { loading: loadingDelete, error: errorDelete }] = useMutation(DELETE_MEDIA, {
    refetchQueries: ["MEDIAS"],
  });

  // ->-------------------------- functions ---------------------------

  const reset = () => {
    setTitle(defaultMedia.title);
    setName(defaultMedia.name);
    setDomain(defaultMedia.domain);
    setSpecification(defaultMedia.specification);
    setTopic(defaultMedia.topic);
    setPublishDate(defaultMedia.publishDate);
    setMediaBody(defaultMedia.mediaBody);
    setTags(defaultMedia.tags);
    setImage(defaultMedia.image);
    setAnswerBody(defaultMedia.answerBody);
  };

  const onClearForm = () => {
    if (clearForm) {
      reset();

      setClearForm(true);
      if (_redirectToList === "true") {
        setRedirectToList(true);
      }
    }
  };

  const onRedirectToList = () => {
    if (redirectToList) {
      onClose();
    }
  };

  const onUpdateCloseModal = () => setMediaToEdit(null);

  // -<-------------------------- functions ---------------------------

  // ->-------------------------- useEffect ---------------------------

  React.useEffect(() => {
    if (mode === "update") {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaToEdit, mode, openAddNewModal]);

  React.useEffect(() => {
    if (blockAllUpdatesExceptX !== "") {
      warningToast(`Please save the changes to be able to do any other action`);
    }
    return () => {};
  }, [blockAllUpdatesExceptX]);

  // -<-------------------------- useEffect ---------------------------

  const loading = loadingCreate || loadingUpdate || loadingDelete;
  if (loading) {
    <LoadingComponent loadingUniqueReference="607025b3-f863-4d04-84ad-47551abb2355" message={`create media`} />;
  }

  const error = errorCreate || errorUpdate || errorDelete;

  if (error) {
    return <DisplayError errorUniqueReference="6d87f241-296f-4c2c-b46c-565cc09c499c" error={error} />;
  }

  return (
    <Modal
      maxWidth="95vw"
      center
      activeCond={isOpen}
      Header={<h4>{capitalizeFirstLetter(mode)} your media</h4>}
      Footer={
        <div className="stack create-entity-modal-footer">
          <div className="left-side">
            <Button
              onClick={() => setClearForm((s) => !s)}
              className={`button-icon ${clearForm ? "selected" : ""}`}
              title="Clear form after submit"
            >
              <Icon name="reload" />
            </Button>
            <Button
              onClick={() => setRedirectToList((s) => !s)}
              className={`button-icon ${redirectToList ? "selected" : ""}`}
              title="Redirect to medias list"
            >
              <Icon name="grid" />
            </Button>
            {mode === "update" && (
              <Button
                size="md1"
                colorScheme="$p3"
                onClick={() => onDeleteMedia(loading, deleteMedia, media.id, () => setMediaToEdit(null), "delete")}
              >
                <Icon name="trash" />
                Delete
              </Button>
            )}
          </div>

          <div className="right-side">
            <Button
              size="md1"
              colorScheme="$p1"
              onClick={() => {
                mode === "create" &&
                  onSaveMedia(mode, loading, createMedia, media, () => null, onClearForm, onRedirectToList);
                mode === "update" &&
                  onSaveMedia(
                    mode,
                    loading,
                    updateMedia,
                    media,
                    onUpdateCloseModal(),
                    () => null,
                    () => null
                  );
              }}
            >
              <Icon name="save" />
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              size="md1"
              colorScheme="gray"
              onClick={() => {
                if (mode === "update") {
                  setMediaToEdit(null);
                } else {
                  onClose();
                }
              }}
            >
              <Icon name="close" />
              Cancel
            </Button>
          </div>
        </div>
      }
      onClose={() => {
        if (mode === "update") {
          setMediaToEdit(null);
        } else {
          onClose();
        }
      }}
    >
      <div className="create-entity-modal">
        <div className="form-group">
          <div
            className="flex-block"
            style={{
              justifyContent: "center",
            }}
          >
            <img src={image} alt="poster" />
          </div>

          <div
            className="flex-block"
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Button
              title="view Media"
              onClick={() =>
                window.open(`/${domain.name}/${specification.name}/${topic.name}/${name}`, "_blank")
              }
            >
              <Icon name="link" />
              &nbsp; view
            </Button>
          </div>
        </div>

        <div
          className="form-group"
          style={{
            marginTop: "16px",
          }}
        >
          <div className="flex-block">
            <TextInput
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              disabled={blockAllUpdatesExceptX !== ""}
              autoComplete="off"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              id="image"
              name="image"
              type="text"
              placeholder="Your image"
              required
              icon="image"
            />
            <Button
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: "40px",
              }}
              title="Search image"
              onClick={() =>
                window.open(
                  `https://unsplash.com/s/photos/${tags.split(",").join("-")}?order_by=latest&orientation=landscape`,
                  "_blank"
                )
              }
            >
              <Icon name="search" />
              <Icon name="image" />
            </Button>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <div className="flex-block">
            <TextInput
              xref={titleRef}
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              disabled={blockAllUpdatesExceptX !== ""}
              autoComplete="off"
              value={title}
              onChange={(e) => {
                let _val = e.target.value;

                if (isAutoCleanTitleChecked && _val.split(". ")[1]) {
                  _val = _val.split(". ")[1] + " in " + searchParams.get("topic");
                }

                setTitle(_val);
              }}
              id="title"
              name="title"
              type="text"
              placeholder="Your title"
              required
              icon="message"
              pattern=".{1,128}"
              title="1 to 128 characters"
            />
            <Button
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              title="Generate name from this title"
              //onClick={() => onGenerateNameFromTitleAndSetTagsHandler({title, setName, setTags, urlRef})}
            >
              <Icon name="ticket" />
            </Button>
          </div>

          <div
            className="flex-block"
            style={{
              display: "flex",
              marginTop: "4px",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                width: "16px",
                height: "16px",
                cursor: "pointer",
              }}
              id="isAutoCleanTitleChecked"
              value={isAutoCleanTitleChecked ? "1" : "0"}
              onChange={() => setIsAutoCleanTitleChecked(!isAutoCleanTitleChecked)}
            />

            <label
              style={{
                cursor: "pointer",
              }}
              htmlFor="isAutoCleanTitleChecked"
            >
              &nbsp;&nbsp;Auto Clean Title
            </label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <div className="flex-block">
            <TextInput
              xref={urlRef}
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              disabled={blockAllUpdatesExceptX !== ""}
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              name="name"
              type="text"
              placeholder="Your name"
              required
              icon="message"
              pattern=".{1,128}"
              title="1 to 128 characters"
            />
            <Button
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              title="Search on Google"
              onClick={() => window.open(`https://www.google.com/search?q=${title.replaceAll("&", " ")}`, "_blank")}
            >
              <Icon name="search" />
            </Button>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="publishDate">Publish date:</label>
          <div className="flex-block">
            <TextInput
              style={{
                cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
              }}
              disabled={blockAllUpdatesExceptX !== ""}
              autoComplete="off"
              value={publishDate}
              onChange={(e) => setPublishDate(e.target.value)}
              id="publishDate"
              name="publishDate"
              type="date"
              placeholder="Your publish date"
              required
              icon="message"
            />
          </div>
        </div>

        <div
          className="form-group"
          style={{
            cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
            maxHeight: "360px",
            overflowY: "scroll",
          }}
        >
          <label>Media body:</label>
          <MDEditor preview="edit" value={desecureText(mediaBody)} onChange={setMediaBody} height={500} />
          <MDEditor.Markdown source={desecureText(mediaBody)} />
        </div>

        <div
          className="form-group"
          style={{
            cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
            maxHeight: "360px",
            overflowY: "scroll",
          }}
        >
          <label htmlFor="name">Tags:</label>
          <TagsInput disabled={blockAllUpdatesExceptX !== ""} tags={tags} setTags={setTags} />
        </div>

        <div
          className="form-group"
          style={{
            cursor: blockAllUpdatesExceptX !== "" ? "not-allowed" : "normal",
            maxHeight: "360px",
            overflowY: "scroll",
          }}
        >
          <label>Media body:</label>
          <MDEditor preview="edit" value={desecureText(answerBody)} onChange={setAnswerBody} height={500} />
          <MDEditor.Markdown source={desecureText(answerBody)} />
        </div>
      </div>
    </Modal>
  );
};

export default MediaModal;
