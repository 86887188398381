import { gql } from "@apollo/client";

export const MEDIAS_IDS = gql`
  query MEDIAS_IDS($challenge: String = "") {
    mediasIds(challenge: $challenge)
  }
`;

export const MEDIAS = gql`
  query MEDIAS($challenge: String = "") {
    medias(challenge: $challenge)
  }
`;

export const MEDIA = gql`
  query MEDIA($challenge: String = "") {
    media(challenge: $challenge)
  }
`;

export const CREATE_MEDIA = gql`
  mutation CREATE_MEDIA($challenge: String!) {
    createMedia(challenge: $challenge)
  }
`;

export const CREATE_MULTIPLE_MEDIA = gql`
  mutation CREATE_MULTIPLE_MEDIA($challenge: String!) {
    createMultipleMedia(challenge: $challenge)
  }
`;

export const UPDATE_MEDIA = gql`
  mutation UPDATE_MEDIA($challenge: String!) {
    updateMedia(challenge: $challenge)
  }
`;

export const CHANGE_MEDIA_INDEX = gql`
  mutation CHANGE_MEDIA_INDEX($challenge: String!) {
    changeMediaIndex(challenge: $challenge)
  }
`;

export const DELETE_MEDIA = gql`
  mutation DELETE_MEDIA($challenge: String!) {
    deleteMedia(challenge: $challenge)
  }
`;

export const DELETE_ALL_MEDIA = gql`
  mutation DELETE_ALL_MEDIA($challenge: String!) {
    deleteAllMedia(challenge: $challenge)
  }
`;
