import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { UPDATE_TASK_MANAGER_SETTINGS } from "../../../../apollo/graphql/task_manager";
import ContextMenu from "../../../../Components/ContextMenu/ContextMenu";
import { encryptObject } from "../../../../lib/crypt";
import CreateTaskModal from "../../Components/CreateTaskModal/CreateTaskModal";
import { ITaskManagerContext, TaskManagerContext } from "../../context";
import { generateTime } from "../../utils";
import RenderTasks from "./RenderTasks/RenderTasks";
import SingleTimeline from "./SingleTimeline";
import { TIMELINE__HEIGHT } from "./timelineConstants";

export type TimelineMenuItemContext = {
  time: string;
  position: { x: number; y: number };
};

const getPrevious = (time: any, times: any) => {
  const position = times.indexOf(time);

  if (position === times.length - 1) {
    return times[0];
  } else {
    return times[position + 1];
  }
};

const TimeLine: React.FC = () => {
  const [openAddNewModal, setOpenAddNewModal] = React.useState("");
  const [taskToEdit, setTaskToEdit] = React.useState(null);

  const mode = taskToEdit !== null && taskToEdit !== undefined ? "update" : "create";

  const [currentContextMenuItem, setCurrentContextMenuItem] = React.useState<TimelineMenuItemContext>();

  const [updateTaskManagerSettingsMutation, { loading, error }] = useMutation(UPDATE_TASK_MANAGER_SETTINGS, {
    refetchQueries: ["QUERY_TASK_MANAGER_SETTINGS"],
  });

  const { taskManagerStore } = useContext<ITaskManagerContext>(TaskManagerContext);

  const {
    client: { nearest },
    server,
  } = taskManagerStore;

  const { timeZoom, selected } = server;

  let times: any = generateTime(timeZoom).reverse();

  const onSelectTimeline = (time: any) => {
    updateTaskManagerSettingsMutation({
      variables: {
        challenge: encryptObject({
          settings: {
            ...server,
            selected: time,
          },
        }),
      },
    });
  };

  if (error) return <div>Error4561684864</div>;

  return (
    <div className="timeline" style={{ paddingBottom: `${TIMELINE__HEIGHT}px` }}>
      <SingleTimeline
        key={`time-${times[times.length - 1]}`}
        selected={selected}
        time={"00:00"}
        nearest={nearest}
        loading={loading}
        timeZoom={timeZoom}
        setCurrentContextMenuItem={setCurrentContextMenuItem}
      />
      {times.map((time: any, index: any) => (
        <SingleTimeline
          key={`time-${time}-${index}`}
          selected={selected}
          time={time}
          nearest={nearest}
          loading={loading}
          timeZoom={timeZoom}
          setCurrentContextMenuItem={setCurrentContextMenuItem}
        />
      ))}
      <RenderTasks setTaskToEdit={setTaskToEdit} />
      {currentContextMenuItem ? (
        <ContextMenu
          data={currentContextMenuItem}
          actions={[
            {
              name: `Add Task to [${getPrevious(currentContextMenuItem.time, times)} - ${currentContextMenuItem.time}]`,
              onClick: () => setOpenAddNewModal(getPrevious(currentContextMenuItem.time, times)),
            },
            {
              name: `Select the timeline ${currentContextMenuItem.time}`,
              onClick: () => onSelectTimeline(currentContextMenuItem.time),
            },
          ]}
        />
      ) : null}
      <CreateTaskModal
        mode={mode}
        taskToEdit={taskToEdit}
        setTaskToEdit={setTaskToEdit}
        isOpen={mode === "update" ? `bypass` : openAddNewModal}
        onClose={() => setOpenAddNewModal("")}
      />
    </div>
  );
};

export default TimeLine;
