// Modules
import { useQuery } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

// Pages
import LoginPage from "./Login/LoginPage";

// Queries
import { CURRENT_USER_QUERY } from "../../../apollo/graphql/user";

// Components
import DisplayError from "../../../Components/Error/ErrorComponent";

// Functions
import { decryptObject } from "../../../lib/crypt";

// Application
import NProgressComponent from "../../../Components/NProgress/NProgressComponent";
import App from "../../AppComponent";

const AppControllerGuard = () => {
  const { loading: currentUserLoading, error, data } = useQuery(CURRENT_USER_QUERY);

  if (currentUserLoading) {
    return <NProgressComponent message="Current User Authentication" />;
  }

  if (error) {
    return <DisplayError layout="modal" errorUniqueReference="eb7a9bc1-569e-4f54-874a-450166c2f663" error={error} />;
  }

  if (data && data.me) {
    
    const decryptedData = decryptObject(data.me);
    if (!decryptedData)
      return (
        <DisplayError
          layout="modal"
          errorUniqueReference="108aa176-15b2-4bcf-8058-898d725fe93f"
          error={Error("b0c7d5e9-1789-4ea6-9f2b-9e16203ed601")}
        />
      );

    if (decryptedData && decryptedData.msg === "redirect_to_login") {
      
      return <LoginPage />;
    } else if (decryptedData.msg === "redirect_to_app") {

      return (
        <BrowserRouter>
          <App />
        </BrowserRouter>
      );
    } else {
      return (
        <DisplayError
          layout="modal"
          errorUniqueReference="e7c544c1-e0aa-476d-9a5b-be33cbbfab07"
          error={Error("440a3f8c-9a79-4f4b-8f30-40bd07c6a8a7")}
        />
      );
    }
  } else {
    return (
      <DisplayError
        layout="modal"
        errorUniqueReference="41f558ea-c59d-4ee8-9c4c-d760ccca9150"
        error={Error("54f98d27-ed20-40a7-a6bc-11dedbcce356")}
      />
    );
  }
};

export default AppControllerGuard;
