// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { knowledgePageRoutesWithElementMetadata } from "./knowledgeMetaData";

const KnowledgePageRoutes: React.FC = () => {
  return (
    <Routes>
      {knowledgePageRoutesWithElementMetadata.map(({ path, Element }, knowledge) => (
        <Route key={knowledge} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default KnowledgePageRoutes;
