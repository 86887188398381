import React from "react";
import { encryptObject } from "../../../../lib/crypt";
import { decodeHTML } from "../../../../lib/decodeHTML";
import RoundedButton from "./RoundedButton";

interface KnowledgeMapItemProps {
  type: string;
  id: string;
  title: string;
  description: string;
  setParentIdOfchilds: React.Dispatch<React.SetStateAction<string | null>>;
  parentIdOfchilds: string | null;
  childrensIds: string;
}

const KnowledgeMapItem: React.FC<KnowledgeMapItemProps> = ({
  type,
  id,
  title,
  description,
  parentIdOfchilds,
  childrensIds,
  setParentIdOfchilds,
}) => {
  // console.log("childrensIds => ", childrensIds);
  const disabled = childrensIds === "";
  const enabled = childrensIds !== "";

  React.useEffect(() => {
    return () => {
      setParentIdOfchilds(null);
    };
  }, [setParentIdOfchilds]);

  return (
    <RoundedButton
      disabled={disabled}
      description={description}
      isSelected={parentIdOfchilds !== "" && parentIdOfchilds === id}
      onClick={(ifCtrlKey: any) => {
        // set
        let topic = "";
        if (type === "topic") {
          window.localStorage.setItem("topic", title);
        }
        if (ifCtrlKey) {
          // type === "topic" add child to it
          if (type === "topic" || type === "section" || type === "lecture") {
            const _topic = window.localStorage.getItem("topic");

            topic = `&topic=${_topic}&redirectToList=false`;
          }

          window.open(`/knowledges?action=create&parentId=${id}${topic}`, "_blank");
        } else {
          disabled && setParentIdOfchilds("");
          enabled && setParentIdOfchilds(id);
        }
      }}
      onDbClick={() => {
        window.open(
          `/knowledges?q=${encryptObject({
            page: 1,
            limit: 1,
            full: true,
            filter: [
              {
                key: "id",
                name: "id",
                searchType: "equal",
                type: "string",
                value: id,
              },
            ],
          })}`,
          "_blank"
        );
      }}
      br={getBR(type)}
    >
      {decodeHTML(title)}
    </RoundedButton>
  );
};

const getBR = (type: any) => {
  const mult = 4;
  let br = 0;
  switch (type) {
    case "domain":
      br = mult * 1;
      break;

    case "specification":
      br = mult * 2;
      break;

    case "topic":
      br = mult * 3;
      break;

    case "section":
      br = mult * 4;
      break;

    case "lecture":
      br = mult * 5;
      break;

    default:
      br = 1;
      break;
  }
  return `${br}px`;
};

export default KnowledgeMapItem;
