export const navigation = [
  {
    title: "Home",
    icon: "home",
    url: "/",
  },
  {
    title: "Task Manager",
    url: "/task-manager",
    slug: "task-manager",
    icon: "multiselect",
  },

  {
    title: "Posts",
    icon: "messages",
    url: "/posts",
  },
  {
    title: "Knowledges",
    icon: "activity",
    url: "/knowledges",
  },
  {
    title: "Projects",
    icon: "store",
    url: "/projects",
  },
  {
    title: "Questions",
    icon: "info",
    url: "/questions",
  },
  {
    title: "Quotes",
    icon: "quote",
    url: "/quotes",
  },
  {
    title: "Apps",
    slug: "apps",
    icon: "web_dev",
    add: true,
    dropdown: [
      {
        title: "Palettes",
        url: "/apps/palettes",
        icon: "color_palette",
      },
    ],
  },
  {
    title: "Preferences",
    slug: "preferences",
    icon: "puzzle",
    add: true,
    dropdown: [
      {
        title: "Backup",
        icon: "save",
        url: "/preferences/backup-data",
      },
      {
        title: "Medias",
        icon: "upload",
        url: "/preferences/medias",
      },
    ],
  },
];

// {
//   title: "Task Manager",
//   slug: "task-manager",
//   icon: "multiselect",
//   add: true,
//   dropdown: [
//     {
//       title: "Task Manager",
//       url: "/task-manager",
//     },
//     {
//       title: "Drafts",
//       url: "/task-manager/drafts",
//       counter: "2",
//       colorCounter: "#FFBC99",
//     },
//   ],
// },
