// Modules
import React from "react";

// Functions
import { getAttrOfItemBykey, getValueOfItemBykey } from "../../../../../lib/object";
import { getDisabledState } from "../../../utils/getDisabledState";
import { updateFilterSearchType, updateFilterValue } from "../utils";

// Types
import { FilterType } from "../../../../../pages/Base/Pagination/Filter/FilterType";

// Components
import TextInput from "../../../../Form/Input/TextInput/TextInput";
import Select from "../../../../Select/Select";

// Constants
import { STRING_FILTER_SEARCH_TYPES } from "../../../constants";

interface FilterModalInputTypeStringProps {
  selectedFilterKey: string;
  localFilters: FilterType[] | null;
  setLocalFilters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  onFilter: (localFilters: any, clear?: boolean | undefined) => void;
}

const FilterModalInputTypeString: React.FC<FilterModalInputTypeStringProps> = ({
  selectedFilterKey,
  localFilters,
  setLocalFilters,
  onFilter,
}) => {
  const currentSearchType = getAttrOfItemBykey(selectedFilterKey, localFilters, "searchType");

  const currentValue = getValueOfItemBykey(selectedFilterKey, localFilters);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "8px",
        }}
      >
        <Select
          defaultValue={currentSearchType}
          style={{
            width: "100%",
          }}
          onChange={(e) =>
            e.target.value && updateFilterSearchType(e.target.value, selectedFilterKey, localFilters, setLocalFilters)
          }
        >
          {STRING_FILTER_SEARCH_TYPES.map((el) => (
            <option key={`key-${el}`} value={`${el}`}>
              {el}
            </option>
          ))}
        </Select>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <TextInput
          onKeyDown={(e) => {
            if (e.key === "Enter" && !getDisabledState(selectedFilterKey, localFilters)) {
              onFilter(localFilters);
            }
          }}
          style={{
            width: "100%",
          }}
          type="text"
          value={currentValue || ""}
          onChange={(e) => updateFilterValue(e.target.value, selectedFilterKey, localFilters, setLocalFilters)}
          placeholder={`${getAttrOfItemBykey(selectedFilterKey, localFilters, "name")}..`}
        />
      </div>
    </div>
  );
};

export default FilterModalInputTypeString;
