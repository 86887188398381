// Modules
import React from "react";
import { motion, AnimatePresence } from "framer-motion";

// Constants
const duration = 0.35;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      //   delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

interface FadeProps {
  children: any;
  activeCond: boolean;
}

type TExtendedProps = FadeProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Fade: React.FC<TExtendedProps> = ({ children, activeCond }, props) => {
  const [removeFromVDOM, setRemoveFromVDOM] = React.useState(activeCond!);

  React.useEffect(() => {
    setRemoveFromVDOM(false);
    return () => {
      setRemoveFromVDOM(false);
    };
  }, [activeCond]);

  return !removeFromVDOM ? (
    <AnimatePresence>
      {activeCond ? (
        <motion.div variants={variants} initial="initial" animate="enter" exit="exit" {...props}>
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  ) : null;
};

export default Fade;
