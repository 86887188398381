// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { questionsPageRoutesWithElementMetadata } from "./questionsMetaData";

const QuestionsPageRoutes: React.FC = () => {
  return (
    <Routes>
      {questionsPageRoutesWithElementMetadata.map(({ path, Element }, question) => (
        <Route key={question} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default QuestionsPageRoutes;
