// Modules
import React from "react";
import { Route, Routes } from "react-router-dom";

// Metadata
import { postsPageRoutesWithElementMetadata } from "./postsMetaData";

const PostsPageRoutes: React.FC = () => {
  return (
    <Routes>
      {postsPageRoutesWithElementMetadata.map(({ path, Element }, post) => (
        <Route key={post} path={path} element={Element && <Element />} />
      ))}
    </Routes>
  );
};

export default PostsPageRoutes;
