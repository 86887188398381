// Types
import { RouteType, RouteTypeWithElement } from "../types/RouteTypes";

// Pages
import AppearancesPage from "../../../pages/Settings/AppearancesPage/AppearancesPage";
import SettingsPage from "../../../pages/Settings/SettingsPage";

// Metadata
import { servicesPageRoutesWithElementMetadata } from "./Services/servicesMetadata";

export const settingsPageRoutesWithElementMetadata: RouteTypeWithElement[] = [
  {
    title: "Settings Page",
    name: "settings-page",
    table: "setting",
    absolutePath: "/settings",
    path: "settings",
    Element: SettingsPage,
    children: [
      {
        title: "Appearances Settings Page",
        name: "appearances-settings-page",
        absolutePath: "/settings/appearances",
        path: "appearances",
        Element: AppearancesPage,
      },
      ...servicesPageRoutesWithElementMetadata,
    ],
  },
];

export const settingsPageRoutesMetadata: RouteType[] = settingsPageRoutesWithElementMetadata.map(
  ({ Element, ChildrenRoutes, ...rest }) => rest
);
