// Modules
import { useLocation } from "react-router-dom";

export const useCurrentRoute = () => {
  const location = useLocation();

  const urlAsArray = location.pathname.split("/");

  return urlAsArray[urlAsArray.length - 1];
};
